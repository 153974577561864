import { createContext, useContext, useState } from "react";

const TitleContext = createContext(null);
const useTitle = () => useContext(TitleContext);

const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState({ name: "No page title", back: null });
  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export { TitleProvider, useTitle };
