import styled, { css } from "styled-components";
import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { useCallback, useEffect, useState } from "react";
import API from "../../../utils/api";
import TextInput from "../../../shared/components/TextInput";
import { ReactComponent as SentImg } from "../../../shared/assets/icons/message-sent.svg";
import { useForm } from "react-hook-form";
import TextAreaInput from "../../../shared/components/TextAreaInput";
import Select from "../../../shared/components/Select";
import { Row } from "../../../shared/styled/Flexbox";
import TextMaskInput from "../../../shared/components/TextMaskInput";
import States from "../../../utils/states";

const EditPatient = ({ onClose, patientId: pid, order = false }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clinic, setClinic] = useState({});
  const [sent, setSent] = useState(false);
  const [patient, setPatient] = useState();
  const [editStatus, setEditStatus] = useState("");

  // Form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  // Get Patient
  const getPatient = async (patientId) => {
    setLoading(true);
    try {
      const response = await API.get(`/patients/${patientId}`);
      const lockResponse = await API.get(`/patients/${patientId}/editStatus`);
      setPatient(response.data);
      if (response.data.dob !== "") {
        response.data.dob_m = new Date(response.data.dob).toISOString().split("T")[0].split("-")[1];
        response.data.dob_d = new Date(response.data.dob).toISOString().split("T")[0].split("-")[2];
        response.data.dob_y = new Date(response.data.dob).toISOString().split("T")[0].split("-")[0];
      }
      // TODO: Look into why we need this reset
      reset(response.data);
      setEditStatus(lockResponse.data.status);
    } catch (e) {
      onClose();
    }
    setLoading(false);
  };

  const savePatient = useCallback(
    async (data) => {
      setLoading(true);

      const newDate = new Date(`${data.dob_y}-${data.dob_m}-${data.dob_d}`);
      data.dob = newDate.toISOString().split("T")[0];
      delete data.dob_m;
      delete data.dob_d;
      delete data.dob_y;

      setError("");
      try {
        if (pid) {
          await API.put(`/patients/${pid}`, data);
        } else {
          await API.post(`/patients`, data);
        }
        setLoading(false);
        onClose();
      } catch (e) {
        setLoading(false);
        setError("Unable to save. Please check the data entered.");
      }
    },
    [pid]
  );

  useEffect(() => {
    if (pid) {
      getPatient(pid);
    } else {
      setPatient({});
      setEditStatus("full");
    }
  }, [pid]);

  return (
    <Modal open onClose={onClose}>
      <Wrapper onSubmit={handleSubmit(savePatient)}>
        <Header>
          <SubTitle>Patients</SubTitle>
          <Title>{pid ? "Edit" : "Add"} Patient</Title>
          {order && (
            <Message>
              <i>
                Note: This does not change the patient assigned to this order, only their current
                information.
                <br /> If you need to assign this order to a different patient, please contact
                support.
              </i>
            </Message>
          )}
        </Header>
        {patient && (
          <>
            <CollapseRow style={{ gap: "30px" }}>
              <FullWidthText
                id={"first"}
                title={"First Name"}
                disabled={loading || editStatus === "contact_only"}
                placeholder={"enter first name"}
                defaultValue={patient.first}
                required
                {...register("first", { required: true })}
              />
              <FullWidthText
                {...register("last", { required: true })}
                id={"last"}
                title={"Last Name"}
                disabled={loading || editStatus === "contact_only"}
                placeholder={"enter last name"}
                defaultValue={patient.last}
                required
              />
            </CollapseRow>
            <CollapseRow style={{ gap: "30px" }}>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Label required>Date of Birth</Label>
                <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: "5px" }}>
                  <FullWidthSelect
                    variant="primary"
                    {...register("dob_m", { required: true })}
                    disabled={loading || editStatus === "contact_only"}
                  >
                    <option value="">Month</option>
                    <option value="01">01 - Jan</option>
                    <option value="02">02 - Feb</option>
                    <option value="03">03 - Mar</option>
                    <option value="04">04 - Apr</option>
                    <option value="05">05 - May</option>
                    <option value="06">06 - Jun</option>
                    <option value="07">07 - Jul</option>
                    <option value="08">08 - Aug</option>
                    <option value="09">09 - Sep</option>
                    <option value="10">10 - Oct</option>
                    <option value="11">11 - Nov</option>
                    <option value="12">12 - Dec</option>
                  </FullWidthSelect>
                  <FullWidthSelect
                    variant="primary"
                    {...register("dob_d", { required: true })}
                    disabled={loading || editStatus === "contact_only"}
                  >
                    <option value="">Day</option>
                    <option value="01">01</option>
                    <option value="02">02</option>
                    <option value="03">03</option>
                    <option value="04">04</option>
                    <option value="05">05</option>
                    <option value="06">06</option>
                    <option value="07">07</option>
                    <option value="08">08</option>
                    <option value="09">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </FullWidthSelect>
                  {/*full width select for year since 1920*/}
                  <FullWidthSelect
                    variant="primary"
                    {...register("dob_y", { required: true })}
                    disabled={loading || editStatus === "contact_only"}
                  >
                    <option value="">Year</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                    <option value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009</option>
                    <option value="2008">2008</option>
                    <option value="2007">2007</option>
                    <option value="2006">2006</option>
                    <option value="2005">2005</option>
                    <option value="2004">2004</option>
                    <option value="2003">2003</option>
                    <option value="2002">2002</option>
                    <option value="2001">2001</option>
                    <option value="2000">2000</option>
                    <option value="1999">1999</option>
                    <option value="1998">1998</option>
                    <option value="1997">1997</option>
                    <option value="1996">1996</option>
                    <option value="1995">1995</option>
                    <option value="1994">1994</option>
                    <option value="1993">1993</option>
                    <option value="1992">1992</option>
                    <option value="1991">1991</option>
                    <option value="1990">1990</option>
                    <option value="1989">1989</option>
                    <option value="1988">1988</option>
                    <option value="1987">1987</option>
                    <option value="1986">1986</option>
                    <option value="1985">1985</option>
                    <option value="1984">1984</option>
                    <option value="1983">1983</option>
                    <option value="1982">1982</option>
                    <option value="1981">1981</option>
                    <option value="1980">1980</option>
                    <option value="1979">1979</option>
                    <option value="1978">1978</option>
                    <option value="1977">1977</option>
                    <option value="1976">1976</option>
                    <option value="1975">1975</option>
                    <option value="1974">1974</option>
                    <option value="1973">1973</option>
                    <option value="1972">1972</option>
                    <option value="1971">1971</option>
                    <option value="1970">1970</option>
                    <option value="1969">1969</option>
                    <option value="1968">1968</option>
                    <option value="1967">1967</option>
                    <option value="1966">1966</option>
                    <option value="1965">1965</option>
                    <option value="1964">1964</option>
                    <option value="1963">1963</option>
                    <option value="1962">1962</option>
                    <option value="1961">1961</option>
                    <option value="1960">1960</option>
                    <option value="1959">1959</option>
                    <option value="1958">1958</option>
                    <option value="1957">1957</option>
                    <option value="1956">1956</option>
                    <option value="1955">1955</option>
                    <option value="1954">1954</option>
                    <option value="1953">1953</option>
                    <option value="1952">1952</option>
                    <option value="1951">1951</option>
                    <option value="1950">1950</option>
                    <option value="1949">1949</option>
                    <option value="1948">1948</option>
                    <option value="1947">1947</option>
                    <option value="1946">1946</option>
                    <option value="1945">1945</option>
                    <option value="1944">1944</option>
                    <option value="1943">1943</option>
                    <option value="1942">1942</option>
                    <option value="1941">1941</option>
                    <option value="1940">1940</option>
                    <option value="1939">1939</option>
                    <option value="1938">1938</option>
                    <option value="1937">1937</option>
                    <option value="1936">1936</option>
                    <option value="1935">1935</option>
                    <option value="1934">1934</option>
                    <option value="1933">1933</option>
                    <option value="1932">1932</option>
                    <option value="1931">1931</option>
                    <option value="1930">1930</option>
                    <option value="1929">1929</option>
                    <option value="1928">1928</option>
                    <option value="1927">1927</option>
                    <option value="1926">1926</option>
                    <option value="1925">1925</option>
                    <option value="1924">1924</option>
                    <option value="1923">1923</option>
                    <option value="1922">1922</option>
                    <option value="1921">1921</option>
                    <option value="1920">1920</option>
                  </FullWidthSelect>
                </div>
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Label required>Birth Sex</Label>
                <FullWidthSelect
                  variant="primary"
                  {...register("gender", { required: true })}
                  disabled={loading || editStatus === "contact_only"}
                  defaultValue={patient.gender}
                >
                  <option value="" />
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="decline">Decline to specify</option>
                </FullWidthSelect>
              </div>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Label required>Profile Type</Label>
                <FullWidthSelect
                  variant="primary"
                  {...register("profile_type", { required: true })}
                  disabled={loading || editStatus === "contact_only"}
                  defaultValue={patient.profile_type}
                >
                  <option value="patient">Patient</option>
                  <option value="practice_team_member">Practice Team Member</option>
                </FullWidthSelect>
              </div>
            </CollapseRow>
            <CollapseRow style={{ gap: "30px" }}>
              <FullWidthText
                {...register("ext_patient_id")}
                id={"ext_patient_id"}
                title={"Patient ID"}
                disabled={loading}
                maxLength={20}
                placeholder="enter patient id"
                defaultValue={patient.ext_patient_id}
              />
              <TextMaskInput
                id={"phone"}
                {...register("phone", { required: false })}
                placeholder="enter 10-digit number"
                title={"Phone Number"}
                min={9}
                disabled={loading}
                mask={"(999) 999-9999"}
                maskPlaceholder={null}
                defaultValue={patient.phone}
              />
            </CollapseRow>
            <FullWidthText
              {...register("email")}
              id={"email"}
              title={"Email address"}
              disabled={loading}
              placeholder="Enter email address"
              defaultValue={patient.email}
            />
            <>
              <FullWidthText
                {...register("address")}
                id={"address"}
                title={"Address"}
                disabled={loading}
                placeholder="Street Address"
                defaultValue={patient.address}
              />
              <CollapseRow style={{ gap: "30px" }}>
                <FullWidthText
                  {...register("city")}
                  id={"city"}
                  placeholder={"City"}
                  disabled={loading}
                  defaultValue={patient.city}
                />
                <StyledSelect
                  style={{ flex: 1 }}
                  disable={loading}
                  error={errors.state !== undefined || error}
                  disabled={loading}
                  defaultValue={patient.state}
                  {...register("state")}
                >
                  <option value="">- State -</option>
                  {States.map((state) => (
                    <option value={state.abbreviation}>{state.name}</option>
                  ))}
                </StyledSelect>
                <FullWidthText
                  {...register("zip")}
                  id={"zip"}
                  placeholder="zip"
                  disabled={loading}
                  defaultValue={patient.zip}
                />
              </CollapseRow>
            </>
            <Content>
              <Buttons>
                <Button variant="secondary" onClick={onClose} disabled={loading} type="button">
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={loading} loading={loading}>
                  Save
                </Button>
              </Buttons>
              {error && <Error>{error}</Error>}
            </Content>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default EditPatient;

const IconWrapper = styled.div`
  position: relative;
`;
const Message = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #0b0b0b;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const SalesRep = styled.div``;
const To = styled.div`
  display: flex;
  gap: 20px;
  font-family: Poppins, serif;
  font-style: normal;
  font-size: 12px;
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;
const StyledSelect = styled(Select)`
  min-height: 40px;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const CollapseRow = styled(Row)`
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.tablet} {
    flex-direction: row;
  }
`;
const FullWidthSelect = styled(Select)`
  max-height: 44px;
  height: 44px;
  flex: 1;
`;

const FullWidthText = styled(TextInput)`
  flex: 1;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
