import styled from "styled-components";
import PreAuthImg from "../../shared/assets/mainbg.jpg";
import Logo from "../../shared/components/Logo";
import { Outlet } from "react-router-dom";
import Copyright from "../../shared/components/Copyright";

const PreAuthBg = styled.div`
  background-image: url(${PreAuthImg});
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding-top: 83px;
  padding-bottom: 32px;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 100%;
  max-width: 360px;
  margin-top: 110px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
`;

const PreAuth = () => {
  return (
    <PreAuthBg>
      <Logo />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Copyright />
    </PreAuthBg>
  );
};

export default PreAuth;
