import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { default as cx } from "classnames";
import { forwardRef } from "react";

const TextInput = forwardRef((props, ref) => {
  const {
    id,
    title,
    placeholder,
    disabled,
    defaultValue,
    value,
    icon,
    iconClick,
    type,
    error,
    onChange,
    onKeyDown,
    onBlur,
    required,
    className,
    maxLength,
    min,
    max,
    variant,
    name,
      step,
    onKeyUp,
    prefix,
    readOnly,
  } = props;

  return (
    <Wrapper className={className} required={required} type={type}>
      {title !== undefined && <label htmlFor={id}>{title}</label>}
      <InputWrapper
        variant={variant}
        className={cx({
          disabled: disabled,
          error: error,
        })}
      >
        {prefix && <Prefix>{prefix}</Prefix>}
        <input
          id={id}
          ref={ref}
          readOnly={readOnly}
          placeholder={placeholder}
          type={type}
          step={step}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          name={name}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          max={max}
          required={required}
        />
        {icon && <span onClick={iconClick}>{icon}</span>}
      </InputWrapper>
    </Wrapper>
  );
});

const Prefix = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  opacity: 0.5;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 1px;
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #bac4cf;
  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: #f8f9fb;
      border: 1px solid #f8f9fb;
    `}
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  padding-left: 11px;
  &:focus-within {
    border-color: #114377;
  }
  & input,
  textarea {
    border: none;
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
    ::placeholder {
      color: #aab7c4;
      opacity: 1;
      font-weight: 500;
    }
  }
  input {
    height: 44px;
  }
  textarea {
    min-height: 80px;
    padding-top: 10px;
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    margin-right: 10px;
  }
  &.error {
    border-color: #f13737;
  }
  &:not(.disabled) span:hover {
    color: #00fcb6;
    cursor: pointer;
  }
  &.disabled input {
    color: #bac4cf;
  }
  &.disabled {
    background-color: #eef2f6;
  }
  &.disabled span svg {
    stroke: #bac4cf;
  }
`;

export const Wrapper = styled.div`
  & label {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 6px;
    line-height: 22px;
    display: block;
    min-height: 22px;
    // If input is required, add the red asterisk
    ${({ required }) =>
      required &&
      css`
        &:after {
          content: " *";
          color: red;
        }
      `}
  }
  ${({ type }) =>
    type === "hidden" &&
    css`
      display: none;
    `}
`;

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.any,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  icon: PropTypes.any,
  iconClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefix: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  type: PropTypes.oneOf(["text", "password", "hidden", "number"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  textarea: PropTypes.bool,
  alwaysShowMask: PropTypes.bool,
  mask: PropTypes.any,
  maskPlaceholder: PropTypes.any,
};

TextInput.defaultProps = {
  type: "text",
  disabled: false,
  error: "",
  variant: "primary",
};

export default TextInput;
