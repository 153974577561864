import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as PrebuyImg } from "../../../shared/assets/icons/Shop/Prebuy.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import Api from "../../../utils/api";
import Checkbox from "../../../shared/components/Checkbox";

const PrebuyAutofill = ({ onUpdate, onLater }) => {
  const [credits, setCredits] = useState({});
  const [currentClinic, setCurrentClinic] = useState({});
  const [clinicPricing, setClinicPricing] = useState({});
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [autofill, setAutofill] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const [confirmPrebuy, setConfirmPrebuy] = useState(false);

  const getCredits = async () => {
    try {
      const response = await Api.get(`/profile/clinic/credits`);
      setCredits(response.data);
    } catch (e) {}
  };
  const getCurrentClinic = async () => {
    try {
      const response = await Api.get("/profile/clinic");
      setCurrentClinic(response.data);
      setAutofill(response.data.settings.prebuy_autofill);
    } catch (e) {}
  };
  const getClinicPricing = async () => {
    try {
      const response = await Api.get(`/profile/clinic/pricing`);
      setClinicPricing(response.data.tests);
    } catch (e) {}
  };

  const doToggle = useCallback(
    async (auto) => {
      setLoading(true);
      setError("");
      try {
        if (auto !== currentClinic.settings.prebuy_autofill) {
          if (auto && currentClinic?.credits?.hr5 <= 0) {
            await Api.post("/billing/prebuy", {});
            setPurchase(true);
          }
          await Api.put("/billing/prebuy/toggle");
        }
        await getCredits();
        setSuccess(true);
      } catch (e) {
        if (e.response.data.error) {
          setError(e.response.data.error);
        }
      }
      setLoading(false);
    },
    [currentClinic]
  );

  useState(() => {
    Promise.all([getCredits(), getClinicPricing(), getCurrentClinic()]).then(() => {
      setInit(true);
    });
  }, []);

  if (!init) return null;
  return (
    <Modal open onClose={onLater}>
      <Wrapper>
        <Header>
          <PrebuyImg />
          <h3>Pre-buy Auto-renew Subscription</h3>
        </Header>
        {!success && (
          <>
            <Content>
              <Checkbox label checked={autofill} onChange={() => setAutofill(!autofill)}>
                Subscribe to auto-renew pre-buy credits{" "}
                {currentClinic.settings.prebuy_autofill ? "(Subscribed)" : "(Not Subscribed)"}
              </Checkbox>
              <div style={{ marginLeft: "30px", fontSize: "11px" }}>
                <i>
                  By subscribing to auto-renew, you are authorizing Direct Diagnostics to
                  automatically place a pre-buy order for your account every time your pre-buy
                  credits reach 0.
                </i>
              </div>
              <br />
              <div style={{ marginLeft: "30px", fontSize: "11px" }}>
                <strong>Pre-buy Terms:</strong>
                <ul style={{ paddingInlineStart: "10px" }}>
                  <li>All Pre-buy charges are non-refundable</li>
                  <li>All Pre-buy credits never expire and will remain on account until used</li>
                  <li>
                    Pre-buy Subscription can be cancelled at anytime by simply unchecking the
                    "Pre-buy Subscription" box
                  </li>
                </ul>
                <span>
                  By clicking "Save" you are agreeing to the "Pre-buy Terms" stated above.
                </span>
                <br />
                {currentClinic?.credits?.hr5 <= 0 &&
                  `You will first be charged for 32 credits: $${(
                    clinicPricing.hr5?.prebuy / 100
                  ).toFixed(2)}`}
              </div>
            </Content>
            <Buttons>
              <Button variant="secondary" onClick={onLater} disabled={loading}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  doToggle(autofill);
                }}
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Buttons>
            {error && <Error>{error}</Error>}
          </>
        )}
        {success && (
          <>
            <Content
              style={{
                textAlign: "center",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {purchase && <strong>Your purchase was successful.</strong>}
              {!currentClinic?.settings?.prebuy_autofill && autofill && (
                <div>You have successfully subscribed to auto-renew pre-buy credits.</div>
              )}
              {currentClinic?.settings?.prebuy_autofill && !autofill && (
                <div>You have successfully unsubscribed to auto-renew pre-buy credits.</div>
              )}
              <div>
                You have <strong>{credits.hr5}</strong> credits remaining.
              </div>
            </Content>
            <Buttons>
              <Button variant="secondary" onClick={onLater}>
                Close
              </Button>
            </Buttons>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

PrebuyAutofill.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 450px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default PrebuyAutofill;
