import styled, { css } from "styled-components";
import { useEffect, useRef, useState, useCallback } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import { rgba } from "polished";
import Button from "../../../../shared/components/Button";
import Modal from "../../../../shared/components/Modal";
import { ActionButton, ActionItem } from "../../../../shared/components/ActionButton";
import { ReactComponent as SearchIcon } from "../../../../shared/assets/icons/Other/Search.svg";
import { ReactComponent as UserIcon } from "../../../../shared/assets/icons/People/User.svg";
import { ReactComponent as MailImg } from "../../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as PhoneImg } from "../../../../shared/assets/icons/Tech/Smartphone.svg";
import { ReactComponent as ShareImg } from "../../../../shared/assets/icons/Other/Share.svg";
import { ReactComponent as ResultsImg } from "../../../../shared/assets/icons/File And Folder/Summary.svg";
import { ReactComponent as SentImg } from "../../../../shared/assets/icons/Other/Send.svg";
import TextInput from "../../../../shared/components/TextInput";
import Api from "../../../../utils/api";
import Select from "../../../../shared/components/Select";
import { useNavigate } from "react-router-dom";
import SendResult from "../../../../components/Modals/SendResult";
import { FormatBirthdate } from "../../../../shared/utilities/FormatBirthdate";
import User from "../../../../components/Modals/Settings/User";
import EditPatient from "../../../../components/Modals/EditPatient";
import ReactTooltip from "react-tooltip";

const defaultSort = { type: "resulted", dir: "desc", limit: 25 };
let defaultSearch = {
  flaggedOnly: false,
  homeOnly: false,
  sort: defaultSort,
  page: 1,
  limit: null,
  search: "",
  orderFilter: {},
};
let resultSearch;

const ViewResults = () => {
  // Check for saved search
  if (resultSearch === undefined) {
    resultSearch = defaultSearch;
    try {
      const cnf = localStorage.getItem("resultSearch");
      if (cnf) {
        resultSearch = JSON.parse(cnf);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const { setTitle } = useTitle();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selected, setSelected] = useState("");
  const [results, setResults] = useState({ data: [], page: 1 });
  const [loaded, setLoaded] = useState(false);
  const [sort, setSort] = useState(resultSearch.sort);
  const [sendModal, setSendModal] = useState(null);
  const [lim, setLim] = useState(resultSearch.limit);
  const [page, setPage] = useState(resultSearch.page);
  const [editPatient, setEditPatient] = useState("");
  const navigate = useNavigate();
  const searchRef = useRef();

  const fetchResults = useCallback(
    async (pg, lmt) => {
      try {
        const searchObj = {
          sort: sort.type,
          sort_dir: sort.dir,
          limit: lmt,
          page: pg,
          search: !loaded ? resultSearch.search : searchRef.current?.value || "",
        };
        const response = await Api.post(`/results/search`, searchObj);
        setResults(response.data);
        resultSearch.search = searchObj.search;
        resultSearch.page = pg;
        resultSearch.limit = lmt;
        resultSearch.sort = sort;
        localStorage.setItem("resultSearch", JSON.stringify(resultSearch));
      } catch (e) {}
    },
    [sort, loaded]
  );

  const selectRow = (e) => {
    setSelected(e.target.getAttribute("data-id"));
  };

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort.dir = "asc";
        }
      }
      console.log(newSort);
      setSort(newSort);
    },
    [sort]
  );

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const resultRowClick = (e, result) => {
    if (e.target.tagName === "TD") {
      navigate(`/results/view/${result.order_id}`);
    }
  };

  const setNextPage = useCallback(
    (p) => {
      setPage(p);
      fetchResults(p, lim);
    },
    [fetchResults, lim]
  );

  const changeLimit = useCallback(
    (l) => {
      setPage(1);
      setLim(l);
      fetchResults(1, l);
    },
    [fetchResults, lim]
  );

  const getPages = useCallback(() => {
    return (
      <Paging>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Label>Limit</Label>
          <StyledSelect
            style={{ flex: 1 }}
            onChange={(e) => changeLimit(Number(e.target.value))}
            value={lim}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </StyledSelect>
        </div>
        <div>Results: {results.results}</div>
        <Pagination>
          {results.page <= results.pages && results.page !== 1 && (
            <Page onClick={() => setNextPage(page - 1)}>&laquo;</Page>
          )}
          <span>
            Page {results.page} of {results.pages}
          </span>
          {results.page < results.pages && (
            <Page onClick={() => setNextPage(page + 1)}>&raquo;</Page>
          )}
        </Pagination>
      </Paging>
    );
  }, [results, page, setNextPage]);

  // Set page title
  useEffect(() => {
    setTitle({ name: "View Results" });
  }, [setTitle]);

  useEffect(() => {
    fetchResults(resultSearch.page, lim).then(() => setLoaded(true));
  }, [sort]);

  if (!loaded) return null;

  return (
    <Wrapper>
      <div style={{ display: "flex", gap: "20px", alignItems: "flex-end" }}>
        <div>
          <StyledTextInput
            id={"search"}
            placeholder={"Search"}
            title={"Search Results"}
            ref={searchRef}
            variant="secondary"
            defaultValue={resultSearch.search}
            icon={<SearchIcon />}
            iconClick={() => fetchResults(1, lim)}
            onKeyUp={(e) => {
              if (e.code === "Enter") fetchResults(1, lim);
            }}
          />
        </div>
      </div>
      {results.data.length > 0 && getPages()}
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th aria-sort={getSorted("resulted")} onClick={() => toggleSort("resulted")}>
              Result Date
            </th>
            <th aria-sort={getSorted("patient.first")} onClick={() => toggleSort("patient.first")}>
              First Name
            </th>
            <th aria-sort={getSorted("patient.last")} onClick={() => toggleSort("patient.last")}>
              Last Name
            </th>
            <th>Date of Birth</th>
            <th aria-sort={getSorted("order.barcode")} onClick={() => toggleSort("order.barcode")}>
              Barcode
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {results.data.map((result) => (
            <ResultRow
              viewed={result.viewed}
              onClick={(e) => resultRowClick(e, result)}
              key={result.id}
            >
              <td />
              <td>{new Date(result.resulted).toLocaleDateString()}</td>
              <td>{result.patient.first}</td>
              <td>{result.patient.last}</td>
              <td>{FormatBirthdate(result.patient.dob)}</td>
              <td>{result.order.barcode}</td>
              <td>
                <StyledAction
                  open={selected === result.id}
                  title="More"
                  id={result.id}
                  onClick={selectRow}
                  onClose={() => setSelected("")}
                >
                  <ActionItem
                    color="#114377"
                    onClick={() => navigate(`/results/view/${result.order_id}`)}
                  >
                    <ResultsImg />
                    View Results
                  </ActionItem>
                  <ActionItem
                    color="#114377"
                    onClick={() => {
                      setSendModal({
                        orderId: result.order_id,
                        mode: "sms_email",
                        email: result.patient.email,
                        phone: result.patient.phone,
                      });
                    }}
                  >
                    <ShareImg />
                    Send Results
                  </ActionItem>
                  <ActionItem
                    color="#114377"
                    onClick={() => {
                      setSendModal({
                        orderId: result.order_id,
                        mode: "email",
                        email: result.patient.email,
                      });
                    }}
                  >
                    <MailImg />
                    Send Results - Email
                  </ActionItem>
                  <ActionItem
                    color="#114377"
                    onClick={() => {
                      setSendModal({
                        orderId: result.order_id,
                        mode: "sms",
                        phone: result.patient.phone,
                      });
                    }}
                  >
                    <PhoneImg />
                    Send Results - Text
                  </ActionItem>
                  <ActionItem
                    color="#114377"
                    onClick={() => {
                      setSendModal({ orderId: result.order_id, mode: "pdf" });
                    }}
                  >
                    <ShareImg />
                    Send Results - PDF
                  </ActionItem>
                  <ActionItem color="#114377" onClick={() => setEditPatient(result.patient.id)}>
                    <UserIcon />
                    Edit Patient
                  </ActionItem>
                </StyledAction>
                {((result.shared !== undefined && result.shared > 0) ||
                  (result.patient_link !== undefined && result.patient_link > 0)) && (
                  <SentImg
                    style={{ height: "20px", stroke: "rgba(17,67,119,0.5)" }}
                    data-tip
                    data-for="sentTip"
                  />
                )}
              </td>
              <td />
            </ResultRow>
          ))}
        </tbody>
      </StyledTable>
      <ReactTooltip id="sentTip" effect="solid" className="tooltip">
        Result has been sent
      </ReactTooltip>
      {results.data.length > 0 && getPages()}
      {deleteModal && <Modal open onClose={() => setDeleteModal(false)} />}
      {sendModal && (
        <SendResult
          onClose={() => {
            setSendModal(null);
            setSelected("");
            fetchResults(page, lim);
          }}
          orderId={sendModal.orderId}
          phone={sendModal.phone}
          email={sendModal.email}
          mode={sendModal.mode}
        />
      )}
      {editPatient && (
        <EditPatient
          order
          onClose={() => {
            setEditPatient("");
            setSelected("");
            fetchResults(page, lim);
          }}
          patientId={editPatient}
        />
      )}
    </Wrapper>
  );
};

const StyledAction = styled(ActionButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${StyledAction} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  thead tr th {
    padding-bottom: 10px;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    border-collapse: separate;
    border-spacing: 5px 5px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    &:after {
      content: "";
      display: inline-flex;
      margin-left: 5px;
      width: 20px;
      font-size: 8px;
    }
    &[aria-sort="ascending"]:after {
      content: " ▲";
    }
    &[aria-sort="descending"]:after {
      content: " ▼";
    }
  }
  th:first-child,
  th:last-child,
  td:first-child,
  td:last-child {
    border: none;
    width: 10px;
    padding-right: 0;
  }
  tbody tr:hover {
    cursor: pointer;
    background-color: #eef2f6;
    td:first-child {
      border-radius: 20px 0 0 20px;
    }
    td:last-child {
      border-radius: 0 20px 20px 0;
    }
    ${StyledAction} {
      opacity: 1;
    }
  }
  td {
    border-collapse: collapse;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
`;

const ResultRow = styled.tr`
  ${({ viewed }) =>
    !viewed &&
    css`
      font-weight: bold;
      color: #114377;
    `}
  position: relative;
`;

const ReturnedResults = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4f4f4f;
`;

const StyledTextInput = styled(TextInput)`
  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
  }
  max-width: 380px;
`;

const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const Page = styled.div`
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 14px;
  ${({ active }) =>
    !active &&
    css`
      :hover {
        background-color: #ddd;
        cursor: pointer;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #4caf50;
      color: white;
    `}
`;

const Pagination = styled.div`
  display: flex;
  & * {
    padding: 8px 8px;
  }
  gap: 5px;
`;

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  min-height: 25px;
`;

const Wrapper = styled.div`
  font-family: Poppins, serif;
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  gap: 20px;
`;

export default ViewResults;
