import styled, { css } from "styled-components";
import Logo from "../../shared/components/Logo";
import Copyright from "../../shared/components/Copyright";
import NavigationLink from "../NavigationLink/NavigationLink";
import { ReactComponent as GridImg } from "../../shared/assets/icons/Layers/Grid.svg";
import { ReactComponent as MessagesImg } from "../../shared/assets/icons/Action/Message Box-Square 2.svg";
import { ReactComponent as LogoutImg } from "../../shared/assets/icons/Action/Log Out.svg";
import { ReactComponent as LoginImg } from "../../shared/assets/icons/Action/Log In.svg";
import { ReactComponent as NavImg } from "../../shared/assets/icons/Other/Menu alt 2.svg";
import { ReactComponent as OrdersImg } from "../../shared/assets/icons/File And Folder/File Text.svg";
import { ReactComponent as TestImg } from "../../shared/assets/icons/File And Folder/File Plus.svg";
import { ReactComponent as BoxImg } from "../../shared/assets/icons/Other/Box.svg";
import { ReactComponent as ResultsImg } from "../../shared/assets/icons/File And Folder/Summary.svg";
import { ReactComponent as SettingsImg } from "../../shared/assets/icons/Other/Settings.svg";
import { ReactComponent as ResourcesImg } from "../../shared/assets/icons/Book/Closed-Book.svg";
import { ReactComponent as HelpImg } from "../../shared/assets/icons/Circle/_.svg";
import { ReactComponent as PatientImg } from "../../shared/assets/icons/People/User search.svg";
import { ReactComponent as TrainingImg } from "../../shared/assets/icons/Other/College Affiliation.svg";
import NavigationItem from "../../shared/components/NavigationItem";
import { useCallback, useContext, useEffect, useState } from "react";
import { MainStore } from "../../stores/main";
import { Row } from "../../shared/styled/Flexbox";
import ContactSupport from "../Modals/ContactSupport";
import { apiPath } from "../../utils/api";
import OrderSupplies from "../Modals/OrderSupplies";
import NoticeMessage from "../Modals/NoticeMessage";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const Sidebar = ({ active }) => {
  const { toggleSidebar, handleLogout, sso } = useContext(MainStore);
  const [contactModal, setContactModal] = useState("");
  const [orderModal, setOrderModal] = useState(false);
  const [holidayNoticeModal, setHolidayNoticeModal] = useState(false);
  const navigate = useNavigate();
  const handleReturnAdmin = () => {
    localStorage.clear();
    window.location = apiPath + "/login/revert";
  };

  return (
    <Outer active={active}>
      <Wrapper active={active}>
        <Row>
          <Logo variant="dark" />
          <StyledNav onClick={() => toggleSidebar(false)}>
            <NavImg />
          </StyledNav>
        </Row>
        <NavItems>
          {/*<div data-tip data-for="disabledTip">*/}
          {/*  <DisabledNavLink title={"Order a Test"} icon={<TestImg />} />*/}
          {/*</div>*/}
          <GreenNavigationLink
            onClick={() => navigate(`/orders/new`)}
            title={"Order a Test"}
            icon={<TestImg />}
          />
          <HR />
          <NavigationLink to={"dashboard"} title={"Dashboard"} icon={<GridImg />} />
          <NavigationLink to={"patients/view"} title={"View Patients"} icon={<PatientImg />} />
          <NavigationLink to={"orders/view"} title={"View Orders"} icon={<OrdersImg />} />
          <NavigationLink to={"results/view"} title={"View Results"} icon={<ResultsImg />} />
          {/*<NavigationLink*/}
          {/*  external*/}
          {/*  to={"https://www.directdiagnostics.com/clinician-resources"}*/}
          {/*  title={"Resources"}*/}
          {/*  icon={<LibraryImg />}*/}
          {/*/>*/}
          <NavigationLink to={"settings"} title={"Settings"} icon={<SettingsImg />} />
          {/*<NavigationItem*/}
          {/*  to={"messages"}*/}
          {/*  title={"Contact Support"}*/}
          {/*  icon={<MessagesImg />}*/}
          {/*  onClick={() => setContactModal("custom")}*/}
          {/*/>*/}
          <NavigationItem
            title={"Order Supplies"}
            icon={<BoxImg />}
            onClick={() => setOrderModal(true)}
          />
          <NavigationLink
            external
            to={"https://help.directdx.app/en/"}
            title={"Help Center"}
            icon={<HelpImg />}
          />
          <NavigationLink
            external
            to={"https://training.directdx.app/en/"}
            title={"Training Center"}
            icon={<TrainingImg />}
          />
          <NavigationLink
            external
            to={"https://resources.directdx.app/en/"}
            title={"Resource Center"}
            icon={<ResourcesImg />}
          />
          {sso && (
            <NavigationItem
              title={"Return to Admin"}
              icon={<LoginImg />}
              onClick={handleReturnAdmin}
            />
          )}
          <NavigationItem title={"Logout"} icon={<LogoutImg />} onClick={handleLogout} />
        </NavItems>
        <SCopyright color="#4F4F4F" />
      </Wrapper>
      {/*{contactModal !== "" && (*/}
      {/*  <ContactSupport*/}
      {/*    onClose={() => setContactModal("")}*/}
      {/*    supplies={contactModal}*/}
      {/*    onOrder={() => {*/}
      {/*      setContactModal("");*/}
      {/*      setOrderModal(true);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {orderModal && <OrderSupplies onClose={() => setOrderModal(false)} />}
      {holidayNoticeModal && (
        <NoticeMessage
          onClose={() => {
            setHolidayNoticeModal(false);
            navigate(`/orders/new`);
          }}
        />
      )}
      <ReactTooltip id="disabledTip" effect="solid" className="tooltip">
        Ordering closed for the holidays.
      </ReactTooltip>
    </Outer>
  );
};

const Outer = styled.div`
  ${({ active }) =>
    !active &&
    css`
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      z-index: 1000;
      background-color: rgba(17, 67, 119, 0.4);
    `}
  ${({ active }) => active && css``}
`;

const StyledNav = styled.button`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  position: absolute;
  right: 20px;
  top: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Wrapper = styled.div`
  ${({ active }) =>
    !active &&
    css`
      position: relative;
      padding: 20px 30px 10px 20px;
      background-color: white;
      max-width: 270px;
      height: 100%;
    `}

  ${({ active }) =>
    active &&
    css`
      ${StyledNav} {
        display: none;
      }
      flex: 1;
      flex-direction: column;
      padding: 30px;
      align-items: center;
      display: none;
      max-width: 220px;
      @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
        max-width: 270px;
        display: flex;
      }
    `}
`;

const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;

const SCopyright = styled(Copyright)`
  flex: 1;
`;

const NavItems = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
  > :last-child {
    margin-top: auto;
  }
`;

const GreenNavigationLink = styled(NavigationItem)`
  background-color: #3cad69;
  color: #ffffff;
  font-weight: 600;
  svg {
    stroke: #ffffff;
    & .svgoverride {
      fill: white;
    }
  }
`;

const DisabledNavLink = styled(NavigationItem)`
  background-color: #7a7a7a;
  color: #ffffff;
  font-weight: 600;
  cursor: default;
  svg {
    stroke: #ffffff;
    & .svgoverride {
      fill: white;
    }
  }
  &:hover {
    cursor: default;
    background-color: #7a7a7a;
    box-shadow: none;
  }
`;

export default Sidebar;
