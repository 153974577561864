import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import Checkbox from "../../../shared/components/Checkbox";
import Api from "../../../utils/api";
import API from "../../../utils/api";
import { useNavigate } from "react-router";
import ErrorText from "../../../shared/components/ErrorText";
import { set } from "react-hook-form";
import OrderSupplies from "../OrderSupplies";
import { useCookies } from "react-cookie";
import { MainStore } from "../../../stores/main";

const Prebuy = ({
  initialScreen = "plans",
  offerId,
  onUpdate,
  onLater,
  onComplete,
  onDismiss,
  onSupplies,
  defaultPlan,
  clinicPricing,
  clinicProfile,
}) => {
  const [screen, setScreen] = useState(initialScreen);
  const [terms, setTerms] = useState({ auth: false, terms: false, certify: false });
  const [badBilling, setBadBilling] = useState(false);
  const [purchasing, setPurchasing] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [singlePurchase, setSinglePurchase] = useState(initialScreen === "single_purchase");
  const [plan, setPlan] = useState(defaultPlan);
  const [existingPlan, setExistingPlan] = useState({});
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["prebuyReminder"]);
  const navigate = useNavigate();
  const mainStoreContext = useContext(MainStore);
  const [prebuyPlans, setPrebuyPlans] = useState([]);

  const getPrebuyPlans = async () => {
    try {
      const response = await Api.get("/global/pricing/prebuy_plans");

      if (response && response.data) {
        response.data.forEach((plan) => {
          if (plan.id === clinicProfile.settings.prebuy_plan_id) {
            setExistingPlan(plan);
          }
        });
      }

      setPrebuyPlans(response.data);
    } catch (e) {}
  };

  // Get billing details
  const getBillingDetails = async () => {
    const clinicResponse = await Api.get("/profile/clinic");
    if (!clinicResponse.data.verified_payment) {
      setBadBilling(true);
    } else {
      const response = await API.get("/billing/details");
      if (typeof response.data == "object") {
        // Check if the expiry is this month
        const today = new Date();
        if (response.data.expiry === `${today.getMonth() + 1}/${today.getFullYear()}`) {
          setBadBilling(true);
        }
      }
    }
  };

  const pageNav = (path) => {
    navigate(path);
    onLater();
  };

  // Get billing details on mount
  useEffect(() => {
    getBillingDetails();
    getPrebuyPlans();

    // Set that the user has seen the popup
    setCookie("prebuyReminder", "shown", { path: "/" });
  }, []);

  const dismiss = () => {
    onDismiss();
    onLater();
  };

  const getTestPrice = (credits, discountPercentage, clinicPricing) => {
    const basePrice = clinicPricing.hr5?.clinic / 100;
    const eaPrice = Number(basePrice - basePrice * (discountPercentage / 100)).toFixed(2);
    const planPrice = Number(eaPrice * credits).toFixed(2);
    return {
      eaPrice,
      planPrice,
    };
  };

  const doCancel = async () => {
    try {
      await API.delete("/billing/prebuy");
      onUpdate();
    } catch (e) {
      if (e.response.data.error) {
        setError(e.response.data.error);
      }
    }
  };

  const buy = useCallback(async () => {
    if (!purchasing) {
      setPurchasing(true);
    }
    try {
      const response = await API.post(`/billing/prebuy`, {
        plan_id: plan.id,
        autofill: !singlePurchase,
      });
      if (response.data.error) {
        setError(response.data.error);
      } else {
        if (clinicProfile.settings.prebuy_plan_id) {
          setScreen("success-update");
        } else {
          setScreen("success");
        }
      }
    } catch (e) {
      console.log(e);
      if (e.response && e.response.data && e.response.data.error) {
        setError(e.response.data.error);
      } else {
        setError("error");
      }
    }
    setPurchasing(false);
  }, [purchasing, singlePurchase, plan, clinicProfile]);

  return (
    <Modal open onClose={onLater}>
      {orderModal && <OrderSupplies onClose={() => setOrderModal(false)} />}
      <Wrapper>
        {screen === "new-plans" && (
          <>
            <Header>
              <h3 style={{ color: "green" }}>
                Our New Pricing and Expanded Pre-buy Options are Now Live
              </h3>
            </Header>
            <Content>
              {clinicProfile.settings.prebuy_plan_id ? (
                <>
                  <div style={{ fontSize: "14px", color: "#4f4f4f", fontWeight: "bold" }}>
                    Your Updated Pre-buy:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      gap: "30px",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div>Credits</div>
                      <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                        {existingPlan.credits}
                      </div>
                    </div>
                    <div>
                      <div>Price Per Test</div>
                      <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                        $
                        {
                          getTestPrice(
                            existingPlan.credits,
                            existingPlan.discount_percentage,
                            clinicPricing
                          ).eaPrice
                        }{" "}
                      </div>
                    </div>
                    <div>
                      <div>Savings</div>
                      <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                        {existingPlan.discount_percentage}%
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ fontSize: "14px", color: "#4f4f4f", fontWeight: "bold" }}>
                    Your Current À la carte Price:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      gap: "30px",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div>Price Per Test</div>
                      <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                        ${clinicPricing.hr5.clinic / 100}
                      </div>
                    </div>
                    <div>
                      <div>Savings</div>
                      <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                        0%
                      </div>
                    </div>
                  </div>
                </>
              )}
              <h3 style={{ color: "darkred" }}>New Pre-buy Options</h3>
              <PrebuyPlans>
                {prebuyPlans.map((plan, i) => (
                  <Plan active={clinicProfile.settings.prebuy_plan_id === plan.id}>
                    <div>
                      <strong style={{ fontSize: "14px" }}>{plan.credits}</strong>
                      <br />
                      Credits
                    </div>
                    <div>
                      <strong>
                        $
                        {
                          getTestPrice(plan.credits, plan.discount_percentage, clinicPricing)
                            .eaPrice
                        }
                      </strong>
                      <br />
                      per test
                    </div>
                    <div>
                      {plan.discount_percentage}%<br />
                      savings
                    </div>
                    <div>
                      $
                      {
                        getTestPrice(plan.credits, plan.discount_percentage, clinicPricing)
                          .planPrice
                      }
                      <br />
                      Pre-buy total
                    </div>
                    {mainStoreContext.role === "clinic_admin" && (
                      <div>
                        {clinicProfile.settings.prebuy_plan_id === plan.id ? (
                          <Subscribe style={{ backgroundColor: "#114377" }} disabled>
                            Subscribed
                          </Subscribe>
                        ) : (
                          <Subscribe
                            onClick={() => {
                              setPlan(plan);
                              setScreen("subscribe");
                            }}
                          >
                            Subscribe
                          </Subscribe>
                        )}
                      </div>
                    )}
                  </Plan>
                ))}
              </PrebuyPlans>
            </Content>
            {mainStoreContext.role !== "clinic_admin" && (
              <p style={{ color: "darkred" }}>
                To purchase a pre-buy subscription, please consult with your office’s primary admin
                user or reach out to your Direct Diagnostics account manager.
              </p>
            )}
            <Buttons style={{ flexDirection: "row" }}>
              {mainStoreContext.role === "clinic_admin" && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setCookie("prebuyReminder", new Date().getTime(), { path: "/" });
                      setScreen("later");
                    }}
                    style={{ minHeight: "40px", color: "#4f4f4f" }}
                  >
                    Show me again later
                  </Button>
                  <Button variant="secondary" onClick={onUpdate} style={{ minHeight: "40px" }}>
                    Keep my current price
                  </Button>
                </>
              )}
              {mainStoreContext.role !== "clinic_admin" && (
                <>
                  <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                    Ok
                  </Button>
                </>
              )}
            </Buttons>
          </>
        )}
        {screen === "cant-manage" && (
          <>
            <Content>
              <p style={{ color: "darkred" }}>
                This option is not available with an active pre- buy subscription.
                <br />
                <br />
                To change your subscription settings, click Manage under your current subscription.
              </p>
            </Content>
            <Buttons style={{ flexDirection: "row" }}>
              <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                Ok
              </Button>
            </Buttons>
          </>
        )}
        {screen === "upgrade" && (
          <>
            <Header>
              <h3 style={{ color: "green" }}>Manage Your Subscription</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", color: "#4f4f4f", fontWeight: "bold" }}>
                Your Current Pre-buy Subscription:
              </div>
              <div
                style={{
                  display: "flex",
                  textAlign: "center",
                  gap: "30px",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <div>Credits</div>
                  <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                    {existingPlan.credits}
                  </div>
                </div>
                <div>
                  <div>Price Per Test</div>
                  <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                    $
                    {
                      getTestPrice(
                        existingPlan.credits,
                        existingPlan.discount_percentage,
                        clinicPricing
                      ).eaPrice
                    }{" "}
                  </div>
                </div>
                <div>
                  <div>Savings</div>
                  <div style={{ fontSize: "16px", color: "#114377", fontWeight: "bold" }}>
                    {existingPlan.discount_percentage}%
                  </div>
                </div>
              </div>
              <h3 style={{ color: "darkred" }}>More Pre-buy Options</h3>
              <PrebuyPlans>
                {prebuyPlans.map((plan, i) => (
                  <Plan active={clinicProfile.settings.prebuy_plan_id === plan.id}>
                    <div>
                      <strong style={{ fontSize: "14px" }}>{plan.credits}</strong>
                      <br />
                      Credits
                    </div>
                    <div>
                      <strong>
                        $
                        {
                          getTestPrice(plan.credits, plan.discount_percentage, clinicPricing)
                            .eaPrice
                        }
                      </strong>
                      <br />
                      per test
                    </div>
                    <div>
                      {plan.discount_percentage}%<br />
                      savings
                    </div>
                    <div>
                      $
                      {
                        getTestPrice(plan.credits, plan.discount_percentage, clinicPricing)
                          .planPrice
                      }
                      <br />
                      Pre-buy total
                    </div>
                    <div>
                      {clinicProfile.settings.prebuy_plan_id === plan.id ? (
                        <Subscribe style={{ backgroundColor: "#114377" }} disabled>
                          Subscribed
                        </Subscribe>
                      ) : (
                        <Subscribe
                          onClick={() => {
                            setPlan(plan);
                            setScreen("update");
                          }}
                        >
                          Change Plan
                        </Subscribe>
                      )}
                    </div>
                  </Plan>
                ))}
              </PrebuyPlans>
            </Content>
            {mainStoreContext.role !== "clinic_admin" && (
              <p style={{ color: "darkred" }}>
                To manage your pre-buy subscription, please consult with your office’s primary admin
                user or reach out to your Direct Diagnostics account manager.
              </p>
            )}
            <Buttons style={{ flexDirection: "row" }}>
              {mainStoreContext.role === "clinic_admin" && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setScreen("cancel")}
                    style={{ minHeight: "40px", color: "darkred" }}
                  >
                    Cancel my subscription
                  </Button>
                  <Button variant="secondary" onClick={onUpdate} style={{ minHeight: "40px" }}>
                    Keep my current price
                  </Button>
                </>
              )}
              {mainStoreContext.role !== "clinic_admin" && (
                <>
                  <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                    Ok
                  </Button>
                </>
              )}
            </Buttons>
          </>
        )}
        {screen === "success" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Thank you for your purchase</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", color: "#114377" }}>
                {plan.credits} credits have been added to your account and your account has been
                charged with the full amount due of $
                {getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).planPrice}.
              </div>
            </Content>
            <Buttons>
              {!singlePurchase && (
                <p>
                  To manage your subscription, go to <strong>Settings</strong> and select{" "}
                  <i>Pricing & Pre-Buy</i>
                </p>
              )}
              <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                Okay
              </Button>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Your pre-buy only includes test credits. Need Supplies? Order them now
              </div>
              <Button onClick={() => setOrderModal(true)} style={{ minHeight: "40px" }}>
                Order Supplies
              </Button>
            </Buttons>
          </>
        )}
        {screen === "cancel" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Cancel Pre-buy Subscription</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", color: "#114377" }}>
                You’ve chosen to cancel your pre-buy subscription. If you have credits remaining,
                they will continue to be used for incoming test results until your credit balance
                reaches 0. <br />
                <br />
                Once all remaining credits have been used, your account will default to standard
                pricing for all new tests.
                <br />
                <br /> You can resubscribe at any time to resume saving on testing costs.
              </div>
            </Content>
            <Buttons style={{ flexDirection: "row" }}>
              <Button
                onClick={doCancel}
                style={{ minHeight: "40px", color: "darkred" }}
                variant={"secondary"}
              >
                Confirm Cancellation
              </Button>
              <Button variant="primary" onClick={onLater}>
                Keep my subscription
              </Button>
            </Buttons>
          </>
        )}
        {screen === "success-update" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Your pre-buy subscription has been updated</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", color: "#114377" }}>
                Once your current credit balance reaches zero, your new subscription will activate.
                At that time, {plan.credits} test credits will be added to your credit pool and your
                account will be charged with the full amount due of $
                {getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).planPrice}.
              </div>
            </Content>
            <Buttons>
              <p>
                To manage your subscription, go to <strong>Settings</strong> and select{" "}
                <i>Pricing & Pre-Buy</i>
              </p>
              <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                Okay
              </Button>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Your pre-buy only includes test credits. Need Supplies? Order them now
              </div>
              <Button onClick={() => setOrderModal(true)} style={{ minHeight: "40px" }}>
                Order Supplies
              </Button>
            </Buttons>
          </>
        )}
        {screen === "later" && (
          <>
            <Content>
              <div style={{ fontSize: "14px", color: "#114377" }}>
                We’ll show you the new pre-buy options once more in 7 days. You can also access your
                pre-buy options and manage subscriptions through your account settings.
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={onUpdate} style={{ minHeight: "40px" }}>
                Got It!
              </Button>
            </Buttons>
          </>
        )}
        {screen === "single_purchase" && (
          <>
            <Header>
              <h3 style={{ color: "rgb(70,114,196)" }}>Confirm Your Pre-buy Purchase</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                You have chosen to purchase a single pre-buy test credit bundle. To complete your
                purchase, you must agree to the terms and conditions outlined in the purchase
                details. Once you complete the purchase, your chosen test credit amount will be
                added to your credit pool and your account will be automatically charged for the
                total amount due.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#114377",
                  marginTop: "15px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                Test Credits: {plan.credits}
                <br />
                Cost per Test: $
                {getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).eaPrice}
                <br />
                Amount Due Today:{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  ${getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).planPrice}
                </span>
              </div>
              <div
                style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Checkbox
                  label
                  checked={terms.certify}
                  onClick={() => setTerms({ ...terms, certify: !terms.certify })}
                >
                  I certify that I am authorized to make this purchase on behalf of my clinic or
                  practice.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.auth}
                  onClick={() => setTerms({ ...terms, auth: !terms.auth })}
                >
                  I authorize Direct Diagnostics to collect the amount due immediately upon
                  purchase.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.terms}
                  onClick={() => setTerms({ ...terms, terms: !terms.terms })}
                >
                  I have read and understand the terms and conditions.
                </Checkbox>
              </div>
            </Content>
            <Buttons>
              {error && (
                <ErrorText error={error !== "error" ? error : "Unknown Error, Contact Support"} />
              )}
              <Button
                variant="green"
                onClick={() => buy()}
                disabled={!terms.auth || !terms.terms || purchasing}
                loading={purchasing}
              >
                Confirm Purchase
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
            <Content>
              <div style={{ textAlign: "left", fontSize: "11px", color: "gray" }}>
                <strong>Terms and conditions</strong>
                <br />
                <br />
                <i>
                  Full payment is due upon purchase. Test credits will be used for each new
                  in-clinic test order upon submission. Test credits purchased will not expire and
                  will automatically be used when submitting test orders until your test credit
                  balance reaches zero. If a sample is rejected, the test credit used will be
                  returned to your credit pool automatically. This purchase is non-refundable and
                  non-exchangeable. Test credits only apply to in-clinic tests and will not apply to
                  at-home tests. Any unused credits will remain in your credit pool. We reserve the
                  right to update these terms and conditions at any time.
                </i>
              </div>
            </Content>
          </>
        )}
        {screen === "subscribe" && (
          <>
            <Header>
              <h3 style={{ color: "rgb(70,114,196)" }}>Confirm Your Subscription Purchase</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                You have chosen to purchase a new pre-buy subscription. To complete your purchase,
                you must agree to the terms and conditions outlined in the subscription details.
                Once you complete the purchase, your subscription will activate and your account
                will be automatically charged for the total amount due.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#114377",
                  marginTop: "15px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                Test Credits: {plan.credits}
                <br />
                Cost per Test: $
                {getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).eaPrice}
                <br />
                Amount Due Today:{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  ${getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).planPrice}
                </span>
              </div>
              <div
                style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Checkbox
                  label
                  checked={terms.certify}
                  onClick={() => setTerms({ ...terms, certify: !terms.certify })}
                >
                  I certify that I am authorized to make this purchase on behalf of my clinic or
                  practice.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.auth}
                  onClick={() => setTerms({ ...terms, auth: !terms.auth })}
                >
                  I authorize Direct Diagnostics to collect the amount due immediately upon
                  purchase.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.terms}
                  onClick={() => setTerms({ ...terms, terms: !terms.terms })}
                >
                  I have read and understand the terms and conditions.
                </Checkbox>
              </div>
            </Content>
            <Buttons>
              {error && (
                <ErrorText error={error !== "error" ? error : "Unknown Error, Contact Support"} />
              )}
              <Button
                variant="green"
                onClick={() => buy()}
                disabled={!terms.auth || !terms.terms || purchasing}
                loading={purchasing}
              >
                Confirm Purchase
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
            <Content>
              <div style={{ textAlign: "left", fontSize: "11px", color: "gray" }}>
                <strong>Terms and conditions</strong>
                <br />
                <br />
                <i>
                  Full payment is due upon purchase. Test credits will be used for each new
                  in-clinic test order upon submission. Test credits purchased under this
                  subscription will not expire and will automatically be used when submitting test
                  orders until your test credit balance reaches zero. Once your test credit balance
                  reaches zero, this subscription will automatically renew, and your account will be
                  charged via the payment method on file. If a sample is rejected, the test credit
                  used will be returned to your credit pool automatically. Purchase of this
                  subscription is non-refundable and non-exchangeable. Test credits only apply to
                  in-clinic tests and will not apply to at-home tests. You have the option to cancel
                  this subscription at any time without penalty. Any unused credits will remain in
                  your credit pool. We reserve the right to update these terms and conditions at any
                  time.
                </i>
              </div>
            </Content>
          </>
        )}
        {screen === "update" && (
          <>
            <Header>
              <h3 style={{ color: "rgb(70,114,196)" }}>Confirm Your Subscription Update</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                You have chosen to update your pre-buy subscription. To complete your purchase, you
                must agree to the terms and conditions outlined in the subscription details. Your
                subscription will be updated when your current credit balance reaches zero and your
                account will be charged automatically via the payment method on file.
              </div>
              <div>Current Pre-buy Subscription</div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#114377",
                  marginTop: "15px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                Test Credits: {existingPlan.credits}
                <br />
                Cost per Test: $
                {
                  getTestPrice(
                    existingPlan.credits,
                    existingPlan.discount_percentage,
                    clinicPricing
                  ).eaPrice
                }
              </div>
              <div>
                <strong>New Pre-buy Subscription</strong>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#114377",
                  marginTop: "15px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                Test Credits: {plan.credits}
                <br />
                Cost per Test: $
                {getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).eaPrice}
                <br />
                Total Amount:{" "}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  ${getTestPrice(plan.credits, plan.discount_percentage, clinicPricing).planPrice}
                </span>
              </div>
              <div
                style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Checkbox
                  label
                  checked={terms.certify}
                  onClick={() => setTerms({ ...terms, certify: !terms.certify })}
                >
                  I certify that I am authorized to make this purchase on behalf of my clinic or
                  practice.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.auth}
                  onClick={() => setTerms({ ...terms, auth: !terms.auth })}
                >
                  I authorize Direct Diagnostics to collect the amount due at the time of each
                  subscription renewal.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.terms}
                  onClick={() => setTerms({ ...terms, terms: !terms.terms })}
                >
                  I have read and understand the terms and conditions.
                </Checkbox>
              </div>
            </Content>
            <Buttons>
              {error && (
                <ErrorText error={error !== "error" ? error : "Unknown Error, Contact Support"} />
              )}
              <Button
                variant="green"
                onClick={() => buy()}
                disabled={!terms.auth || !terms.terms || purchasing}
                loading={purchasing}
              >
                Confirm Purchase
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
            <Content>
              <div style={{ textAlign: "left", fontSize: "11px", color: "gray" }}>
                <strong>Terms and conditions</strong>
                <br />
                <br />
                <i>
                  Full payment due upon subscription renewal. Test credits will be used for each new
                  in-clinic test order upon submission. Test credits purchased under this
                  subscription will not expire and will automatically be used when submitting test
                  orders until your test credit balance reaches zero. Once your test credit balance
                  reaches zero, this subscription will automatically renew, and your account will be
                  charged via the payment method on file. If a sample is rejected, the test credit
                  used will be returned to your credit pool automatically. Purchase of this pre-buy
                  subscription is non-refundable and non-exchangeable. Test credits only apply to
                  in-clinic tests and will not apply to at-home tests. You have the option to cancel
                  this subscription at any time without penalty. Any unused credits will remain in
                  your credit pool. We reserve the right to update these terms and conditions at any
                  time.
                </i>
              </div>
            </Content>
          </>
        )}
        {screen === "payment_info" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Payment Info Reminder</h3>
            </Header>
            <Content>
              <div style={{ color: "#114377" }}>
                Your payment information is either missing or needs to be updated.
              </div>{" "}
              <div style={{ color: "#114377" }}>
                Please update your payment information to proceed with this order.
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={() => pageNav("/settings/billing/edit")}>
                Update Payment Info
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

Prebuy.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 665px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 30px;
  }
`;

const TextHighlight = styled.span`
  font-size: 18px;
  color: green;
  text-decoration: underline;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

const Subscribe = styled(Button)`
  background-color: darkgreen;
  line-height: 1;
  color: white;
  height: 34px;
  width: 95%;
  justify-content: center;
  margin-top: 15px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  :hover {
    background-color: darkgreen;
  }
`;

const PrebuyPlans = styled.div`
  display: flex;
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100px;
  text-align: center;
  font-family: Poppins, serif;
  box-sizing: border-box;
  margin-top: 30px;

  // If active prop is passed, then apply the following styles
  ${({ active }) =>
    active &&
    css`
      border-left: 1px solid red;
      border-right: 1px solid red !important;
      position: relative;
      :before {
        content: "Current";
        position: absolute;
        box-sizing: border-box;
        top: -25px;
        height: 25px;
        border-top: 1px solid red;
        border-right: 1px solid red;
        border-left: 1px solid red;
        width: 100px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      :after {
        content: "";
        position: absolute;
        box-sizing: border-box;
        bottom: -10px;
        height: 10px;
        width: 100px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 1px solid red;
        border-left: 1px solid red;
        border-bottom: 1px solid red;
      }
    `}

  & > :nth-child(1) {
    background-color: #f8f9fb;
    color: #114377;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & > :nth-child(2) {
    color: #4f4f4f;
  }

  & > :nth-child(3) {
    font-size: 10px;
    color: darkgreen;
  }

  border-right: 1px solid rgba(79, 79, 79, 0.2);

  :last-of-type {
    border-right: none;
  }
`;

export default Prebuy;
