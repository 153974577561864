import * as ACTION_TYPES from "./main_types";

export const LOGIN_SUCCESS = (role, clinic, sso) => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
    role,
    clinic,
    sso,
  };
};

export const LOGIN_FAILURE = (error) => {
  return {
    type: ACTION_TYPES.LOGIN_FAILURE,
    payload: error,
  };
};

export const LOGOUT = () => {
  return {
    type: ACTION_TYPES.LOGOUT,
  };
};

export const TOGGLE_SIDEBAR = (on) => {
  return {
    type: ACTION_TYPES.TOGGLE_SIDEBAR,
    payload: on,
  };
};

export const SET_ROLE = (role) => {
  return {
    type: ACTION_TYPES.SET_ROLE,
    payload: role,
  };
};
