import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as CardImg } from "../../../shared/assets/icons/Other/Credit Card.svg";
import styled from "styled-components";
import PropTypes from "prop-types";

const AtHomeConfirmation = ({ onConfirm, onLater }) => {
  return (
    <Modal open onClose={onLater}>
      <Wrapper>
        <Header>
          <h3>At Home Order</h3>
        </Header>
        <Content>
          <p>
            You have selected an <strong>At Home</strong> test kit. Please note that{" "}
            <strong>the cost of this test is higher</strong> than an in-clinic test;{" "}
            <u>test credits</u> on your account <u>will not be applied</u> to this At Home test for
            this reason.
          </p>
          <p>
            <strong>
              By clicking Confirm, you acknowledge:
              <ul>
                <li>You want to order an At Home test</li>
                <li>
                  This test will be mailed directly to your patient's <u>residential address</u>{" "}
                  **We do not ship to PO Boxes**
                </li>
                <li>Your clinic will be billed at the higher At Home test rate</li>
              </ul>
            </strong>
          </p>
          <p>
            <strong>Terms & Conditions</strong>
            <br />
            <span style={{ fontSize: "10px" }}>
              Test credits will not be applied to this At Home test. The clinic will be charged a
              non-refundable $50 once the status of your order changes to "shipped". The status of
              this order changes to "shipped" when Direct Diagnostics has successfully shipped a
              test kit to your patient at the physical address provided in the order you are about
              to place. You may cancel the order at any time before the status changes to "shipped"
              if you do not wish to incur the charges. The remaining cost of the At Home test will
              be charged when the status of the sample changes to "resulted". The status changes to
              "resulted" after your patient's sample has arrived at our lab and is successfully
              tested. We reserve the right to make changes to these terms and conditions at any
              time.
            </span>
          </p>
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onLater}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 420px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default AtHomeConfirmation;
