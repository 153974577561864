import styled, { css } from "styled-components";
import TextInput from "../../../shared/components/TextInput";
import Button from "../../../shared/components/Button";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PasswordInput from "../../../shared/components/PasswordInput";
import { ReactComponent as InfoImg } from "../../../shared/assets/icons/Circle/Info filled.svg";
import { ReactComponent as ClinicImg } from "../../../shared/assets/icons/Other/Clinic.svg";
import { ReactComponent as CircleImg } from "../../../shared/assets/icons/Action/Tick Small Circle.svg";
import API from "../../../utils/api";
import { MainStore } from "../../../stores/main";
import ErrorText from "../../../shared/components/ErrorText";

const Login = () => {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState("");
  const [clinic, setClinic] = useState(searchParams.get("clinicId"));
  const [clinics, setClinics] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [tempPw, setTempPw] = useState("");
  const [tempLogin, setTempLogin] = useState({});
  const [userId, setUserId] = useState("");
  const [hmac, setHmac] = useState("");
  const mainStoreContext = useContext(MainStore);

  const navigate = useNavigate();

  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm();

  const newPw = useRef({});
  newPw.current = watch2("new_pw", "");

  const loginRedirect = useCallback(
    (r, c, s, u = userId, h = hmac) => {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: window.config.intercomAppId,
        user_id: u,
        user_hash: h,
      });
      mainStoreContext.handleLogin(r, c, s);
      navigate("/dashboard");
    },
    [userId, hmac]
  );

  // Process change password
  const changePassword = useCallback(
    async (data) => {
      setLoading(true);
      try {
        await API.put("/profile/password", {
          password: tempPw,
          new_password: data.new_pw,
        });
        loginRedirect(tempLogin.role, tempLogin.clinic_id, tempLogin.sso === true);
      } catch (e) {
        if (e.response.data.error) {
          setError(e.response.data.error);
        } else {
          setError("Unknown error. Please contact support.");
        }
      }
      setLoading(false);
    },
    [clinic, tempPw, tempLogin, loginRedirect]
  );

  // Login Submit
  const loginSubmit = async (data) => {
    // Have they selected a clinic?
    if (state === "select" && clinic === "") return;

    setError("");
    setLoading(true);

    try {
      const req = {
        type: "clinic",
        username: data.email,
        password: data.password,
        clinic,
      };

      const response = await API.post("/login", req);
      const tempClinics = response.data.clinics;
      setClinics(tempClinics);

      if (state === "") {
        setTempPw(data.password);
      }

      if (tempClinics?.length > 1) {
        setState("select");
      } else {
        if (response.data.temporary_pw) {
          setUserId(response.data.user_id);
          setHmac(response.data.hmac);
          setTempLogin(response.data);
          setState("temp");
        } else {
          loginRedirect(
            response.data.role,
            response.data.clinic_id,
            response.data.sso === true,
            response.data.user_id,
            response.data.hmac
          );
        }
      }
    } catch (e) {
      if (e.response?.data.error) {
        setError(e.response.data.error);
      } else {
        setError("Unable to login. Please try again.");
      }
    }
    setLoading(false);
  };

  const resetLogin = () => {
    setState("login");
    setClinic("");
    setClinics([]);
  };

  useEffect(() => {
    const loginSSO = searchParams.get("sso");
    const orderRedirect = searchParams.get("orderId");
    if (loginSSO == "true") {
      loginSubmit({});
    }
    localStorage.setItem("orderRedirect", orderRedirect);
  }, []);

  if (state === "temp") {
    return (
      <>
        <Title>Password Update</Title>
        <Notice>Please update your temporary password.</Notice>
        <FormWrapper onSubmit={handleSubmit2(changePassword)}>
          <PasswordInput
            id={"new_pw"}
            title={"Enter New Password"}
            type="password"
            disabled={loading}
            error={errors2.new_pw}
            {...register2("new_pw", { required: true })}
          />
          <PasswordInput
            id={"confirm_pw"}
            title={"Confirm New Password"}
            type="password"
            disabled={loading}
            error={errors2.confirm_pw}
            {...register2("confirm_pw", {
              required: true,
              validate: (value) => value === newPw.current || "The passwords do not match",
            })}
          />
          <PwNotice>
            <InfoImg />
            Password must be at least 8 characters long and must contain 1 special character, 1
            uppercase character, and 1 number
          </PwNotice>
          <Button disabled={loading} loading={loading}>
            Confirm
          </Button>
          {error && <Error>{error}</Error>}
        </FormWrapper>
      </>
    );
  } else if (state === "select") {
    return (
      <form onSubmit={handleSubmit(loginSubmit)}>
        <SubTitle>Clinic Log In</SubTitle>
        <Title>Select Clinic</Title>
        <ClinicList>
          {clinics?.map((cln) => (
            <Clinic onClick={() => setClinic(cln.id)} active={clinic === cln.id} key={cln.id}>
              <ClinicIcon />
              <span>{cln.name}</span>
              <CheckIcon />
            </Clinic>
          ))}
        </ClinicList>
        <FullButton type="submit" disabled={clinic === "" || loading} loading={loading}>
          Select Clinic
        </FullButton>
        <FullButton variant="secondary" onClick={resetLogin} type="button">
          Cancel
        </FullButton>
      </form>
    );
  } else {
    return (
      <>
        <Title>Clinic Log In</Title>
        <FormWrapper onSubmit={handleSubmit(loginSubmit)}>
          <TextInput
            id={"email"}
            title={"Email Address"}
            {...register("email", { required: true })}
            disabled={loading}
          />
          <TextInput
            id={"password"}
            title={"Password"}
            type="password"
            disabled={loading}
            {...register("password", { required: true })}
          />
          <FormFooter>
            <Link to={"/forgot"}>Forgot Password?</Link>
            <Button disabled={loading} loading={loading}>
              Log In
            </Button>
          </FormFooter>
          {error && <ErrorText error={error} />}
        </FormWrapper>
      </>
    );
  }
};

const ClinicList = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
const ClinicIcon = styled(ClinicImg)`
  & path {
    stroke: ${(props) => props.theme.colors.blue.light.primary};
  }
  & rect {
    fill: ${(props) => props.theme.colors.blue.light.primary};
  }
`;
const CheckIcon = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
`;
const ClinicHoverStyle = css`
  color: white;
  background-color: #114377;
  ${ClinicIcon} {
    & path {
      stroke: white;
    }
    & rect {
      fill: white;
    }
  }
  ${CheckIcon} {
    & path.svgcircle {
      fill: white;
    }
    & path.svgcheck {
      fill: #114377;
      stroke: #114377;
    }
  }
`;
const Clinic = styled.div`
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0b0b0b;
  gap: 10px;
  cursor: pointer;
  span {
    flex: 1;
  }
  &:hover {
    ${ClinicHoverStyle}
  }
  ${(props) => props.active && ClinicHoverStyle}
`;

const FullButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const FormWrapper = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & :first-child {
    flex: 1;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Notice = styled.p`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: ${(props) => props.theme.colors.black.offBlack};
`;

const PwNotice = styled.p`
  display: flex;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  color: ${(props) => props.theme.colors.grey.grey2};
  gap: 10px;
  svg {
    fill: ${(props) => props.theme.colors.grey.grey3};
    height: 20px;
    width: 20px;
    flex: none;
  }
`;

const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const Title = styled.h2``;

export default Login;
