import { useTitle } from "../../contexts/TitleContext";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { ReactComponent as NavImg } from "../../shared/assets/icons/Other/Menu alt 2.svg";
import { ReactComponent as ClinicImg } from "../../shared/assets/icons/Other/Clinic.svg";
import { ReactComponent as LeftArrow } from "../../shared/assets/icons/Arrows/Left.svg";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import SwitchClinic from "../Modals/SwitchClinic";
import { MainStore } from "../../stores/main";
import API from "../../utils/api";
import Sidebar from "../Sidebar";

const Title = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  flex: 1;
  display: flex;
  align-items: center;
  && a {
    margin-right: 10px;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    button {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  padding: 15px 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.mobileL} {
    padding: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  height: 50px;
`;
const Details = styled.div`
  display: none;
  gap: 10px;
  align-items: center;
  @media only screen and ${(props) => props.theme.breakpoints.devices.tabletS} {
    display: flex;
  }
`;
const UserWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
`;
const Avatar = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;
const Name = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const VHR = styled.hr`
  height: 20px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;
const Clinic = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blue.light.primary};
  cursor: pointer;
`;

const Children = styled.div`
  display: flex;
  button > span {
    display: none;
  }
  button > svg:first-child {
    margin-right: 0;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    button {
      gap: 10px;
    }
    button > span {
      display: flex;
    }
  }
`;

const HeaderLayout = () => {
  const { title } = useTitle();
  const [toggleClinic, setToggleClinic] = useState(false);
  const [profile, setProfile] = useState({});
  const [clinicProfile, setClinicProfile] = useState({});
  const mainStoreContext = useContext(MainStore);
  const navigate = useNavigate();

  const getCurrentProfile = async () => {
    try {
      const response = await API.get("/profile");
      response.data.name = response.data.first + " " + response.data.last;
      setProfile(response.data);
      window.Intercom("update", {
        name: response.data.name,
        email: response.data.email,
        user_type: mainStoreContext.role,
        phone: response.data.phone,
      });
    } catch (e) {}
  };

  const getCurrentClinicProfile = async () => {
    try {
      const response = await API.get("/profile/clinic");
      window.Intercom("update", {
        clinic_name: response.data.name,
        billing_schedule: response.data.billing_schedule,
        account_status: response.data.account_status,
        practice_type: response.data.practice_type,
        account_manager: response.data.account_manager,
        prebuy_plan_type: response.data.prebuy_plan_type,
        prebuy_status: response.data.prebuy_plan_type ? "subscribed" : "not subscribed",
        last_prebuy_at: response.data.last_prebuy
          ? new Date(response.data.last_prebuy).getTime() / 1000
          : null,
        prebuy_canceled_at: response.data.last_prebuy_canceled
          ? new Date(response.data.last_prebuy_canceled).getTime() / 1000
          : null,
      });
      setClinicProfile(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    getCurrentProfile();
    getCurrentClinicProfile();
  }, []);

  return (
    <Wrapper>
      <Header>
        <Title>
          <button onClick={() => mainStoreContext.toggleSidebar(true)}>
            <NavImg />
          </button>
          {title.back && (
            <Link to={title.back}>
              <LeftArrow />
            </Link>
          )}
          {title.previous && (
            <LeftArrow onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
          )}
          {title.name || title}
        </Title>
        <Children>{title.children}</Children>
        <UserWrap onClick={() => setToggleClinic(true)}>
          <Avatar>
            <ClinicImg />
          </Avatar>
          <Details>
            <Clinic>{clinicProfile.name}</Clinic>
            <VHR />
            <Name>{profile.name}</Name>
          </Details>
        </UserWrap>
      </Header>
      <Outlet />
      {toggleClinic && (
        <SwitchClinic
          onClose={() => setToggleClinic(false)}
          currentClinic={mainStoreContext.clinic}
        />
      )}
      {mainStoreContext.sidebar && <Sidebar />}
    </Wrapper>
  );
};

export default HeaderLayout;
