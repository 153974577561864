import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import Checkbox from "../../../shared/components/Checkbox";
import Api from "../../../utils/api";
import API from "../../../utils/api";
import { useNavigate } from "react-router";
import ErrorText from "../../../shared/components/ErrorText";
import { set, useForm } from "react-hook-form";
import OrderSupplies from "../OrderSupplies";
import TextInput from "../../../shared/components/TextInput";
import States from "../../../utils/states";
import {
  InputWrapper,
  Wrapper as TextInputWrapper,
} from "../../../shared/components/TextInput/TextInput";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Select from "../../../shared/components/Select";
import { MainStore } from "../../../stores/main";

const stripeOptions = {
  style: {
    base: {
      color: "#32325d",

      fontFamily: '"Poppins", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontWeight: 600,
      fontSize: "12px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const DeactivateOffer = ({
  initialScreen = "offer",
  offerId,
  onLater,
  onComplete,
  hasBilling = false,
  maxPurchase = 0,
}) => {
  const [screen, setScreen] = useState(initialScreen);
  const [terms, setTerms] = useState({ auth: false, terms: false });
  const [badBilling, setBadBilling] = useState(!hasBilling);
  const [purchasing, setPurchasing] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const mainStore = useContext(MainStore);

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors },
  } = useForm();

  // Get billing details
  const getBillingDetails = async () => {
    const clinicResponse = await Api.get("/profile/clinic");
    if (!clinicResponse.data.verified_payment) {
      setBadBilling(true);
    } else {
      const response = await API.get("/billing/details");
      if (typeof response.data == "object") {
        // Check if the expiry is this month
        const today = new Date();
        if (response.data.expiry === `${today.getMonth() + 1}/${today.getFullYear()}`) {
          setBadBilling(true);
        }
      }
    }
  };

  const onCancel = async () => {
    try {
      const response = await API.put("/profile/clinic/disable");
      mainStore.handleLogout();
    } catch (e) {}
  };

  // Credit card submission
  const cardSubmit = async (data) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Process the stripe request
      const cardElement = elements.getElement("card");
      const { token, error } = await stripe.createToken(cardElement, {
        name: `${data.firstName} ${data.lastName}`,
        address_line1: data.address,
        address_city: data.city,
        address_state: data.state,
        address_zip: data.zip,
        address_country: "US",
      });

      if (error) {
        setError(error.message);
      }

      if (token) {
        const response = await API.put("/billing/details", {
          token: token.id,
          type: token.type,
        });
        setBadBilling(false);
      }
    } catch (e) {}

    setLoading(false);
  };

  const pageNav = (path) => {
    navigate(path);
    onLater();
  };

  // Get billing details on mount
  useEffect(() => {
    getBillingDetails();
  }, []);

  const dismiss = () => {
    onLater();
  };

  const buyOffer = useCallback(async () => {
    if (!purchasing) {
      setPurchasing(true);
    }
    try {
      const response = await API.post(`/offers/${offerId}/claim`, {});
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setScreen("success");
      }
    } catch (e) {
      if (e.response.data.error) {
        setError(e.response.data.error);
      } else {
        setError("error");
      }
    }
    setPurchasing(false);
  }, [purchasing]);

  return (
    <Modal open onClose={onLater}>
      {orderModal && <OrderSupplies onClose={() => setOrderModal(false)} />}
      <Wrapper>
        {screen === "offer" && (
          <>
            <Header>
              <h3>🚨 Order Now or Lose Account Access! 🚨</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                We appreciate your interest in Direct Diagnostics and thank you for signing up.
                However, we noticed that your clinic hasn't yet taken advantage of our services, and
                we want to extend a last-chance offer to you.
                <br />
                <br />
                To help jumpstart testing in your office, we would love to offer you an exclusive
                30% discount on a pre-order of 5 tests (a $102 savings!).
              </div>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "#114377" }}>
                Please be aware that if no test orders are placed by December 31, 2023, your
                Clinic's account will be closed and access to all clinical resources will be revoked
              </div>
            </Content>
            <Buttons>
              <Button onClick={() => setScreen("checkout")}>
                Add Payment Information and Order Now
              </Button>
              <Button variant="secondary" onClick={() => setScreen("last_chance")}>
                No thanks, please close my account
              </Button>
            </Buttons>
          </>
        )}
        {screen === "last_chance" && (
          <>
            <Header>
              <h3>
                Are you sure?
                <br />
                Your account will be closed and access to all clinical resources will be revoked
              </h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                By selecting this option, you are confirming that your office is no longer
                interested in salivary testing with Direct Diagnostics. Your access to Direct
                Diagnostics and all clinical resources will be revoked.
              </div>
            </Content>
            <Buttons>
              <Button variant="secondary" onClick={onCancel}>
                Yes, please close my account and revoke all access
              </Button>
              <Button onClick={() => setScreen("checkout")}>Wait! Please keep my account</Button>
            </Buttons>
          </>
        )}
        {screen === "success" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Thank you for your purchase!</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "#114377" }}>
                5 test credits have been added to your account
              </div>
              <div style={{ fontSize: "12px", color: "#114377" }}>
                If you have questions, please email support@directdiagnostics.com.
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={onComplete}>
                Okay
              </Button>
            </Buttons>
          </>
        )}
        {screen === "checkout" && (
          <>
            <Header>
              <h3>Special Offer Purchase: 30% off 5 test credits!</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                You have opted to purchase 5 test credits at 30% off. By accepting this special
                offer, you must agree to the terms and conditions outlined in the offer details.
                Once you complete the purchase, 5 test credits will automatically be added to your
                account.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#114377",
                  textDecoration: "underline",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                TOTAL DUE: <span style={{ color: "green" }}>$238</span>
              </div>
              {badBilling && (
                <form onSubmit={handleSubmit2(cardSubmit)}>
                  <PaymentDetailWrapper>
                    <div>
                      <Content>
                        <Row>
                          <TextInput
                            id={"firstName"}
                            title={"First Name"}
                            placeholder={"enter first name"}
                            {...register2("firstName", { required: true })}
                          />
                          <TextInput
                            id={"lastName"}
                            title={"Last Name"}
                            placeholder="enter last name"
                            {...register2("lastName", { required: true })}
                          />
                        </Row>
                        <Row>
                          <TextInput
                            id={"address"}
                            title={"address"}
                            placeholder={"address"}
                            {...register2("address", { required: true })}
                          />
                        </Row>
                        <Row>
                          <TextInput
                            id={"city"}
                            placeholder={"city"}
                            {...register2("city", { required: true })}
                          />
                        </Row>
                        <Row>
                          <StyledSelect
                            style={{ flex: 1 }}
                            required
                            disable={loading}
                            error={errors.state !== undefined || error}
                            disabled={loading}
                            {...register2("state", { required: true })}
                          >
                            <option value="">- State -</option>
                            {States.map((state) => (
                              <option value={state.abbreviation}>{state.name}</option>
                            ))}
                          </StyledSelect>
                        </Row>
                        <Row>
                          <TextInput
                            id={"zip"}
                            placeholder={"zip"}
                            {...register2("zip", { required: true })}
                          />
                        </Row>
                      </Content>
                    </div>
                    <div>
                      <Content>
                        <Row>
                          <TextInputWrapper>
                            <label>Credit Card Details</label>
                            <InputWrapper>
                              <StripeWrapper>
                                <CardElement options={stripeOptions} />
                              </StripeWrapper>
                            </InputWrapper>
                          </TextInputWrapper>
                        </Row>
                      </Content>
                    </div>
                  </PaymentDetailWrapper>
                  <Footer>
                    <Button
                      disabled={loading}
                      variant="secondary"
                      onClick={() => setPaymentMethod("")}
                    >
                      Cancel
                    </Button>
                    <Button disabled={loading} loading={loading}>
                      Save Changes
                    </Button>
                  </Footer>
                  {error && <Error>{error}</Error>}
                </form>
              )}
              <div
                style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Checkbox
                  label
                  checked={terms.auth}
                  onClick={() => setTerms({ ...terms, auth: !terms.auth })}
                >
                  I certify that I am authorized to make this purchase on behalf of my clinic or
                  practice.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.terms}
                  onClick={() => setTerms({ ...terms, terms: !terms.terms })}
                >
                  I have read and understand the terms and conditions. By selecting{" "}
                  <i>Confirm Purchase</i>, I agree that Direct Diagnostics may collect the amount
                  due upon purchase.
                </Checkbox>
              </div>
            </Content>
            <Buttons>
              {error && (
                <ErrorText error={error !== "error" ? error : "Unknown Error, Contact Support"} />
              )}
              <Button
                variant="green"
                onClick={() => buyOffer()}
                disabled={!terms.auth || !terms.terms || purchasing || badBilling}
                loading={purchasing}
              >
                Confirm Purchase
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
            <Content>
              <div style={{ textAlign: "left", fontSize: "11px", color: "gray" }}>
                <strong>Terms and conditions</strong>
                <br />
                <br />
                <i>
                  This offer cannot be combined with any other sales or special offers. Full payment
                  is due upon purchase. Purchase of this offer is non-refundable. This offer only
                  applies to in-clinic tests and at-home tests are excluded from this offer. Test
                  credits are purchased at standard in-clinic test pricing of $68 per result with
                  30% discount applied to total purchase. There is a limit of 1 special offer
                  purchases for the duration of this offer. Test credits purchased under this offer
                  will not expire but must be used within 3 months to avoid account closure.
                  <br />
                  <br />
                  If if no test orders are placed (either via this special offer or traditional
                  purchasing methods) by December 31, 2023, your account will be closed and access
                  to all clinical resources will be revoked.
                </i>
              </div>
            </Content>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

DeactivateOffer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 30px;
  }
`;

const TextHighlight = styled.span`
  font-size: 18px;
  color: green;
  text-decoration: underline;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

const StripeWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  > button {
    width: 100%;
    max-width: 200px;
    justify-content: center;
  }
`;

const PaymentDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  h4 {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 30px;
  > div {
    flex: 1;
  }
`;

const Error = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

const StyledSelect = styled(Select)`
  background-color: #ffffff;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
`;

export default DeactivateOffer;
