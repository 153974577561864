import TextInput from "../TextInput";
import { forwardRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as EyeImg } from "../../assets/icons/Action/Eye On.svg";
import PropTypes from "prop-types";

const PasswordInput = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((c) => !c);
  };
  return (
    <TextInput
      {...props}
      ref={ref}
      type={show ? "text" : "password"}
      icon={show ? <EyeOn /> : <EyeOff />}
      iconClick={toggleShow}
    />
  );
});

const EyeOn = styled(EyeImg)`
  stroke: ${(props) => props.theme.colors.blue.light.primary};
`;
const EyeOff = styled(EyeImg)`
  stroke: ${(props) => props.theme.colors.grey.grey3};
`;

export default PasswordInput;
