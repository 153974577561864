import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTitle } from "../../../contexts/TitleContext";
import { ReactComponent as UserImg } from "../../../shared/assets/icons/People/User.svg";
import { ReactComponent as MapPinImg } from "../../../shared/assets/icons/Other/Map Pin.svg";
import { ReactComponent as GlobeImg } from "../../../shared/assets/icons/Other/Globe.svg";
import { ReactComponent as MailImg } from "../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as PhoneImg } from "../../../shared/assets/icons/Phone/Defaut.svg";
import { ReactComponent as LockImg } from "../../../shared/assets/icons/Action/Locked.svg";
import { ReactComponent as BirthdayImg } from "../../../shared/assets/icons/birthday.svg";
import { ReactComponent as RightArrowImg } from "../../../shared/assets/icons/Chevron/Right.svg";
import { ReactComponent as ClinicImg } from "../../../shared/assets/icons/Other/Clinic.svg";
import { ReactComponent as NotificationImg } from "../../../shared/assets/icons/Action/Notification On.svg";
import { ReactComponent as ClipboardImg } from "../../../shared/assets/icons/Other/Clipboard.svg";
import { ReactComponent as FileFolderImg } from "../../../shared/assets/icons/File And Folder/File Text.svg";
import { ReactComponent as TestImg } from "../../../shared/assets/icons/File And Folder/File Plus.svg";
import { ReactComponent as SendImg } from "../../../shared/assets/icons/Other/Send.svg";
import { ReactComponent as DeleteImg } from "../../../shared/assets/icons/Other/Delete 2.svg";
import { ReactComponent as DNAImg } from "../../../shared/assets/icons/Other/DNA.svg";
import { ReactComponent as SentImg } from "../../../shared/assets/icons/Other/Send.svg";
import { ReactComponent as ShareImg } from "../../../shared/assets/icons/Other/Share.svg";
import { ReactComponent as ResultsImg } from "../../../shared/assets/icons/File And Folder/Summary.svg";
import { ReactComponent as QRImg } from "../../../shared/assets/icons/qr.svg";
import { ReactComponent as PayBillsImg } from "../../../shared/assets/icons/pay bills.svg";
import { ReactComponent as WalletImg } from "../../../shared/assets/icons/wallet.svg";
import { ReactComponent as PersonImg } from "../../../shared/assets/icons/People/User Plus.svg";
import { ReactComponent as PrebuyImg } from "../../../shared/assets/icons/Shop/Prebuy.svg";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Circle/exp.svg";
import { ReactComponent as HomeImg } from "../../../shared/assets/icons/Other/Home alt 2.svg";
import styled, { css } from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../shared/components/Button";
import API from "../../../utils/api";
import DefaultTestType from "../../../components/Modals/Settings/DefaultTestType/DefaultTestType";
import SendTestResults from "../../../components/Modals/Settings/SendTestResults/SendTestResults";
import SystemAlert from "../../../components/Modals/Settings/SystemAlert/SystemAlert";
import BulletinBoard from "../../../components/Modals/Settings/BulletinBoard/BulletinBoard";
import NotificationType from "../../../components/Modals/Settings/NotificationType/NotificationType";
import EditInfo from "../../../components/Modals/Settings/EditInfo/EditInfo";
import { MainStore } from "../../../stores/main";
import FormatPhoneNumber from "../../../shared/utilities/FormatPhone";
import Modal from "../../../shared/components/Modal";
import QRCode from "react-qr-code";
import Logo from "../../../shared/components/Logo";
import GetInitials from "../../../shared/utilities/GetInitials";
import EditPatient from "../../../components/Modals/EditPatient";
import { FormatBirthdate } from "../../../shared/utilities/FormatBirthdate";
import Confirm from "../../../components/Modals/Confirm";
import ReactTooltip from "react-tooltip";
import { ActionButton, ActionItem } from "../../../shared/components/ActionButton";
import { rgba } from "polished";
import { ReactComponent as PackageImg } from "../../../shared/assets/icons/Shop/Package.svg";
import { ReactComponent as EditImg } from "../../../shared/assets/icons/Action/Edit.svg";
import Select from "../../../shared/components/Select";
import Api from "../../../utils/api";

const Patient = () => {
  const { setTitle } = useTitle();
  const [modal, setModal] = useState("");
  const [profile, setProfile] = useState({});
  const [settings, setSettings] = useState({});
  const [sort, setSort] = useState({});
  const selectType = useRef();
  const [loaded, setLoaded] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [shipping, setShipping] = useState();
  const [rejected, setRejected] = useState("");
  const [canceled, setCanceled] = useState(null);
  const [toCancel, setToCancel] = useState("");
  const [showQR, setShowQR] = useState(false);
  const { role } = useContext(MainStore);
  const [editPatient, setEditPatient] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [editStatus, setEditStatus] = useState("");
  const { sso } = useContext(MainStore);
  const [orders, setOrders] = useState({ data: [], page: 1 });
  const [lim, setLim] = useState(100);
  const [page, setPage] = useState(1);
  const fromDate = useRef();
  const [selected, setSelected] = useState("");
  const toDate = useRef();
  const search = useRef();
  const defaultSort = { type: "collected", dir: "desc" };
  const [sendModal, setSendModal] = useState(null);

  // Toggle the modal to open/closed
  const toggleModalOff = () => setModal("");
  const togglePwModal = () => setModal("password");
  const toggleDefaultTestModal = () => setModal("default_test");
  const toggleSendResultMethodModal = () => setModal("result_method");
  const toggleNotificationMethodModal = () => setModal("notification_method");
  const toggleBulletinMethodModal = () => setModal("bulletin_method");
  const toggleNotificationType = () => setModal("notification_type");
  const toggleEditInfo = () => setModal("edit_info");

  const getCurrentProfile = async () => {
    try {
      const response = await API.get("/profile/clinic");
      setProfile(response.data);
    } catch (e) {}
  };

  const updateProfile = (newProfile) => {
    setProfile(newProfile);
    toggleModalOff();
  };

  const handleOrderClick = async (ord) => {
    if (ord.order_status === "rejected") {
      let reason = ord.reject_reason;
      if (ord.reject_note) {
        reason = reason + " NOTE: " + ord.reject_note;
      }
      setRejected(reason);
    } else if (ord.order_status === "resulted") {
      navigate(`/results/view/${ord.id}`);
    } else if (ord.order_status === "canceled") {
      try {
        const details = await API.get(`/orders/${ord.id}/cancel`);
        setCanceled(details.data);
      } catch (e) {}
    }
  };

  const selectRow = (e) => {
    setSelected(e.target.getAttribute("data-id"));
  };

  const getOrderList = useCallback(
    async (pg, lmt) => {
      if (!pg) pg = 1;
      if (!lmt) lmt = lim;
      try {
        const request = {
          patient_id: id,
          page: pg,
          limit: lmt,
          result_data: true,
        };
        const response = await Api.post(`/orders/search`, request);
        setOrders(response.data);
        setPage(response.data.page);
      } catch (e) {
        console.log(e);
      }
    },
    [id, sort, lim]
  );

  const setNextPage = useCallback(
    (p) => {
      setPage(p);
      getOrderList(p, lim);
    },
    [getOrderList, lim]
  );

  const changeLimit = useCallback(
    (l) => {
      setPage(1);
      setLim(l);
      getOrderList(1, l);
    },
    [getOrderList, lim]
  );

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort = { ...defaultSort };
        }
      }
      setSort(newSort);
    },
    [sort]
  );

  const getPages = useCallback(() => {
    return (
      <Paging>
        <div>Results: {orders.results}</div>
        <Pagination>
          {orders.page <= orders.pages && orders.page !== 1 && (
            <Page onClick={() => setNextPage(page - 1)}>&laquo;</Page>
          )}
          <span>
            Page {orders.page} of {orders.pages}
          </span>
          {orders.page < orders.pages && <Page onClick={() => setNextPage(page + 1)}>&raquo;</Page>}
        </Pagination>
      </Paging>
    );
  }, [orders, page, setNextPage]);

  const getCurrentSettings = async () => {
    try {
      const response = await API.get("/profile/settings");
      setSettings(response.data.settings);
    } catch (e) {}
  };

  // Set page title
  useEffect(() => {
    setTitle({
      name: "View Patient",
      previous: true,
    });
  }, [setTitle, id]);

  const getPatient = async (id) => {
    try {
      const response = await API.post(`/patients/search`, { id });
      if (response.data.data && response.data.data[0]) {
        setProfile(response.data.data[0]);
      }
    } catch (e) {}
  };

  const deletePatient = async (id) => {
    try {
      await API.delete(`/patients/${id}`);
    } catch (e) {}
  };

  const startPatientOrder = (p) => {
    navigate(`/orders/new/`, { state: { patient: p } });
  };

  // Load page
  // TODO: Handle single failure
  useEffect(async () => {
    try {
      await getPatient(id);
      const lockResponse = await API.get(`/patients/${id}/editStatus`);
      await getOrderList();
      setEditStatus(lockResponse.data.status);
      setLoaded(true);
    } catch (e) {}
  }, [id]);

  if (loaded) {
    return (
      <>
        <PatientInfo>
          <Title>Patient Profile</Title>
          <Container>
            <Column>
              <FlexSection>
                <UserWrap>
                  <Avatar>{GetInitials(`${profile.first} ${profile.last}`)}</Avatar>
                  <Name>{`${profile.first} ${profile.last}`}</Name>
                </UserWrap>
                <Button variant="secondary" onClick={toggleEditInfo}>
                  Edit Info
                </Button>
              </FlexSection>
              <Section>
                <Demographics>
                  <tbody>
                    <DemoRow>
                      <DemoCol>
                        <UserImg />
                      </DemoCol>
                      <DemoCol>Patient Name:</DemoCol>
                      <DemoCol>
                        {profile.first} {profile.last}
                      </DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol>
                        <MapPinImg />
                      </DemoCol>
                      <DemoCol>Address:</DemoCol>
                      <DemoCol>
                        <div>{profile.address}</div>
                        <div>{`${profile.city} ${profile.state} ${profile.zip}`}</div>
                      </DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol>
                        <MailImg />
                      </DemoCol>
                      <DemoCol>Email Address:</DemoCol>
                      <DemoCol>{profile.email}</DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol>
                        <PhoneImg />
                      </DemoCol>
                      <DemoCol>Phone:</DemoCol>
                      <DemoCol>{FormatPhoneNumber(profile.phone)}</DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol>
                        <BirthdayImg />
                      </DemoCol>
                      <DemoCol>Date of Birth:</DemoCol>
                      <DemoCol>{FormatBirthdate(profile.dob)}</DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol>
                        <DNAImg />
                      </DemoCol>
                      <DemoCol>Birth Sex:</DemoCol>
                      <DemoCol>{profile.gender}</DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol></DemoCol>
                      <DemoCol>External ID:</DemoCol>
                      <DemoCol>{profile.ext_patient_id}</DemoCol>
                    </DemoRow>
                    <DemoRow>
                      <DemoCol></DemoCol>
                      <DemoCol>Profile Type:</DemoCol>
                      <DemoCol>
                        {profile.profile_type === "patient"
                          ? "Patient"
                          : profile.profile_type === "practice_team_member"
                          ? "Practice Team Member"
                          : ""}
                      </DemoCol>
                    </DemoRow>
                  </tbody>
                </Demographics>
              </Section>
            </Column>
            <Column>
              {profile.last_order && (
                <div style={{ marginBottom: "10px" }}>
                  Last Order on {FormatBirthdate(profile.last_order.created)} (
                  {profile.last_order.order_status})
                </div>
              )}
              <ArrowButton onClick={() => startPatientOrder(profile)}>
                <TestImg />
                <span>Start Order</span>
                <RightArrowImg />
              </ArrowButton>
              {editStatus === "delete" && (
                <DeleteArrowButton onClick={() => setModal("delete")}>
                  <DeleteImg />
                  <span>Delete Patient</span>
                </DeleteArrowButton>
              )}
            </Column>
          </Container>
        </PatientInfo>
        <OrderList>
          <StyledTable>
            <thead>
              <tr>
                <th />
                <ColHead>Collection Date</ColHead>
                <ColHead>Test</ColHead>
                <ColHead>Status</ColHead>
                <ColHead>Barcode</ColHead>
                <ColHead>Resulted</ColHead>
                <ColHead>Results Sent</ColHead>
                <th />
              </tr>
            </thead>
            <tbody>
              {orders.data.map((order) => (
                <tr key={order.id}>
                  <td>
                    {order.test_location === "at_home" ? (
                      <div data-tip data-for="atHomeTip">
                        <HomeImg height={15} />
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{FormatBirthdate(order.collected)}</td>
                  <td>{order.test.name}</td>
                  <td>
                    <StatusPill
                      status={order.order_status}
                      onClick={() => handleOrderClick(order)}
                      flagged={order.flag !== undefined && order.flag !== ""}
                    >
                      {order.order_status.toUpperCase()}
                      {order.order_status.toUpperCase() === "NEEDS VERIFICATION" && (
                        <>
                          {order.flag !== undefined &&
                            order.flag !== "" &&
                            order.flag === "Patient Data Error" && (
                              <>
                                <WarningImg data-tip data-for={order.id} />
                                <ReactTooltip id={order.id} effect="solid" className="tooltip">
                                  {order.flag === "Patient Data Error" && (
                                    <>
                                      {order.sub_flag === "Collection Date Verification"
                                        ? "Sample info error - Verify collection date"
                                        : "Patient Info Error - " + order.sub_flag}
                                    </>
                                  )}
                                </ReactTooltip>
                              </>
                            )}
                        </>
                      )}
                    </StatusPill>
                  </td>
                  <td>{order.barcode}</td>
                  <td>{order.result_data && FormatBirthdate(order.result_data.uploaded)}</td>
                  <td>
                    {order.order_status === "resulted" &&
                      ((order.shared !== undefined && order.shared > 0) ||
                        (order.patient_link !== undefined && order.patient_link > 0)) && (
                        <SentImg
                          style={{ height: "20px", stroke: "rgba(17,67,119,0.5)" }}
                          data-tip
                          data-for="sentTip"
                        />
                      )}
                  </td>
                  <td>
                    {order.order_status === "resulted" && (
                      <StyledAction
                        open={selected === order.id}
                        title="More"
                        id={order.id}
                        onClick={selectRow}
                        onClose={() => setSelected("")}
                      >
                        <ActionItem
                          color="#114377"
                          onClick={() => navigate(`/results/view/${order.id}`)}
                        >
                          <ResultsImg />
                          View Results
                        </ActionItem>
                        <ActionItem
                          color="#114377"
                          onClick={() => {
                            setSendModal({
                              orderId: order.id,
                              mode: "sms_email",
                              email: order.patient.email,
                              phone: order.patient.phone,
                            });
                          }}
                        >
                          <ShareImg />
                          Send Results
                        </ActionItem>
                        <ActionItem
                          color="#114377"
                          onClick={() => {
                            setSendModal({
                              orderId: order.id,
                              mode: "email",
                              email: order.patient.email,
                            });
                          }}
                        >
                          <MailImg />
                          Send Results - Email
                        </ActionItem>
                        <ActionItem
                          color="#114377"
                          onClick={() => {
                            setSendModal({
                              orderId: order.id,
                              mode: "sms",
                              phone: order.patient.phone,
                            });
                          }}
                        >
                          <PhoneImg />
                          Send Results - Text
                        </ActionItem>
                        <ActionItem
                          color="#114377"
                          onClick={() => {
                            setSendModal({ orderId: order.id, mode: "pdf" });
                          }}
                        >
                          <ShareImg />
                          Send Results - PDF
                        </ActionItem>
                      </StyledAction>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          {orders.data.length > 0 && getPages()}
        </OrderList>
        {modal === "edit_info" && (
          <EditPatient
            onClose={() => {
              setModal("");
              getPatient(id);
            }}
            patientId={profile.id}
          />
        )}
        {modal === "delete" && (
          <Confirm
            title={"Delete Patient"}
            text={"Are you sure you want to delete this patient?"}
            confirmText={"Delete"}
            onConfirm={() => {
              setModal("");
              deletePatient(profile.id);
              navigate("/patients/view");
            }}
            onClose={() => setModal("")}
          />
        )}
      </>
    );
  }

  return null;
};

const OrderList = styled.div`
  font-family: Poppins, serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Column = styled.div`
  font-family: Poppins, serif;
  font-size: 12px;
  flex: 1;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    :first-of-type {
      border-right: 1px solid #bac4cf;
      padding-right: 35px;
    }
    :last-of-type {
      padding-left: 35px;
    }
  }
`;

const PatientInfo = styled.div`
  background: #114377;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  margin-bottom: 20px;
`;
const Title = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
`;
const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  min-height: 100px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    flex-direction: row;
  }
`;
const UserWrap = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const Avatar = styled.div`
  min-width: 60px;
  min-height: 60px;
  border-radius: 30px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;
const Name = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const Demographics = styled.table``;
const DemoRow = styled.tr`
  vertical-align: center;
  font-family: Poppins, serif;
  font-size: 12px;
  > :nth-child(1) {
    width: 30px;
  }
  > :nth-child(2) {
    padding-right: 20px;
  }
  > :nth-child(3) {
    font-weight: bold;
  }
`;
const DemoCol = styled.td`
  svg {
    height: 16px;
    width: 16px;
    stroke: ${(props) => props.theme.colors.grey.grey2};
    opacity: 0.6;
  }
`;
const SectionHeader = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey2};
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const Section = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
const ArrowButton = styled.div`
  font-size: 12px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  color: #114377;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  max-width: 510px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  > :nth-child(1) {
    margin-right: 20px;
    height: 16px;
    width: 16px;
    stroke: ${(props) => props.theme.colors.grey.grey2};
    opacity: 0.6;
  }
  > :nth-child(2) {
    min-width: 120px;
    margin-right: 20px;
  }
  > :last-child {
    margin-left: auto;
  }
`;

const DeleteArrowButton = styled(ArrowButton)`
  > :nth-child(1) {
    margin-right: 20px;
    height: 16px;
    width: 16px;
    stroke: #f13737;
    opacity: 1;
  }
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-color: #f13737;
  color: #f13737;
  > :last-child {
    margin-left: 0;
    margin-right: auto;
  }
`;

const FlexSection = styled(Section)`
  display: flex;
  align-items: center;
  > :nth-child(1) {
    flex: 1;
  }
`;

const FullBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  top: 0;
  left: 0;
`;

const StatusPill = styled.div`
  height: 24px;
  min-width: 130px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  > svg {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }

  ${(props) =>
    props.status === "pending" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "shipped" &&
    css`
      color: #ffffff;
      background: #802ca9;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "resulted" &&
    css`
      color: #219653;
      background: #cbffe3;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.status === "needs verification" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "pending lab verification" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "canceled" &&
    css`
      color: #000000;
      background: #eef2f6;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.status === "checked in" &&
    css`
      color: rgba(17, 67, 119, 0.4);
      background: rgba(48, 156, 255, 0.4);
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "rejected" &&
    css`
      color: #ef3117;
      background: #ffcfcf;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.flagged &&
    css`
      > svg {
        stroke: #f2994a;
      }
    `};
`;

const StyledAction = styled(ActionButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${StyledAction} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
  thead {
    display: none;
  }
  tr {
    display: flex;
    flex-direction: column;
    border: 1px solid ${rgba("#114377", 0.2)};
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 5px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.tabletS} {
    thead {
      display: revert;
    }
    tr {
      border: none;
      display: revert;
      td:nth-child(3) {
        font-weight: bold;
      }
    }
    td {
      border-collapse: collapse;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 20px;
      position: relative;
    }
    thead tr th {
      padding-bottom: 10px;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      border-collapse: separate;
      border-spacing: 5px 5px;
      -moz-user-select: none;
      -webkit-user-select: none;
      &:after {
        content: "";
        display: inline-flex;
        margin-left: 5px;
        width: 20px;
        font-size: 8px;
      }
      &[aria-sort="ascending"]:after {
        content: " ▲";
      }
      &[aria-sort="descending"]:after {
        content: " ▼";
      }
    }
    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      border: none;
      width: 10px;
      padding-right: 0;
    }
    tbody tr:hover {
      background-color: #eef2f6;
      td:first-child {
        border-radius: 20px 0 0 20px;
      }
      td:last-child {
        border-radius: 0 20px 20px 0;
      }
      ${StyledAction} {
        opacity: 1;
      }
    }
  }
`;

const Pagination = styled.div`
  display: flex;
  & * {
    padding: 8px 8px;
  }
  gap: 5px;
`;

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  min-height: 25px;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const ColHead = styled.th`
  ${(props) =>
    props.sort &&
    css`
      cursor: pointer;
    `};
`;

const Page = styled.div`
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 14px;
  ${({ active }) =>
    !active &&
    css`
      :hover {
        background-color: #ddd;
        cursor: pointer;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #4caf50;
      color: white;
    `}
`;

export default Patient;
