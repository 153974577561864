import styled from "styled-components";
import TextInput from "../../../shared/components/TextInput";
import { Link } from "react-router-dom";
import Button from "../../../shared/components/Button";
import { useForm } from "react-hook-form";
import API from "../../../utils/api";
import { useState } from "react";
import { ReactComponent as InfoCircle } from "../../../shared/assets/icons/Circle/Info filled.svg";
import { ReactComponent as MailIcon } from "../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as ResetCircle } from "../../../shared/assets/icons/resetcircle.svg";

const Forgot = () => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Possible states
  const states = {
    initial: "",
    success: "success",
    error: "error",
  };

  // Component states
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(states.initial);
  const [email, setEmail] = useState("");

  // Form submission to process the forgot password request
  const onSubmit = async (data) => {
    // Reset the form state
    setLoading(true);
    setState(states.initial);

    // Submit the reset request
    try {
      const response = await API.post("/forgot", {
        email: data.email,
        type: "clinic",
      });

      // Check if the response was an error
      // if so, set error state and if not move to success
      if (response.status !== 200) {
        setState(states.error);
      } else {
        setState(states.success);
        setEmail(data.email);
      }
    } catch (e) {
      setState(states.error);
    }

    // Set the form to not loading
    setLoading(false);
  };

  // Success state render
  if (state === states.success) {
    return (
      <Wrapper>
        <ResetCircle />
        <Title>Reset Link Sent!</Title>
        <p>
          An email containing a password reset link has been sent to your email
          address:
        </p>
        <SuccessBox>
          <MailIcon />
          {email}
        </SuccessBox>
        <Link to={"/"}>
          <Button>Return to Login</Button>
        </Link>
      </Wrapper>
    );
  }

  // Initial page state
  return (
    <>
      <Title>Forgot Password</Title>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id={"email"}
          title={"Email Address"}
          error={errors.email !== undefined || state === states.error}
          {...register("email", {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            },
          })}
          disabled={loading}
        />
        {state === states.error && (
          <Error>
            <InfoCircle /> Unable to process a password reset.
          </Error>
        )}
        <FormFooter>
          <Link to={"/"}>
            <Button variant="secondary" type="button" disabled={loading}>
              Cancel
            </Button>
          </Link>
          <Button type="submit">Reset Password</Button>
        </FormFooter>
      </FormWrapper>
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  button {
    width: 100%;
    justify-content: center;
  }
  p {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
  }
  h2 {
    margin-top: 10px;
  }
`;

const Error = styled.div`
  gap: 10px;
  color: #f13737;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  svg {
    fill: #f13737;
  }
`;

const FormWrapper = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SuccessBox = styled.div`
  padding: 10px;
  background: #f8f9fb;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #114377;
  display: flex;
  gap: 20px;
  svg {
    stroke: #114377;
  }
`;

const Title = styled.h2``;

export default Forgot;
