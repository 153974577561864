import { ThemeProvider } from "styled-components";

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "600px",
  tablet: "768px",
  tabletL: "1280px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tabletS: `(min-width: ${sizes.tabletS})`,
  tabletL: `(min-width: ${sizes.tabletL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const defaultTheme = {
  breakpoints: {
    sizes,
    devices,
  },
  colors: {
    blue: {
      light: {
        primary: "#114377",
        secondary: "#0d5096",
      },
      dark: {
        primary: "rgba(17, 67, 119, 0.3)",
      },
    },
    white: {
      primary: "#FFFFFF",
    },
    green: {
      primary: "green",
    },
    grey: {
      primary: "#F6F8FA",
      grey2: "#4F4F4F",
      grey3: "#bac4cf",
      grey4: "#828282",
      grey5: "#e0e0e0",
    },
    red: {
      primary: "#F13737",
      dark: "rgb(81,0,0)",
    },
    black: {
      primary: "#000000",
      offBlack: "#0B0B0B",
    },
  },
};

const DXThemeProvider = (props) => {
  const theme = Object.assign({}, defaultTheme, props.theme);
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default DXThemeProvider;
