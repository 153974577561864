import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { hideVisually } from "polished";
import { ReactComponent as CircleImg } from "../../../shared/assets/icons/Action/Tick Small Circle.svg";

const Radio = ({
  className,
  checked,
  children,
  group,
  label,
  error,
  iconPassThrough,
  onClick,
  ...props
}) => {
  return (
    <RadioContainer
      className={className}
      checked={checked}
      iconPassThrough={iconPassThrough}
      onClick={onClick}
    >
      {!label && children}
      <label>
        <HiddenRadio checked={checked} name={group} {...props} />
        {label && children}
        <StyledRadio checked={checked} />
      </label>
    </RadioContainer>
  );
};

Radio.propTypes = {
  label: PropTypes.bool,
  check: PropTypes.bool,
  error: PropTypes.bool,
  iconPassThrough: PropTypes.bool,
};

const HiddenRadio = styled.input.attrs({ type: "radio" })`
  // Hide radio visually but remain accessible to screen readers.
  ${hideVisually()};
`;

const StyledRadio = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
  ${({ checked }) =>
    checked &&
    css`
      & path.svgcircle {
        fill: white;
      }
      & path.svgcheck {
        fill: #114377;
      }
    `}
`;

const RadioContainer = styled.div`
  label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  cursor: pointer;
  min-height: 50px;
  background: #ffffff;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  text-transform: capitalize;
  color: #0b0b0b;
  svg {
    height: 20px;
    width: 20px;
    flex-basis: content;
    // TODO: Clean this up
    ${({ iconPassThrough }) =>
      !iconPassThrough &&
      css`
        stroke: #114377;
        fill: #114377;
      `}
  }
  ${({ checked }) =>
    checked &&
    css`
      color: white;
      background-color: #114377;
      ${({ iconPassThrough }) =>
        !iconPassThrough &&
        css`
          svg {
            stroke: white;
            fill: white;
          }
        `}
    `}
`;

export default Radio;
