import Modal from "../../../../shared/components/Modal";
import styled, { css } from "styled-components";
import { ReactComponent as CircleImg } from "../../../../shared/assets/icons/Action/Tick Small Circle.svg";
import Button from "../../../../shared/components/Button";
import API from "../../../../utils/api";
import { useState } from "react";

const TemporaryPassword = ({ onClose, userId }) => {
  const [loading, setLoading] = useState(false);
  const saveNew = async () => {
    setLoading(true);
    try {
      await API.post(`/users/${userId}/temp`);
    } catch (e) {}
    setLoading(false);
    onClose();
  };
  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <SubTitle>MANAGE USERS</SubTitle>
          <Title>Send Temporary Password</Title>
        </Header>
        <p>Are you sure you want to generate and send a new temporary password?</p>
        <p>Doing so will invalidate the users current password.</p>
        <Buttons>
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={saveNew} loading={loading} disabled={loading}>
            Send Temporary
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default TemporaryPassword;

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  width: 360px;
  p {
    font-family: Poppins, serif;
    font-style: normal;
    font-size: 12px;
  }
`;

const CheckIcon = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
`;
const ItemHoverStyle = css`
  color: white;
  background-color: #114377;
  svg {
    & path {
      stroke: white;
    }
    & rect {
      fill: white;
    }
  }
  ${CheckIcon} {
    & path.svgcircle {
      fill: white;
    }
    & path.svgcheck {
      fill: #114377;
      stroke: #114377;
    }
  }
`;
const Item = styled.div`
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0b0b0b;
  gap: 10px;
  cursor: pointer;
  span {
    flex: 1;
  }
  &:hover {
    ${ItemHoverStyle}
  }
  ${(props) => props.active && ItemHoverStyle}
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
