import styled, { css } from "styled-components";
import Button from "../../../../shared/components/Button";
import { ReactComponent as ToolIcon } from "../../../../shared/assets/icons/Other/Tool.svg";
import { ReactComponent as HR5CircleImg } from "../../../../shared/assets/icons/hr5circle.svg";
import { ReactComponent as UnivCircleImg } from "../../../../shared/assets/icons/univcircleimg.svg";
import { ReactComponent as HomeTestingImg } from "../../../../shared/assets/icons/hometestingcircle.svg";
import { ReactComponent as PrebuyImg } from "../../../../shared/assets/icons/prebuycircle.svg";
import { ReactComponent as PrebuyShopImg } from "../../../../shared/assets/icons/Shop/Prebuy.svg";
import { ReactComponent as ClinicTestingImc } from "../../../../shared/assets/icons/clinictestingcircle.svg";
import Radio from "../../../../shared/components/Radio";
import RadioSwitch from "../../../../shared/components/RadioSwitch";
import { useCallback, useEffect, useState } from "react";
import TextInput from "../../../../shared/components/TextInput";
import Select from "../../../../shared/components/Select";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../../utils/api";
import { useForm } from "react-hook-form";
import Switch from "../../../../shared/components/Switch/Switch";
import Modal from "../../../../shared/components/Modal";
import { useTitle } from "../../../../contexts/TitleContext";
import Prebuy from "../../../../components/Modals/Prebuy";
import PrebuyAutofill from "../../../../components/Modals/PrebuyAutofill/PrebuyAutofill";
import API from "../../../../utils/api";
import PrebuyOffer from "../../../../components/Modals/PrebuyOffer";
import { useCookies } from "react-cookie";

const pricingOptions = [
  {
    id: "global_pricing",
    name: "Global Pricing",
  },
  {
    id: "custom_pricing",
    name: "Custom Pricing",
  },
];

const PricingPrebuy = () => {
  const [modal, setModal] = useState({ mode: "" });
  const { setTitle } = useTitle();
  const [credits, setCredits] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clinicPricing, setClinicPricing] = useState({});
  const [currentClinic, setCurrentClinic] = useState({});
  const [offers, setOffers] = useState([]);
  const [cookies, setCookie] = useCookies(["offerPopups", "prebuyReminder"]);
  const [offer, setOffer] = useState({});
  const [prebuyPlans, setPrebuyPlans] = useState([]);
  const [offerPopup, setOfferPopup] = useState({ code: "", offerId: null });
  const navigate = useNavigate();
  const { id } = useParams();

  const dismissOfferPopup = (code) => {
    let popup = {};
    if (cookies.offerPopups === undefined || cookies.offerPopups[code] === undefined) {
      popup = {};
    } else {
      popup = cookies.offerPopups;
    }

    setCookie(
      "offerPopups",
      {
        ...popup,
        [code]: popup[code] + 1 || 1,
      },
      { path: "/" }
    );
    setOfferPopup({ code: "", offerId: null });
  };

  const getTestPrice = (credits, discountPercentage, clinicPricing) => {
    const basePrice = clinicPricing.hr5?.clinic / 100;
    const eaPrice = Number(basePrice - basePrice * (discountPercentage / 100)).toFixed(2);
    const planPrice = Number(eaPrice * credits).toFixed(2);
    return {
      eaPrice,
      planPrice,
    };
  };

  const getCredits = async () => {
    try {
      const response = await Api.get(`/profile/clinic/credits`);
      setCredits(response.data);
    } catch (e) {}
  };
  const getClinicPricing = async () => {
    try {
      const response = await Api.get(`/profile/clinic/pricing`);
      if (
        response.data &&
        response.data.tests &&
        response.data.tests.hr5 &&
        response.data.tests.hr5.custom
      ) {
        setIsCustom(true);
      }
      setClinicPricing(response.data.tests);
    } catch (e) {}
  };
  const getCurrentClinic = async () => {
    try {
      const response = await Api.get("/profile/clinic");
      setCurrentClinic(response.data);
    } catch (e) {}
  };

  const getOffers = async () => {
    try {
      const response = await API.get("/offers");
      setOffers(response.data);

      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].offers.length > 0) {
          if (response.data[i].max_redemptions > response.data[i].offers[0].redemptions) {
            setOffer({
              code: response.data[i].code,
              offerId: response.data[i]._id,
              max_redemptions: response.data[i].max_redemptions,
              redeemed: false,
            });
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPrebuyPlans = async () => {
    try {
      const response = await Api.get("/global/pricing/prebuy_plans");
      setPrebuyPlans(response.data);
    } catch (e) {}
  };

  // Set page title
  useEffect(() => {
    setTitle({ name: "Pricing & Pre-buy", back: "settings" });
  }, [setTitle]);

  useEffect(() => {
    Promise.all([
      getCredits(),
      getClinicPricing(),
      getCurrentClinic(),
      getOffers(),
      getPrebuyPlans(),
    ]).then(() => setLoaded(true));
  }, []);

  if (!loaded) return null;

  return (
    <>
      {offers.map((offer) => (
        <>
          {offer.code === "august-endofsummer" && (
            <Notice>
              <span style={{ color: "#FF0000", fontWeight: "bold", marginRight: "5px" }}>
                Alert!
              </span>
              Special offer available:
              <span style={{ fontWeight: "bold", marginRight: "5px", marginLeft: "5px" }}>
                Buy <span style={{ color: "green", textDecoration: "underline" }}>5</span> test
                credits, get <span style={{ color: "green", textDecoration: "underline" }}>1</span>{" "}
                free!
              </span>
              Limited time only,{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() =>
                  setOfferPopup({
                    code: offer.code,
                    offerId: offer._id,
                    redeemed: offer.max_redemptions <= offer.offers[0].redemptions,
                    max_redemptions: offer.max_redemptions,
                  })
                }
              >
                click here
              </span>{" "}
              to view details
            </Notice>
          )}
        </>
      ))}
      <Wrapper>
        <Col>
          <Wrapper>
            <Col>
              <h3 style={{ fontWeight: 400 }}>
                My Current Price: <strong>{isCustom ? "Custom" : "Standard"}</strong>
              </h3>
              {currentClinic?.settings?.disable_prebuy_plans !== true && !isCustom && (
                <h3 style={{ fontWeight: 400 }}>
                  Pre-buy Subscription Status:{" "}
                  <strong>
                    {currentClinic.settings.prebuy_plan_id ? (
                      <span style={{ color: "darkgreen" }}>Subscribed</span>
                    ) : (
                      <span style={{ color: "darkred" }}>Not Subscribed</span>
                    )}
                  </strong>
                </h3>
              )}
              <h3 style={{ fontWeight: 400 }}>{isCustom ? "Custom" : "Standard"} Pricing</h3>
              <PricingContainer>
                <div>
                  <h3>In-Clinic</h3>
                  <span>
                    <strong>${(clinicPricing.hr5?.clinic / 100).toFixed(2)}</strong> / test
                  </span>
                </div>
                <div>
                  <h3>At-Home</h3>
                  <span>
                    <strong>${(clinicPricing.hr5?.at_home / 100).toFixed(2)}</strong>/ test
                  </span>
                </div>
              </PricingContainer>
            </Col>
            <Col>
              <h3>Current Test Credit Balance</h3>
              <CreditTypeRow>
                <CreditType>
                  <div>
                    <span>In-Clinic Tests</span>
                    <span style={{ fontWeight: 600, fontSize: "20px", color: "#114377" }}>
                      {credits.hr5 || 0}
                    </span>
                  </div>
                </CreditType>
              </CreditTypeRow>
            </Col>
          </Wrapper>
          {currentClinic?.settings?.disable_prebuy_plans !== true && !isCustom && (
            <>
              <h3 style={{ fontWeight: 400 }}>Available Pre-buy Options</h3>
              <PrebuyPlans>
                {prebuyPlans.map((plan) => (
                  <Plan
                    active={plan.id === currentClinic.settings.prebuy_plan_id}
                    last={
                      !currentClinic.settings.prebuy_plan_id &&
                      plan.id !== currentClinic.settings.prebuy_plan_id &&
                      plan.id === currentClinic.last_prebuy_plan_id
                        ? new Date(currentClinic.last_prebuy).toLocaleDateString()
                        : false
                    }
                  >
                    <div>{plan.credits} Credits</div>
                    <div>
                      <strong>
                        $
                        {
                          getTestPrice(plan.credits, plan.discount_percentage, clinicPricing)
                            .eaPrice
                        }
                      </strong>{" "}
                      / test
                    </div>
                    <div>Cost savings of {plan.discount_percentage}%</div>
                    <div>
                      {plan.id === currentClinic.settings.prebuy_plan_id ? (
                        <Subscribe
                          onClick={() => setModal({ mode: "upgrade" })}
                          style={{
                            backgroundColor: "#f8f9fb",
                            color: "darkred",
                            boxShadow: "none",
                          }}
                        >
                          Manage
                        </Subscribe>
                      ) : (
                        <PurchaseOnce onClick={() => setModal({ mode: "prebuy-single", plan })}>
                          Purchase Once
                        </PurchaseOnce>
                      )}
                      {plan.id === currentClinic.settings.prebuy_plan_id ? (
                        <Subscribe
                          disabled
                          style={{ backgroundColor: "transparent", color: "#114377" }}
                        >
                          Subscribed
                        </Subscribe>
                      ) : (
                        <Subscribe
                          onClick={() =>
                            setModal({
                              mode: currentClinic.settings.prebuy_plan_id
                                ? "upgrade"
                                : "prebuy-subscribe",
                              plan,
                            })
                          }
                        >
                          Subscribe
                        </Subscribe>
                      )}
                    </div>
                  </Plan>
                ))}
              </PrebuyPlans>
            </>
          )}
        </Col>
        {modal.mode === "prebuy-single" && (
          <Prebuy
            clinicProfile={currentClinic}
            initialScreen={
              currentClinic.settings.prebuy_plan_id ? "cant-manage" : "single_purchase"
            }
            defaultPlan={modal.plan}
            clinicPricing={clinicPricing}
            onLater={() => {
              setModal({ mode: "" });
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
            onUpdate={() => {
              setModal("");
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
          />
        )}
        {modal.mode === "upgrade" && (
          <Prebuy
            initialScreen={"upgrade"}
            clinicProfile={currentClinic}
            clinicPricing={clinicPricing}
            onLater={() => {
              setModal({ mode: "" });
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
            onUpdate={() => {
              setModal("");
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
          />
        )}
        {modal.mode === "prebuy-subscribe" && (
          <Prebuy
            clinicProfile={currentClinic}
            initialScreen={"subscribe"}
            defaultPlan={modal.plan}
            clinicPricing={clinicPricing}
            onLater={() => {
              setModal({ mode: "" });
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
            onUpdate={() => {
              setModal("");
              setTimeout(() => {
                getCredits();
                getCurrentClinic();
              }, 500);
            }}
          />
        )}
        {offerPopup.code === "july-heatwave" && (
          <PrebuyOffer
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            offerId={offerPopup.offerId}
            onDismiss={() => dismissOfferPopup("july-heatwave")}
            onUpdate={() => navigate("/settings/billing/edit")}
            onComplete={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            maxPurchase={offerPopup.max_redemptions}
            initialScreen={offerPopup.redeemed ? "redeemed" : "offer"}
          />
        )}
        {offerPopup.code === "august-endofsummer" && (
          <PrebuyOffer
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            offerId={offerPopup.offerId}
            onDismiss={() => dismissOfferPopup("august-endofsummer")}
            onUpdate={() => navigate("/settings/billing/edit")}
            onComplete={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            maxPurchase={offerPopup.max_redemptions}
            initialScreen={offerPopup.redeemed ? "redeemed" : "offer"}
          />
        )}
      </Wrapper>
    </>
  );
};

const PurchaseOnce = styled(Button)`
  background-color: #f8f9fb;
  line-height: 1;
  color: #4f4f4f;
  height: 34px;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  :hover {
    background-color: #f8f9fb;
  }
`;
const Subscribe = styled(Button)`
  background-color: darkgreen;
  line-height: 1;
  color: white;
  height: 34px;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
  :hover {
    background-color: darkgreen;
  }
`;

const PrebuyPlans = styled.div`
  display: flex;
  gap: 5px;
`;

const Plan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 140px;
  border-radius: 8px;
  padding: 10px;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 10px solid white;
  border-top: 35px solid white;
  font-family: Poppins, serif;
  position: relative;
  & > :nth-child(1) {
    font-weight: 600;
    color: #114377;
    font-size: 18px;
  }
  & > :nth-child(2) {
    color: rgb(50, 93, 149);
  }
  & > :nth-child(3) {
    font-size: 10px;
    color: #0b0b0b;
  }

  // if active prop is true, then apply the following styles
  ${({ active }) =>
    active &&
    css`
      border-color: rgb(206, 217, 200);
      :before {
        content: "Active";
        font-size: 13px;
        position: absolute;
        color: #4f4f4f;
        top: -25px;
        left: 0;
      }
    `}

  // if last prop is true, then apply the following styles
  ${({ last }) =>
    last &&
    css`
      border-color: rgb(233, 241, 249);
      :before {
        content: "Recently Purchased";
        font-size: 11px;
        font-weight: 500;
        position: absolute;
        color: #4f4f4f;
        top: -30px;
        left: 0;
      }
      :after {
        content: "on ${last}";
        font-size: 10px;
        position: absolute;
        color: #4f4f4f;
        top: -15px;
        left: 0;
      }
    `}
`;

const StyledRadio = styled(Radio)`
  flex: 1;
  max-width: 320px;
  & > label > span {
    display: flex;
    align-items: center;
    gap: 15px;
    & > svg {
      height: 30px;
      width: 30px;
    }
  }
`;
const Row = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
  flex-wrap: wrap;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;
const Header = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  & h3 {
    flex: 1;
  }
`;
const CreditTypeRow = styled.div`
  display: flex;
  gap: 30px;
`;
const CreditType = styled.div`
  background-color: white;
  border: 1px solid #114377;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  display: flex;
  gap: 20px;
  & > svg {
    height: 50px;
    width: 50px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    font-family: Poppins, serif;
    align-content: center;
    text-align: center;
    & > :nth-child(1) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #0b0b0b;
    }
    & > :nth-child(2) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #0b0b0b;
    }
  }
`;
const PricingContainer = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 350px;
  box-sizing: border-box;
  font-family: Poppins, serif;

  & > h3 {
    flex: 1;
    font-size: 15px;
    text-align: center;
  }

  & > div {
    background: #f8f9fb;
    color: #114377;
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    padding-left: 30px;
    padding-right: 30px;

    & > h3 {
      font-size: 14px;
    }

    & > svg {
      height: 50px;
      width: 50px;
    }

    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4f4f4f;
    }
  }
`;
const PricingConfig = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const Pricing = styled.div`
  display: flex;
  gap: 35px;
  & > div {
    width: 100%;
    max-width: 555px;
  }
`;
const StyledRadioSwitch = styled(RadioSwitch)`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  & > ul {
    background-color: #bac4cf;
    margin: 0px;
  }
`;
const Title = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  color: #4f4f4f;
  flex: 0 0 100%;
  margin-bottom: -14px;
`;
const StyledSelect = styled(Select)``;
const Col = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex: 1;
`;
// Notice
const Notice = styled.div`
  border-radius: 10px;
  font-family: Poppins, serif;
  margin-bottom: 20px;
  background-color: rgba(241, 55, 55, 0.1);
  padding: 10px;
  font-size: 16px;
`;
export default PricingPrebuy;
