import Modal from "../../../shared/components/Modal";
import styled, { css } from "styled-components";
import { ReactComponent as BirthdayImg } from "../../../shared/assets/icons/birthday.svg";
import { ReactComponent as CircleImg } from "../../../shared/assets/icons/Action/Tick Small Circle.svg";
import { ReactComponent as MaleImg } from "../../../shared/assets/icons/gender/male.svg";
import { ReactComponent as FemaleImg } from "../../../shared/assets/icons/gender/female.svg";
import GetInitials from "../../../shared/utilities/GetInitials";
import { useEffect, useState } from "react";
import Api from "../../../utils/api";
import { FormatBirthdate } from "../../../shared/utilities/FormatBirthdate";
import Button from "../../../shared/components/Button";

const PatientSearch = ({ onClose, search, selectPatient, override, data, orderData }) => {
  const [patients, setPatients] = useState([]);

  const getPatients = async () => {
    try {
      const response = await Api.post("/patients/search", { ...search, limit: 10 });
      if (response.data && response.data.data.length) {
        setPatients(response.data.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (data !== undefined && data !== null) {
      setPatients(data);
    } else {
      getPatients();
    }
  }, []);

  return (
    <Modal onClose={onClose} open>
      <Wrapper>
        <Header>
          <SubTitle>Order a Test</SubTitle>
          <Title>Search Results (Top 10)</Title>
        </Header>
        {override && (
          <div>
            <p style={{ fontSize: "12px" }}>
              You did not select an existing patient for this order but we found possible matches.
              Click on the patient you would like to use or create a new patient.
            </p>
            <Button variant="secondary" onClick={() => selectPatient(null, override, orderData)}>
              Proceed with new patient
            </Button>
          </div>
        )}
        <List>
          {patients.length === 0 && <Item>No patients found matching criteria.</Item>}
          {patients.map((patient) => (
            <Item onClick={() => selectPatient(patient, override, orderData)}>
              <UserWrap>
                <Avatar>{GetInitials(`${patient.first} ${patient.last}`)}</Avatar>
                <Name>
                  {`${patient.first} ${patient.last}`}
                  <div>Created: {new Date(patient.created).toLocaleDateString()}</div>
                </Name>
              </UserWrap>
              <Birthday>
                <BirthdayImg />
                {FormatBirthdate(patient.dob)}
              </Birthday>
              <div>
                {patient.profile_type === "patient" && <span>Patient</span>}
                {patient.profile_type === "practice_team_member" && (
                  <span>Practice Team Member</span>
                )}
              </div>
              <Gender>
                {patient.gender === "male" && <MaleImg />}
                {patient.gender === "female" && <FemaleImg />}
                {`${patient.gender}`}
              </Gender>
              <CheckIcon />
            </Item>
          ))}
        </List>
      </Wrapper>
    </Modal>
  );
};

export default PatientSearch;

const Name = styled.div`
  line-height: 14px;
  & div {
    font-weight: normal;
    font-size: 10px;
  }
`;
const Birthday = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & svg {
    height: 16px;
    stroke: #828282;
    fill: #828282;
  }
  font-size: 10px;
  font-weight: normal;
  flex: 1;
`;
const Gender = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & svg {
    height: 16px;
    stroke: #828282;
    fill: #828282;
  }
  flex: 1;
  font-size: 10px;
  font-weight: normal;
  text-transform: capitalize;
`;

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  > * {
    flex-shrink: 0;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.tabletS} {
    width: 560px;
  }
`;

const List = styled.div`
  margin-top: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
`;
const ItemHoverStyle = css`
  color: #114377;
  border-color: #114377;
  ${CheckIcon} {
    & path.svgcircle {
      fill: #114377;
    }
  }
`;
const UserWrap = styled.div`
  display: flex;
  gap: 15px;
  font-family: Poppins, serif;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
  flex: 2;
  > * {
    flex-wrap: wrap;
  }
`;
const Item = styled.div`
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0b0b0b;
  gap: 10px;
  cursor: pointer;
  justify-content: space-between;
  span {
    flex: 1;
  }
  &:hover {
    ${ItemHoverStyle}
  }
  ${(props) => props.active && ItemHoverStyle}
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const Avatar = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;
