import styled from "styled-components";
import TextInput from "../../../shared/components/TextInput";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../../shared/components/Button";
import { useForm } from "react-hook-form";
import API from "../../../utils/api";
import { useEffect, useState } from "react";
import { ReactComponent as InfoCircle } from "../../../shared/assets/icons/Circle/Info filled.svg";
import { ReactComponent as ResetCircle } from "../../../shared/assets/icons/resetcircle.svg";

const ForgotProcess = () => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Possible states
  const states = {
    initial: "",
    success: "success",
    error: "error",
  };

  const navigate = useNavigate();

  // Component states
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(states.initial);
  const [error, setError] = useState("");
  const { token } = useParams();

  // Form submission to process the forgot password request
  const onSubmit = async (data) => {
    // Do the passwords match?
    if (data.password !== data.confirm) {
      setState(states.error);
      return;
    }

    // Reset the form state
    setLoading(true);
    setState(states.initial);

    // Submit the reset request
    try {
      const response = await API.post("/forgot/process", {
        token: data.token,
        password: data.password,
      });

      // Check if the response was an error
      // if so, set error state and if not move to success
      if (response.status !== 200) {
        setState(states.error);
        if (response.data.error) setError(response.data.error);
      } else {
        setState(states.success);
      }
    } catch (e) {
      if (e.response && e.response.data.error) {
        setError(e.response.data.error);
      }
      setState(states.error);
    }

    // Set the form to not loading
    setLoading(false);
  };

  // If the token is missing, redirect to the main page
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token, navigate]);

  // Success state render
  if (state === states.success) {
    return (
      <Wrapper>
        <ResetCircle />
        <Title>Password Reset!</Title>
        <p>Your password has been reset. You can now login.</p>
        <Link to={"/"}>
          <Button>Return to Login</Button>
        </Link>
      </Wrapper>
    );
  }

  // Initial page state
  return (
    <>
      <Title>Forgot Password</Title>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id={"password"}
          type="password"
          title={"New Password"}
          placeholder={"enter password"}
          error={errors.email !== undefined || state === states.error}
          {...register("password", { required: true })}
          disabled={loading}
        />
        <TextInput
          id={"confirm"}
          type="password"
          title={"Confirm Password"}
          placeholder={"enter password"}
          error={errors.email !== undefined || state === states.error}
          {...register("confirm", { required: true })}
          disabled={loading}
        />
        <Info>
          <InfoCircle /> Password must be at least 8 characters long and must
          contain 1 special character, 1 uppercase character, and 1 number
        </Info>
        {state === states.error && (
          <Error>
            <InfoCircle /> {error === "" ? "Unable to process request" : error}
          </Error>
        )}
        <FormFooter>
          <Link to={"/"}>
            <Button variant="secondary" type="button" disabled={loading}>
              Cancel
            </Button>
          </Link>
          <Button type="submit">Reset Password</Button>
        </FormFooter>
        <input type="hidden" value={token} {...register("token")} />
      </FormWrapper>
    </>
  );
};

const Wrapper = styled.div`
  text-align: center;
  button {
    width: 100%;
    justify-content: center;
  }
  p {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
  }
  h2 {
    margin-top: 10px;
  }
`;

const Error = styled.div`
  gap: 10px;
  color: #f13737;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  svg {
    min-width: 20px;
    min-height: 20px;
    max-height: 20px;
    max-width: 20px;
    fill: #f13737;
  }
`;

const Info = styled.div`
  gap: 10px;
  color: #4f4f4f;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  svg {
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;
    fill: #bac4cf;
  }
`;

const FormWrapper = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2``;

export default ForgotProcess;
