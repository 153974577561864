import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const Flexbox = styled.div``;
export const Row = styled.div`
  display: flex;
`;

const media = {
  xs: (styles) => `
    @media only screen and (max-width: 480px) {
        ${styles}
    }
  `,
};
const Col = styled.div`
  // Bound to 12 columns per row
  ${({ bound }) =>
    bound &&
    css`
      flex: ${({ size }) => size * (12 / 100)};
    `}
  // Unbound columns
  ${({ bound }) =>
    !bound &&
    css`
      flex: ${({ size }) => size};
    `}
  ${({ collapse }) =>
    collapse &&
    media[collapse](`
    display: none;
  `)}
`;

Col.propTypes = {
  size: PropTypes.number,
  bound: PropTypes.bool,
};
Col.defaultProps = {
  size: 1,
  bound: true,
};

export { Col };
