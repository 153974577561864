import React from "react";
import ReactDOM from "react-dom";
import "./shared/index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import reportWebVitals from "./reportWebVitals";
import DXThemeProvider from "./shared/DXThemeProvider";
import { MainStoreProvider } from "./stores/main";
import App from "./components/App";
import { stripeKey } from "./utils/api";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CookiesProvider } from "react-cookie";

if (window.config.environment !== "local.development") {
  Sentry.init({
    dsn: window.config.sentryKey,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: window.config.environment,
  });
}

const stripePromise = loadStripe(stripeKey);
const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap",
  },
];

ReactDOM.render(
  <React.StrictMode>
    <DXThemeProvider>
      <BrowserRouter>
        <MainStoreProvider>
          <Elements stripe={stripePromise} options={{ fonts }}>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </Elements>
        </MainStoreProvider>
      </BrowserRouter>
    </DXThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
