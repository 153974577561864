import styled, { css } from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import TextInput from "../../../../shared/components/TextInput";
import API, { apiPath } from "../../../../utils/api";
import { useNavigate, useParams } from "react-router-dom";

const Results = () => {
  const { setTitle } = useTitle();
  const fromDate = useRef();
  const toDate = useRef();
  const [bulletins, setBulletins] = useState([]);
  const [selected, setSelected] = useState({});
  const [modal, setModal] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const handleIframe = useCallback(
    (e) => {
      if (typeof e.data === "string" && !e.origin.includes("stripe")) {
        if (e.data === "pdf") {
          window.open(`${apiPath}/orders/${id}/results/pdf`, "_blank");
        } else {
          navigate(`/results/view/${e.data}`);
        }
      }
    },
    [id]
  );

  // Set page title
  useEffect(() => {
    setTitle({
      name: "View Results",
      previous: true,
    });
    window.addEventListener("message", handleIframe);
    return () => {
      window.removeEventListener("message", handleIframe);
    };
  }, [setTitle, id]);

  return (
    <Wrapper>
      <iframe
        title={"results"}
        src={`${apiPath}/orders/${id}/results/html`}
        frameBorder={0}
        name={"results"}
        id={"results"}
      />
    </Wrapper>
  );
};

const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;

const Details = styled.div`
  & :nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #0b0b0b;
  }
  & :nth-child(2) {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #4f4f4f;
  }
`;

const DateEl = styled.div`
  display: flex;
  flex-direction: column;
  & :nth-child(1) {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    text-align: right;
    white-space: nowrap;
    flex: 1;
    max-height: 30px;
    color: #0b0b0b;
  }
`;

const Unread = styled.div`
  background: #114377;
  width: 10px;
  height: 10px;
  border-radius: 5px;
`;

const MessageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Message = styled.div`
  font-family: Poppins, serif;
  min-height: 90px;
  border-radius: 15px;
  display: flex;
  gap: 15px;
  padding: 15px;
  box-sizing: border-box;
  > svg {
    min-width: 60px;
  }
  ${({ unread, active }) =>
    unread &&
    !active &&
    css`
      ${DateEl} :nth-child(1), ${Details} :nth-child(1), ${Details} :nth-child(2) {
        color: #114377;
      }
    `}

  ${({ active }) =>
    active &&
    css`
      ${DateEl} :nth-child(1), ${Details} :nth-child(1), ${Details} :nth-child(2) {
        color: white;
      }
      background: #114377;
    `}
  
  ${({ active }) =>
    !active &&
    css`
      :hover {
        cursor: pointer;
        background: ${(props) => props.theme.colors.grey.grey5};
      }
    `}
`;

const Clinician = styled.div`
  > div {
    display: flex;
    align-content: center;
    > svg {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

const SearchHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #4f4f4f;
  }
  svg {
    cursor: pointer;
    stroke: #4f4f4f;
  }
`;

const FullWidthInput = styled(TextInput).attrs({ variant: "secondary" })`
  flex: 1;
  svg {
    stroke: #114377;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #0b0b0b;
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const DefaultCol = styled.div`
  flex: 1;
`;
const LeftCol = styled(DefaultCol)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 30px;
`;
const RightCol = styled(DefaultCol)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  iframe {
    height: 100%;
    width: 100%;
  }
`;

const InsideWrapper = styled(Wrapper)`
  flex-direction: column;
  border: none;
  background-color: #f8f9fb;
`;

export default Results;
