import { ReactComponent as MoreImg } from "../../assets/icons/Other/More Horizontal.svg";
import { ReactComponent as CloseImg } from "../../assets/icons/Action/X.svg";
import styled from "styled-components";
import Button from "../Button";
import PropTypes from "prop-types";

const ActionButton = ({
  title,
  children,
  onClick,
  id,
  onClose,
  open,
  className,
}) => {
  return (
    <ActionWrapper className={className}>
      <MenuButton data-id={id} onClick={onClick}>
        <MoreImg />
      </MenuButton>
      <ActionMenu open={open}>
        <Header>
          <span>{title}</span>
          <CloseImg onClick={onClose} />
        </Header>
        <MenuItems>{children}</MenuItems>
      </ActionMenu>
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div`
  position: relative;
  pointer-events: none;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: -0.005em;
    color: #0b0b0b;
  }
  svg {
    cursor: pointer;
  }
  margin-bottom: 10px;
`;

const ActionItem = styled.button`
  pointer-events: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  font-family: Poppins, serif;
  height: 44px;
  width: 100%;
  background: #f8f9fb;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  color: ${({ color }) => color};
  font-weight: 500;
  svg {
    stroke: ${({ color }) => color};
    height: 20px;
    padding-right: 10px;
  }
  :hover {
    background: #eef2f6;
  }
`;

const ActionMenu = styled.div`
  box-sizing: border-box;
  padding: 15px;
  position: absolute;
  opacity: ${({ open }) => (open ? 1 : 0)};
  top: 0;
  right: 50px;
  box-shadow: 2px 5px 8px rgba(17, 67, 119, 0.3);
  border-radius: 10px;
  width: 230px;
  background-color: white;
  cursor: default;
  z-index: 1;
  pointer-events: auto;
  display: ${({ open }) => (open ? "block" : "none")};
`;

const MenuButton = styled(Button).attrs({ variant: "secondary" })`
  position: relative;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  pointer-events: auto;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  width: 40px;
  > svg {
    stroke: #114377;
    margin-right: 0 !important;
    height: 20px;
    pointer-events: none;
  }
`;

ActionMenu.propTypes = {
  open: PropTypes.bool,
};

ActionItem.defaultProps = {
  color: "#114377",
};

// TODO: Add PropTypes
export { ActionItem, ActionButton };
