import Modal from "../../../../shared/components/Modal";
import styled, { css } from "styled-components";
import Button from "../../../../shared/components/Button";
import { useCallback, useState } from "react";
import API from "../../../../utils/api";
import { useForm } from "react-hook-form";
import TextInput from "../../../../shared/components/TextInput";
import Select from "../../../../shared/components/Select";
import TextMaskInput from "../../../../shared/components/TextMaskInput";
import ErrorText from "../../../../shared/components/ErrorText";
import Checkbox from "../../../../shared/components/Checkbox";
import RadioSwitch from "../../../../shared/components/RadioSwitch";

const pwOptions = [
  {
    id: "current",
    name: "Do Nothing",
  },
  {
    id: "email_password",
    name: "Email Password",
  },
  {
    id: "set_password",
    name: "Set Password",
  },
];

const User = ({ onClose, user, edit }) => {
  const [loading, setLoading] = useState(false);
  const [testing, setTesting] = useState(false);
  const [pwType, setPwType] = useState(edit ? "current" : "email_password");
  const [error, setError] = useState("");
  // Form handling
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const pass = watch("password");
  const onChange = (val) => {
    setPwType(val.target.value);
  };
  const save = useCallback(
    async (data) => {
      setLoading(true);
      setError("");
      if (data.active === "true") data.active = true;
      if (data.active === "false") data.active = false;
      try {
        if (edit) {
          if (pwType === "email_password") data.email_password = true;
          const response = await API.put(`/users/${user.id}`, data);
          if (response.data.error) {
            setError(response.data.error);
          }
        } else {
          data.testing = testing;
          const response = await API.post("/users", data);
          if (response.data.error) {
            setError(response.data.error);
          }
        }
        setLoading(false);
        onClose();
      } catch (e) {
        if (e.response.data.error) {
          setError(e.response.data.error);
        }
        setLoading(false);
      }
    },
    [pwType]
  );
  return (
    <Modal open onClose={onClose}>
      <Wrapper onSubmit={handleSubmit(save)}>
        <Header>
          <SubTitle>MANAGE USERS</SubTitle>
          <Title>{edit ? "Edit User" : "Add User"}</Title>
        </Header>
        <Content>
          <Row>
            <TextInput
              id={"first"}
              title={"First Name"}
              type="text"
              {...register("first", { required: true })}
              disabled={loading}
              defaultValue={user.first}
              error={errors["first"]}
            />
            <TextInput
              id={"last"}
              title={"Last Name"}
              type="text"
              defaultValue={user.last}
              {...register("last", { required: true })}
              disabled={loading}
              error={errors["last"]}
            />
          </Row>
          <Row>
            <TextInput
              id={"email"}
              title={"Email"}
              type="text"
              defaultValue={user.email}
              {...register("email")}
              disabled={loading}
            />
          </Row>
          <Row>
            <TextMaskInput
              alwaysShowMask={false}
              maskPlaceholder={null}
              mask="(999)999-9999"
              id={"phone"}
              defaultValue={user.phone}
              title={"Phone"}
              type="text"
              {...register("phone")}
              disabled={loading}
            />
          </Row>
          <Row>
            <Select
              id={"type"}
              title={"Role"}
              type="text"
              {...register("role", { required: true })}
              disabled={loading}
              required
              defaultValue={user.role}
            >
              <option value={""}>Select User Role</option>
              <option value={"clinic_admin"}>Admin User</option>
              <option value={"clinician"}>Standard User</option>
            </Select>
            {!edit && (
              <Checkbox label checked={testing} onChange={() => setTesting(!testing)}>
                Add to Testing Clinician List
              </Checkbox>
            )}
            <input type="hidden" id="active" defaultValue={user.active} {...register("active")} />
          </Row>
          {!edit && (
            <>
              <Row>
                <StyledRadioSwitch
                  group={"password_type"}
                  value={pwType}
                  disabled={loading}
                  options={pwOptions.slice(1)}
                  onChange={onChange}
                />
              </Row>
              {pwType === "set_password" && (
                <Row>
                  <StyledTextInput
                    id={"password"}
                    title={"Set password"}
                    variant="secondary"
                    required
                    type="password"
                    disabled={loading}
                    {...register("password", { required: true })}
                    placeholder={"enter password"}
                  />
                  <StyledTextInput
                    id={"conf_pass"}
                    title={"Confirm password"}
                    disabled={loading}
                    variant="secondary"
                    type="password"
                    required
                    {...register("conf_pass", {
                      required: true,
                      validate: (value) => value === pass || "The passwords do not match",
                    })}
                    placeholder={"confirm password"}
                  />
                </Row>
              )}
            </>
          )}
          {edit && (
            <>
              <h4 style={{ margin: 0 }}>Password Reset Options</h4>
              <Row>
                <StyledRadioSwitch
                  group={"password_type"}
                  value={pwType}
                  disabled={loading}
                  options={pwOptions}
                  onChange={onChange}
                />
              </Row>
              {pwType === "set_password" && (
                <Row>
                  <StyledTextInput
                    id={"password"}
                    title={"Set password"}
                    variant="secondary"
                    required
                    disabled={loading}
                    {...register("password", { required: true })}
                    placeholder={"enter password"}
                  />
                  <StyledTextInput
                    id={"conf_pass"}
                    title={"Confirm password"}
                    disabled={loading}
                    variant="secondary"
                    required
                    {...register("conf_pass", {
                      required: true,
                      validate: (value) => value === pass || "The passwords do not match",
                    })}
                    placeholder={"confirm password"}
                  />
                </Row>
              )}
            </>
          )}
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button type="submit">{edit ? "Save Changes" : "Add User"}</Button>
        </Buttons>
        {error && <ErrorText error={error} />}
      </Wrapper>
    </Modal>
  );
};

export default User;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: Poppins, serif;
  font-size: 12px;
`;
const Row = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  align-items: center;
  > div {
    flex: 1;
  }
  label {
    font-size: 10px;
  }
`;

const Buttons = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Wrapper = styled.form`
  width: 360px;
`;
const StyledTextInput = styled(TextInput)`
  flex: 1;
`;
const StyledRadioSwitch = styled(RadioSwitch)`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  & > ul {
    background-color: #bac4cf;
    margin: 0px;
  }
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
