import Modal from "../../../shared/components/Modal";
import styled from "styled-components";
import { ReactComponent as HR5Img } from "../../../shared/assets/icons/hr5circle.svg";
import { ReactComponent as CircleImg } from "../../../shared/assets/icons/Action/Tick Small Circle.svg";
import Button from "../../../shared/components/Button";
import { Link, useNavigate } from "react-router-dom";
import FormatPhoneNumber from "../../../shared/utilities/FormatPhone";
import { FormatBirthdate } from "../../../shared/utilities/FormatBirthdate";

const OrderComplete = ({ onClose, order, updated }) => {
  const navigate = useNavigate();
  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <IconWrapper>
          <HR5 />
          <Circle>
            <CheckIcon />
          </Circle>
        </IconWrapper>
        <h2>Order {updated ? "Updated" : "Completed"}!</h2>
        {updated && <Message>Your order has been updated.</Message>}
        {!updated && (
          <Message>
            Your order has been completed and added to the queue of orders to be shipped.
          </Message>
        )}
        <Details>
          <Column>
            <Table>
              <Row>
                <CellLabel>Patient Name:</CellLabel>
                <CellValue>
                  {order.first} {order.last}
                </CellValue>
              </Row>
              {order.dob && (
                <Row>
                  <CellLabel>Date of Birth:</CellLabel>
                  <CellValue>{new Date(order.dob + "T00:00:00").toLocaleDateString()}</CellValue>
                </Row>
              )}
              <Row>
                <CellLabel>Patient ID:</CellLabel>
                <CellValue>{order.ext_patient_id}</CellValue>
              </Row>
              <Row>
                <CellLabel>Email Address:</CellLabel>
                <CellValue>{order.email}</CellValue>
              </Row>
              <Row>
                <CellLabel>Phone Number:</CellLabel>
                <CellValue>{FormatPhoneNumber(order.phone)}</CellValue>
              </Row>
              <Row>
                <CellLabel>Test location:</CellLabel>
                <CellValue>{order.test_location === "clinic" ? "Clinic" : "At Home"}</CellValue>
              </Row>
              <Row>
                <CellLabel>Type of Test:</CellLabel>
                <CellValue>{order.test_type.toUpperCase()}</CellValue>
              </Row>
              {order.collected && (
                <Row>
                  <CellLabel>Collected:</CellLabel>
                  <CellValue>{FormatBirthdate(order.collected)}</CellValue>
                </Row>
              )}
            </Table>
          </Column>
        </Details>
        <Buttons>
          <Button variant="secondary" onClick={onClose}>
            Order Another Test
          </Button>
          <Button variant="primary" onClick={() => navigate("/dashboard")}>
            Return to Dashboard
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default OrderComplete;

const Table = styled.div`
  display: table;
  font-family: Poppins, serif;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
`;

const Row = styled.div`
  display: table-row;
`;

const CellLabel = styled.div`
  display: table-cell;
  padding-right: 20px;
`;
const CellValue = styled.div`
  display: table-cell;
  font-weight: 600;
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  button {
    justify-content: center;
    width: 100%;
  }
  width: 100%;
`;

const Column = styled.div``;

const Details = styled.div`
  width: 100%;
  background: #f8f9fb;
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
  font-size: 16px;
`;

const Message = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #0b0b0b;
`;

const IconWrapper = styled.div`
  position: relative;
`;

const HR5 = styled(HR5Img)`
  height: 100px;
  width: 100px;
`;

const Circle = styled.div`
  position: absolute;
  background: linear-gradient(180deg, #12e24c 0%, #07ae4d 89.58%);
  border: 5px solid #ffffff;
  height: 28px;
  width: 28px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  align-items: center;
  margin-top: 30px;
  gap: 20px;
`;

const CheckIcon = styled(CircleImg)`
  height: 25px;
  width: 25px;
  & path.svgcircle {
    fill: transparent;
  }
  & path.svgcheck {
    fill: white;
  }
`;
