import styled, { keyframes } from "styled-components";

const Loader = ({ size = 20, color = "#FFF" }) => (
  <LoaderWrap size={size} color={color} />
);

const Rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Show = keyframes`
  100% {
    opacity: 1;
  }
`;

export const LoaderWrap = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: ${(props) => props.size / 10}px solid ${(props) => props.color};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  opacity: 0;
  animation: ${Show} 1s cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards,
    ${Rotation} 1s linear infinite;
`;

export default Loader;
