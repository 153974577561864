import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import TextInput from "../../../shared/components/TextInput";
import { useState } from "react";

const CancelOrder = ({ onCancel, onLater, onEdit }) => {
  const [reason, setReason] = useState("");
  const _onCancel = (msg) => {
    onCancel(msg);
  };
  return (
    <Modal open onClose={onLater}>
      <Wrapper>
        <Header>
          <WarningImg />
          <h3>Warning!</h3>
        </Header>
        <Content>Are you sure you wish to cancel this pending order?</Content>
        <div style={{ fontSize: "11px" }}>
          WARNING: The barcode cannot be reused once this order is canceled. To correct any patient
          information errors, please choose to Edit this order instead. You may continue with
          canceling this order if the sample testing is no longer needed or a new order is
          necessary. Once the test order is canceled, DO NOT mail the sample to the lab. The sample
          will not be processed and will be immediately discarded at the lab once it is received.
        </div>
        <Buttons>
          <Button variant="primary" onClick={() => onEdit()}>
            Edit Order
          </Button>
        </Buttons>
        <TextInput
          id={"reason"}
          title={"reason"}
          placeholder={"Type your cancellation reason"}
          onChange={(e) => setReason(e.target.value)}
          textarea
        />
        <Buttons>
          <Button variant="secondary" onClick={onLater}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => _onCancel(reason)}
            disabled={reason.length === 0}
          >
            Cancel Order
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

CancelOrder.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 360px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

export default CancelOrder;
