import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled from "styled-components";
import PropTypes from "prop-types";

const CardExpiring = ({ onUpdate, onLater }) => {
  return (
    <Modal open onClose={onLater}>
      <Wrapper>
        <Header>
          <WarningImg />
          <h3>Warning!</h3>
        </Header>
        <Content>
          Your credit card is expiring soon. Please update your Billing
          Information.
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onLater}>
            Update Later
          </Button>
          <Button variant="primary" onClick={onUpdate}>
            Update Now
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

CardExpiring.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 360px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

export default CardExpiring;
