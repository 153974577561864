import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../shared/components/Checkbox";
import Api from "../../../utils/api";
import API from "../../../utils/api";
import { useNavigate } from "react-router";
import ErrorText from "../../../shared/components/ErrorText";
import { set } from "react-hook-form";
import OrderSupplies from "../OrderSupplies";

const PrebuyOffer = ({
  initialScreen = "offer",
  offerId,
  onUpdate,
  onLater,
  onComplete,
  onDismiss,
  onSupplies,
  maxPurchase = 0,
}) => {
  const [screen, setScreen] = useState(initialScreen);
  const [terms, setTerms] = useState({ auth: false, terms: false });
  const [badBilling, setBadBilling] = useState(false);
  const [purchasing, setPurchasing] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Get billing details
  const getBillingDetails = async () => {
    const clinicResponse = await Api.get("/profile/clinic");
    if (!clinicResponse.data.verified_payment) {
      setBadBilling(true);
    } else {
      const response = await API.get("/billing/details");
      if (typeof response.data == "object") {
        // Check if the expiry is this month
        const today = new Date();
        if (response.data.expiry === `${today.getMonth() + 1}/${today.getFullYear()}`) {
          setBadBilling(true);
        }
      }
    }
  };

  const pageNav = (path) => {
    navigate(path);
    onLater();
  };

  // Get billing details on mount
  useEffect(() => {
    getBillingDetails();
  }, []);

  const dismiss = () => {
    onDismiss();
    onLater();
  };

  const buyOffer = useCallback(async () => {
    if (!purchasing) {
      setPurchasing(true);
    }
    try {
      const response = await API.post(`/offers/${offerId}/claim`, {});
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setScreen("success");
      }
    } catch (e) {
      if (e.response.data.error) {
        setError(e.response.data.error);
      } else {
        setError("error");
      }
    }
    setPurchasing(false);
  }, [purchasing]);

  return (
    <Modal open onClose={onLater}>
      {orderModal && <OrderSupplies onClose={() => setOrderModal(false)} />}
      <Wrapper>
        {screen === "offer" && (
          <>
            <Header>
              <h3 style={{ color: "rgb(70,114,196)" }}>End of Summer Special!</h3>
            </Header>
            <Content>
              <div style={{ color: "gray", fontSize: "14px" }}>
                Prepare your office for a productive fall with our HR5 End of Summer Special - the
                perfect opportunity to integrate evidence-based periodontal care into your practice!
              </div>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "rgb(70,114,196)" }}>
                Buy <TextHighlight>5</TextHighlight> test credits, GET{" "}
                <TextHighlight>1</TextHighlight> FREE!
              </div>
              <div style={{ color: "gray" }}>That is only $62.50 per test!</div>
              <div style={{ color: "gray", fontSize: "15px" }}>
                Offer ends August 31<sup>st</sup>
              </div>
            </Content>
            <Buttons>
              <Button
                variant="green"
                onClick={() => setScreen(!badBilling ? "checkout" : "payment_info")}
              >
                I'm Interested! Take me to checkout
              </Button>
              <Button variant="secondary" onClick={dismiss}>
                Not at this time
              </Button>
            </Buttons>
          </>
        )}
        {screen === "full_offer" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Wait!</h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                We’re offering a limited-time special pre-buy offer to help enhance your practice
                this summer through evidence-based periodontal care!
              </div>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "#114377" }}>
                Buy <TextHighlight>5</TextHighlight> test credits, get{" "}
                <TextHighlight>1</TextHighlight> free!
              </div>
              <div style={{ color: "gray" }}>Offer ends August 31st</div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={() => setScreen("checkout")}>
                I'm Interested! Take me to checkout
              </Button>
              <Button variant="secondary" onClick={onLater}>
                I’ll pass and purchase the full pre-buy
              </Button>
            </Buttons>
          </>
        )}
        {screen === "success" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Your payment has been received</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "#114377" }}>
                6 test credits have been added to your account
              </div>
              <div style={{ fontSize: "12px", color: "#114377" }}>
                Stay in the know about future offers and announcements by subscribing to our
                bi-weekly newsletter{" "}
                <a
                  href="https://share.hsforms.com/1jYIRHDAKR6CfqPdF7DDEmQca1tg"
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  here
                </a>
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={onComplete}>
                Ok
              </Button>
              <div
                style={{
                  fontSize: "12px",
                  color: "#114377",
                  textAlign: "center",
                }}
              >
                Need Supplies? Order them now
              </div>
              <Button variant="secondary" onClick={() => setOrderModal(true)}>
                Order Supplies
              </Button>
            </Buttons>
          </>
        )}
        {screen === "redeemed" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Whoa!</h3>
            </Header>
            <Content>
              <div style={{ fontSize: "14px", fontWeight: "bold", color: "#114377" }}>
                It looks like you’ve already purchased this special pre-buy offer – go you!
                <br />
              </div>
              <div style={{ fontSize: "12px", color: "#114377" }}>
                Only {maxPurchase} purchase(s) per clinic allowed for this limited-time offer.
                <br />
                <br />
                <br /> Stay in the know about future offers and announcements by subscribing to our
                bi-weekly newsletter{" "}
                <a
                  href="https://share.hsforms.com/1jYIRHDAKR6CfqPdF7DDEmQca1tg"
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                >
                  here
                </a>
                !
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={onComplete}>
                Okay
              </Button>
            </Buttons>
          </>
        )}
        {screen === "checkout" && (
          <>
            <Header>
              <h3 style={{ color: "rgb(70,114,196)" }}>
                Special Offer Purchase: Buy <TextHighlight>5</TextHighlight> test credits, get{" "}
                <TextHighlight>1</TextHighlight> free!
              </h3>
            </Header>
            <Content>
              <div style={{ color: "gray" }}>
                You have opted to purchase 5 test credits at $75 each and receive 1 additional
                credit free of charge. By accepting this special offer, you must agree to the terms
                and conditions outlined in the offer details. Once you complete the purchase, 6 test
                credits will automatically be added to your account.
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#114377",
                  textDecoration: "underline",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                TOTAL DUE: <span style={{ color: "green" }}>$375</span>
              </div>
              <div
                style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Checkbox
                  label
                  checked={terms.auth}
                  onClick={() => setTerms({ ...terms, auth: !terms.auth })}
                >
                  I certify that I am authorized to make this purchase on behalf of my clinic or
                  practice.
                </Checkbox>
                <Checkbox
                  label
                  checked={terms.terms}
                  onClick={() => setTerms({ ...terms, terms: !terms.terms })}
                >
                  I have read and understand the terms and conditions. By selecting{" "}
                  <i>Confirm Purchase</i>, I agree that Direct Diagnostics may collect the amount
                  due upon purchase.
                </Checkbox>
              </div>
            </Content>
            <Buttons>
              {error && (
                <ErrorText error={error !== "error" ? error : "Unknown Error, Contact Support"} />
              )}
              <Button
                variant="green"
                onClick={() => buyOffer()}
                disabled={!terms.auth || !terms.terms || purchasing}
                loading={purchasing}
              >
                Confirm Purchase
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
            <Content>
              <div style={{ textAlign: "left", fontSize: "11px", color: "gray" }}>
                <strong>Terms and conditions</strong>
                <br />
                <br />
                <i>
                  Some exclusions apply. There is a limit of 1 special offer purchase for the
                  duration of this offer. This offer cannot be combined with any other sales or
                  special offers. Full payment is due upon purchase. Purchase of this offer is non-
                  refundable and non-renewable. This offer only applies to in-clinic tests and
                  at-home tests are excluded from this offer. Test credits are purchased at standard
                  in-clinic test pricing of $75 per result. Test credits purchased under this offer
                  will not expire and will automatically be used when submitting test orders until
                  no further credits remain. If a sample is rejected, the test credit used will
                  automatically be added back to your credit pool.
                </i>
              </div>
            </Content>
          </>
        )}
        {screen === "payment_info" && (
          <>
            <Header>
              <h3 style={{ color: "#114377" }}>Payment Info Reminder</h3>
            </Header>
            <Content>
              <div style={{ color: "#114377" }}>
                Your payment information is either missing or needs to be updated.
              </div>{" "}
              <div style={{ color: "#114377" }}>
                Please update your payment information to proceed with this order.
              </div>
            </Content>
            <Buttons>
              <Button variant="green" onClick={() => pageNav("/settings/billing/edit")}>
                Update Payment Info
              </Button>
              <Button variant="secondary" onClick={onLater}>
                Cancel
              </Button>
            </Buttons>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

PrebuyOffer.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: 30px;
  }
`;

const TextHighlight = styled.span`
  font-size: 18px;
  color: green;
  text-decoration: underline;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

export default PrebuyOffer;
