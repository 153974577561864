import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import styled from "styled-components";
import { TitleProvider } from "../../contexts/TitleContext";
import { useContext, useEffect } from "react";
import { MainStore } from "../../stores/main";

const AppWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Auth = () => {
  const { sidebar } = useContext(MainStore);

  return (
    <AppWrapper>
      {!sidebar && <Sidebar active />}
      <TitleProvider>
        <Page>
          <Outlet />
        </Page>
      </TitleProvider>
    </AppWrapper>
  );
};

export default Auth;
