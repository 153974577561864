import styled, { css } from "styled-components";
import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { useEffect, useState } from "react";
import API from "../../../utils/api";
import TextInput from "../../../shared/components/TextInput";
import { ReactComponent as SentImg } from "../../../shared/assets/icons/message-sent.svg";
import { useForm, Controller } from "react-hook-form";
import TextAreaInput from "../../../shared/components/TextAreaInput";
import Select from "../../../shared/components/Select";
import ReactSelect, { components } from "react-select";
import { ReactComponent as HR5Img } from "../../../shared/assets/icons/hr5circle.svg";
import { ReactComponent as FamImg } from "../../../shared/assets/icons/famcircle.svg";
import { ReactComponent as CovidImg } from "../../../shared/assets/icons/covidcircle.svg";

const Option = (props) => (
  <components.Option {...props}>
    <div style={{ fontSize: "12px" }}>
      <strong>{props.data.label}</strong>
    </div>
    <div style={{ fontSize: "10px" }}>{props.data.description}</div>
  </components.Option>
);

const fullorderTypes = [
  {
    label: "Full Supply Order",
    description: "(40 test kits, 10 return mailers, 10 ice packs, 50 patient flyers)",
    value: "Full Supply Order",
    testKits: 40,
    returnMailers: 10,
    icePacks: 10,
    patientFlyers: 50,
  },
  {
    label: "Half Supply Order",
    description: "(20 test kits, 5 return mailers, 5 ice packs, 50 patient flyers)",
    value: "Half Supply Order",
    testKits: 20,
    returnMailers: 5,
    icePacks: 5,
    patientFlyers: 50,
  },
  {
    label: "Shipping Supplies",
    description: "(10 return mailers, 10 ice packs)",
    value: "Shipping Supplies",
    testKits: 0,
    returnMailers: 10,
    icePacks: 10,
    patientFlyers: 0,
  },
  {
    label: "Custom",
    description: "",
    value: "Custom",
  },
];

const altOrderTypes = [
  {
    label: "Full Supply Order",
    description: "(40 test kits, 10 return mailers, 50 patient flyers)",
    value: "Full Supply Order",
    testKits: 40,
    returnMailers: 10,
    patientFlyers: 50,
  },
  {
    label: "Half Supply Order",
    description: "(20 test kits, 5 return mailers, 50 patient flyers)",
    value: "Half Supply Order",
    testKits: 20,
    returnMailers: 5,
    patientFlyers: 50,
  },
  {
    label: "Shipping Supplies",
    description: "(10 return mailers)",
    value: "Shipping Supplies",
    returnMailers: 10,
    icePacks: 10,
    patientFlyers: 0,
  },
  {
    label: "Custom",
    description: "",
    value: "Custom",
  },
];

const OrderSupplies = ({ onClose, supplies }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clinic, setClinic] = useState({});
  const [sent, setSent] = useState(false);

  const curDate = new Date();
  let orderTypes = altOrderTypes;
  let enableIce = false;

  // 03/25 - 10/23, enable ice packs
  if (curDate >= new Date("03-25-2024") && curDate <= new Date("10-31-2024")) {
    orderTypes = fullorderTypes;
    enableIce = true;
  }

  // Form handling
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  // Send Message
  const sendMessage = async (data) => {
    setLoading(true);
    setError("");
    try {
      const request = {
        subject: "Supply Order",
        message: "Order type: " + data.order_type,
      };
      if (data.order_type === "Custom") {
        request.message = request.message + "\n\n";
        request.message = request.message + "Test Kits: " + data.test_kits + "\n";
        request.message = request.message + "Return Mailers: " + data.return_mailers + "\n";
        if (enableIce) {
          request.message = request.message + "Ice packs: " + data.ice_packs + "\n";
        }
        request.message = request.message + "Patient Brochures: " + data.patient_brochures;
      } else {
        const order = orderTypes.filter((o) => o.value === data.order_type)[0];
        request.message = request.message + "\n\n";
        request.message = request.message + "Test Kits: " + order.testKits + "\n";
        request.message = request.message + "Return Mailers: " + order.returnMailers + "\n";
        if (enableIce) {
          request.message = request.message + "Ice packs: " + order.icePacks + "\n";
        }
        request.message = request.message + "Patient Brochures: " + order.patientFlyers;
      }
      const response = await API.post("/contact_support", request);
      if (response.data.error) {
        setError(response.data.error);
      } else {
        setSent(true);
      }
    } catch (e) {}
    setLoading(false);
  };

  const order_type = watch("order_type");

  return (
    <Modal open onClose={onClose}>
      <Wrapper onSubmit={handleSubmit(sendMessage)}>
        {sent && (
          <div style={{ textAlign: "center" }}>
            <IconWrapper>
              <SentImg />
            </IconWrapper>
            <h2>Request Sent!</h2>
            <Message>
              Your supply order has been submitted. Your order will be processed and shipped within
              2-3 business days.
            </Message>
            <Button variant="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        )}
        {!sent && (
          <>
            <Header>
              <Title>Supply Order</Title>
            </Header>
            <div>
              <Label required>Order Type</Label>
              <Controller
                rules={{
                  required: true,
                }}
                name="order_type"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <ReactSelect
                      style={{ flex: 1 }}
                      disabled={loading}
                      required
                      onBlur={onBlur}
                      error={errors["order_type"]}
                      value={orderTypes.filter((option) => value === option.value)}
                      onChange={(v) => onChange(v.value)}
                      defaultValue={null}
                      components={{
                        Option: Option,
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors["order_type"] ? "1px solid #FF0000" : "1px solid #BAC4CF",
                        }),
                      }}
                      options={orderTypes}
                    >
                      <option value="custom">Custom</option>
                      <option value="testing">
                        Full Supply Order
                        <br />
                        (40 test kits, 10 return mailers, 50 patient flyers)
                      </option>
                      <option value="shipping">Shipping Supplies</option>
                      <option value="both">Both Supplies</option>
                    </ReactSelect>
                  );
                }}
              />
              {order_type === "Custom" && (
                <>
                  <Disclaimer style={{ marginTop: "20px" }}>
                    Custom order quantities are limited and subject to change per inventory
                    availability. Order confirmations will be required for orders over a certain
                    amount.
                  </Disclaimer>
                  <table style={{ marginTop: "20px", fontSize: "12px" }}>
                    <thead style={{ fontWeight: "bold" }}>
                      <tr>
                        <td style={{ width: "100%" }}>Item</td>
                        <td>Quantity</td>
                      </tr>
                    </thead>
                    <tr>
                      <td>Test Kits</td>
                      <td>
                        <select {...register("test_kits")}>
                          <option>0</option>
                          <option>20</option>
                          <option>40</option>
                          <option>60</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ paddingTop: "10px" }}>
                        <u>Shipping Supplies</u>
                      </td>
                    </tr>
                    <tr>
                      <td>Return mailers</td>
                      <td>
                        <select {...register("return_mailers")}>
                          <option>0</option>
                          <option>5</option>
                          <option>10</option>
                          <option>15</option>
                        </select>
                      </td>
                    </tr>
                    {enableIce && (
                      <tr>
                        <td>
                          Ice packs
                          <br />
                          <span style={{ fontSize: "9px" }}>Not needed November through March</span>
                        </td>
                        <td>
                          <select {...register("ice_packs")}>
                            <option>0</option>
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                          </select>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={2} style={{ paddingTop: "10px" }}>
                        <u>Marketing Materials</u>
                      </td>
                    </tr>
                    <tr>
                      <td>Patient brochure rack cards</td>
                      <td>
                        <select {...register("patient_brochures")}>
                          <option>0</option>
                          <option>50</option>
                          <option>100</option>
                          <option>150</option>
                          <option>200</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </>
              )}
            </div>
            <Content>
              <Disclaimer>
                All testing and shipping supplies are provided free of charge.
              </Disclaimer>
              <Disclaimer>Ice packs not needed November through March.</Disclaimer>
              <Disclaimer>
                For order quantities that do not match the preset quantities available, please
                contact{" "}
                <a href="mailto:support@directdiagnostics.com">support@directdiagnostics.com</a>.
              </Disclaimer>
              <Buttons>
                <Button variant="secondary" onClick={onClose} disabled={loading}>
                  Discard
                </Button>
                <Button variant="primary" type="submit" disabled={loading} loading={loading}>
                  Request Supplies
                </Button>
              </Buttons>
              {error && <Error>{error}</Error>}
            </Content>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default OrderSupplies;

const Disclaimer = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  color: #858585;
`;

const IconWrapper = styled.div`
  position: relative;
`;
const Message = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #0b0b0b;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const SalesRep = styled.div``;
const To = styled.div`
  display: flex;
  gap: 20px;
  font-family: Poppins, serif;
  font-style: normal;
  font-size: 12px;
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;
const StyledSelect = styled(Select)`
  min-height: 30px;
`;
const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 330px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
