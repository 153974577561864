import { Route, Routes, Navigate } from "react-router-dom";
import PreAuth from "../../pages/PreAuth";
import Login from "../../pages/PreAuth/Login";
import Forgot from "../../pages/PreAuth/Forgot";
import Auth from "../../pages/Auth";
import HeaderLayout from "../HeaderLayout";
import Dashboard from "../../pages/Auth/Dashboard";
import Settings from "../../pages/Auth/Settings";
import Users from "../../pages/Auth/Settings/Users";
import Clinicians from "../../pages/Auth/Settings/Clinicians";
import React, { useCallback, useContext, useRef, useState } from "react";
import { MainStore } from "../../stores/main";
import API from "../../utils/api";
import ViewOrders from "../../pages/Auth/Orders/View";
import NewOrder from "../../pages/Auth/Orders/New";
import ViewResults from "../../pages/Auth/Results/View";
import BillingInformation from "../../pages/Auth/Settings/BillingInformation";
import BillingHistory from "../../pages/Auth/Settings/BillingHistory";
import ForgotProcess from "../../pages/PreAuth/ForgotProcess";
import Results from "../../pages/Auth/Results/Results";
import PricingPrebuy from "../../pages/Auth/Settings/PricingPrebuy";
import ViewPatients from "../../pages/Auth/Patients/ViewPatients";
import Patient from "../../pages/Auth/Patients/Patient";
import { useLocation } from "react-router";

const App = () => {
  const mainStoreContext = useContext(MainStore);
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  // Fetch the current session
  const fetchRefresh = useCallback(async () => {
    try {
      const response = await API.get("/session");
      if (!mainStoreContext.isAuthenticated && response.data.role !== "admin") {
        mainStoreContext.handleLogin(
          response.data.role,
          response.data.clinic_id,
          response.data.sso === true
        );
        if (!response.data.sso) {
          window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: window.config.intercomAppId,
            user_id: response.data.user_id,
            user_hash: response.data.hmac,
          });
        }
      }
      if (location.pathname === "/" && !response.data.sso && response.data.role !== "admin") {
        window.Intercom("shutdown");
        mainStoreContext.handleLogout();
      }
    } catch (error) {
      window.Intercom("shutdown");
      if (mainStoreContext.isAuthenticated) {
        window.location.href = "/";
      }
    }
    if (!loaded) setLoaded(true);
  }, [location, mainStoreContext]);

  // Refresh the session every 30 seconds
  const refreshTimer = useRef();
  React.useEffect(() => {
    if (mainStoreContext.isAuthenticated) {
      clearInterval(refreshTimer.current);
      refreshTimer.current = setInterval(() => {
        fetchRefresh().then();
      }, 30000);
    } else {
      clearInterval(refreshTimer.current);
      fetchRefresh().then();
    }
    return () => clearInterval(refreshTimer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainStoreContext.isAuthenticated, fetchRefresh]);

  if (loaded) {
    return (
      <Routes>
        <Route element={<PreAuth />}>
          <Route index element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="forgot/process/:token" element={<ForgotProcess />} />
        </Route>
        {mainStoreContext.isAuthenticated && (
          <Route element={<Auth />}>
            <Route element={<HeaderLayout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="orders/view" element={<ViewOrders />} />
              <Route path="orders/new" element={<NewOrder />} />
              <Route path="orders/:id" element={<NewOrder />} />
              <Route path="patients/view" element={<ViewPatients />} />
              <Route path="patients/view/:id" element={<Patient />} />
              <Route path="results/view" element={<ViewResults />} />
              <Route path="results/view/:id" element={<Results />} />
              <Route path="settings" element={<Settings />} />
              {mainStoreContext.role === "clinic_admin" && (
                <>
                  <Route path="settings/users" element={<Users />} />
                  <Route path="settings/clinicians" element={<Clinicians />} />
                  <Route path="settings/billing/edit" element={<BillingInformation />} />
                  <Route path="settings/billing/history" element={<BillingHistory />} />
                  <Route path="settings/billing/pricing" element={<PricingPrebuy />} />
                </>
              )}
            </Route>
            <Route path="notifications" element={<Dashboard />} />
          </Route>
        )}
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    );
  } else {
    return null;
  }
};

export default App;
