import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/triangle.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useCallback, useRef, useState } from "react";
import TextInput from "../../../shared/components/TextInput";
import Api from "../../../utils/api";
import TextMaskInput from "../../../shared/components/TextMaskInput";
import { Row } from "../../../shared/styled/Flexbox";
import Checkbox from "../../../shared/components/Checkbox";

const SendResult = ({ orderId, onClose, phone, email, mode }) => {
  const [loading, setLoading] = useState(false);
  const [updatePatient, setUpdatePatient] = useState(false);
  const phoneRef = useRef();
  const emailRef = useRef();

  let title;
  let inputTitle;
  if (mode === "email") {
    inputTitle = "Patient Email";
    title = "Send Result via Email";
  } else if (mode === "sms") {
    inputTitle = "Patient Phone";
    title = "Send Result via Text";
  } else if (mode === "pdf") {
    inputTitle = "Email";
    title = "Send Result PDF via Email";
  } else if (mode === "sms_email") {
    inputTitle = "Email";
    title = "Send Result";
  }

  const sendResult = useCallback(async () => {
    setLoading(true);
    try {
      const sendObj = {
        updatePatient: updatePatient,
        type: mode,
        id: orderId,
      };
      if (mode.includes("sms")) {
        sendObj.phone = phoneRef.current.value;
      }
      if (mode.includes("email") || mode === "pdf") {
        sendObj.email = emailRef.current.value;
      }
      await Api.post(`/results/send`, sendObj);
      onClose();
    } catch (e) {}
    setLoading(false);
  }, [mode, orderId, updatePatient]);

  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <h3>{title}</h3>
        </Header>
        <Content>
          {mode.includes("sms") && (
            <TextMaskInput
              title={title}
              min={9}
              disabled={loading || !updatePatient}
              mask={"(999) 999-9999"}
              maskPlaceholder={null}
              ref={phoneRef}
              id={"data"}
              defaultValue={phone}
            />
          )}
          {(mode.includes("email") || mode === "pdf") && (
            <TextInput
              title={inputTitle}
              ref={emailRef}
              id={"data"}
              defaultValue={email}
              disabled={(loading || !updatePatient) && mode !== "pdf"}
            />
          )}
          {(mode.includes("sms") || mode.includes("email")) && (
            <Row style={{ marginTop: "10px" }}>
              <Checkbox
                label
                checked={updatePatient}
                onChange={() => setUpdatePatient(!updatePatient)}
              >
                Update Patient Record
              </Checkbox>
            </Row>
          )}
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={sendResult} disabled={loading} loading={loading}>
            Send
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 460px;
  min-width: 300px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

export default SendResult;
