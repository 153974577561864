import styled, { css } from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import { rgba } from "polished";
import { ReactComponent as SearchImg } from "../../../../shared/assets/icons/Other/Search.svg";
import { ReactComponent as EditImg } from "../../../../shared/assets/icons/Action/Edit.svg";
import { ReactComponent as AddImg } from "../../../../shared/assets/icons/Action/+.svg";
import { ReactComponent as ToggleLeftImg } from "../../../../shared/assets/icons/Action/Toggle-Left.svg";
import { ReactComponent as ToggleRightImg } from "../../../../shared/assets/icons/Action/Toggle-Right.svg";
import TextInput from "../../../../shared/components/TextInput";
import { ActionButton, ActionItem } from "../../../../shared/components/ActionButton";
import Button from "../../../../shared/components/Button";
import Checkbox from "../../../../shared/components/Checkbox";
import API from "../../../../utils/api";
import Clinician from "../../../../components/Modals/Settings/Clinician";

const defaultSort = { type: "created", dir: "asc" };

const Clinicians = () => {
  const { setTitle } = useTitle();
  const [selected, setSelected] = useState({});
  const [sort, setSort] = useState(defaultSort);
  const [clinicians, setClinicians] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [modal, setModal] = useState("");
  const searchRef = useRef();

  const fetchClinicians = useCallback(async () => {
    let path = `/clinicians?mode=${sort.type}&dir=${sort.dir}`;
    if (disabled) path = path + "&disabled=true";
    if (searchRef.current.value !== "") path = path + "&search=" + searchRef.current.value;
    try {
      const response = await API.get(path);
      setClinicians(response.data);
    } catch (e) {}
  }, [disabled, sort]);

  const selectRow = (e) => {
    setSelected(e);
  };

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort = { ...defaultSort };
        }
      }
      setSort(newSort);
    },
    [sort]
  );

  const closeRefresh = () => {
    setSelected({});
    setModal("");
    fetchClinicians();
  };

  const toggleClinician = async (clinician) => {
    try {
      const data = { ...clinician, active: !clinician.active };
      await API.put(`/clinicians/${clinician.id}`, data);
    } catch (e) {}
    setSelected({});
    fetchClinicians();
  };

  const doSearch = (e) => {
    if (e.keyCode === 13) {
      fetchClinicians();
    }
  };

  // Set page title
  useEffect(() => {
    setTitle({
      name: "Clinicians",
      back: "settings",
      children: [
        <Button onClick={() => setModal("add_new")}>
          <AddImg />
          Add New Clinician
        </Button>,
      ],
    });
  }, [setTitle]);

  // Fetch clinicians on page load
  useEffect(() => {
    fetchClinicians();
  }, [disabled, sort]);

  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          gap: "10px",
          "font-family": "Poppins, serif",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextInput
          id={"search"}
          icon={<SearchImg />}
          placeholder={"Search"}
          onKeyUp={doSearch}
          iconClick={() => fetchClinicians()}
          ref={searchRef}
        />
        <Checkbox label checked={disabled} onChange={() => setDisabled(!disabled)}>
          Show Disabled
        </Checkbox>
      </div>
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th aria-sort={getSorted("first")} onClick={() => toggleSort("first")}>
              First Name
            </th>
            <th aria-sort={getSorted("last")} onClick={() => toggleSort("last")}>
              Last Name
            </th>
            <th aria-sort={getSorted("email")} onClick={() => toggleSort("email")}>
              Email
            </th>
            <th aria-sort={getSorted("added")} onClick={() => toggleSort("added")}>
              Date Added
            </th>
            <th aria-sort={getSorted("type")} onClick={() => toggleSort("type")}>
              Type
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {clinicians.map((user) => (
            <User key={user.id} selected={selected.id === user.id} disabled={!user.active}>
              <td />
              <td>{user.first}</td>
              <td>{user.last}</td>
              <td>{user.email}</td>
              <td>{new Date(user.created).toDateString()}</td>
              <td>{user.type === "primary" ? "Primary Clinician" : "Testing Clinician"}</td>
              <td>
                <ActionButton
                  open={selected.id === user.id && modal === ""}
                  title="More"
                  id={user.id}
                  onClose={() => setSelected({})}
                  onClick={() => selectRow(user)}
                >
                  <ActionItem color="#114377" onClick={() => setModal("edit")}>
                    <EditImg />
                    Edit User Info
                  </ActionItem>
                  <ActionItem
                    color={user.active ? "#F13737" : "#219653"}
                    onClick={() => toggleClinician(user)}
                  >
                    {user.active ? <ToggleLeftImg /> : <ToggleRightImg />}
                    {user.active ? "Disable Clinician" : "Enable Clinician"}
                  </ActionItem>
                </ActionButton>
              </td>
              <td />
            </User>
          ))}
        </tbody>
      </StyledTable>
      {modal === "add_new" && <Clinician onClose={closeRefresh} clinician={{}} />}
      {modal === "edit" && <Clinician onClose={closeRefresh} edit clinician={selected} />}
    </Wrapper>
  );
};

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${ActionButton} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  thead tr th {
    padding-bottom: 10px;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    border-collapse: separate;
    border-spacing: 5px 5px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    &:after {
      content: "";
      display: inline-flex;
      margin-left: 5px;
      width: 20px;
      font-size: 8px;
    }
    &[aria-sort="ascending"]:after {
      content: " ▲";
    }
    &[aria-sort="descending"]:after {
      content: " ▼";
    }
  }
  th:first-child,
  th:last-child,
  td:first-child,
  td:last-child {
    border: none;
    width: 10px;
    padding-right: 0;
  }
  tbody tr:hover {
    background-color: #eef2f6;
    td:first-child {
      border-radius: 20px 0 0 20px;
    }
    td:last-child {
      border-radius: 0 20px 20px 0;
    }
    ${ActionButton} {
      opacity: 1;
    }
  }
  td {
    border-collapse: collapse;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  gap: 20px;
`;

const User = styled.tr`
  ${({ selected }) =>
    selected &&
    css`
      background-color: #eef2f6;
      td:first-child {
        border-radius: 20px 0 0 20px;
      }
      td:last-child {
        border-radius: 0 20px 20px 0;
      }
      ${ActionButton} {
        opacity: 1;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${(props) => props.theme.colors.grey.grey3};
    `}
`;

export default Clinicians;
