import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import styled from "styled-components";
import PropTypes from "prop-types";
import TextInput from "../../../shared/components/TextInput";
import { useForm } from "react-hook-form";
import { useState } from "react";
import API from "../../../utils/api";

const VerifyACH = ({ onUpdate, onLater }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    setError("");
    try {
      const response = await API.post("/billing/details/verify", {
        dep1: Number(data.dep1),
        dep2: Number(data.dep2),
      });
      if (response.data.error) {
        setError(response.data.error);
      } else {
        onUpdate();
      }
    } catch (e) {}
    setLoading(false);
  };

  return (
    <Modal open onClose={onLater}>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h3>ACH Payment</h3>
        </Header>
        <Content>
          <span>Please verify 2 micro deposits.</span>
          <TextInput
            id={"dep1"}
            max={99}
            min={1}
            prefix="0."
            placeholder={"enter amount"}
            title={"Micro deposit 1"}
            type="number"
            {...register("dep1", { required: true })}
            disabled={loading}
          />
          <TextInput
            id={"dep2"}
            type="number"
            maxLength={2}
            min={1}
            max={99}
            prefix="0."
            placeholder={"enter amount"}
            title={"Micro deposit 2"}
            {...register("dep2", { required: true })}
            disabled={loading}
          />
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onLater} disabled={loading}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading} loading={loading}>
            Confirm
          </Button>
        </Buttons>
        {error && <Error>{error}</Error>}
      </Wrapper>
    </Modal>
  );
};

VerifyACH.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 360px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #f13737;
    height: 28px;
    width: 28px;
  }
`;

export default VerifyACH;
