import styled, { css } from "styled-components";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import { rgba } from "polished";
import Button from "../../../../shared/components/Button";
import { ReactComponent as DeleteImg } from "../../../../shared/assets/icons/Octagon/X.svg";
import { ReactComponent as InfoImg } from "../../../../shared/assets/icons/Circle/Info filled.svg";
import { ReactComponent as PackageImg } from "../../../../shared/assets/icons/Shop/Package.svg";
import { ReactComponent as EditImg } from "../../../../shared/assets/icons/Action/Edit.svg";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as WarningImg } from "../../../../shared/assets/icons/Circle/exp.svg";
import { ReactComponent as HomeImg } from "../../../../shared/assets/icons/Other/Home alt 2.svg";

import API, { apiPath } from "../../../../utils/api";
import Select from "../../../../shared/components/Select";
import TextInput, {
  Wrapper as TextInputWrapper,
} from "../../../../shared/components/TextInput/TextInput";
import DateInput from "../../../../shared/components/DateInput";
import { ActionButton, ActionItem } from "../../../../shared/components/ActionButton";
import Api from "../../../../utils/api";
import Modal from "../../../../shared/components/Modal";
import ReactTooltip from "react-tooltip";
import Shipping from "../../../../components/Modals/Shipping";
import CancelOrder from "../../../../components/Modals/CancelOrder";
import { MainStore } from "../../../../stores/main";
import EditPatient from "../../../../components/Modals/EditPatient";
import { FormatBirthdate } from "../../../../shared/utilities/FormatBirthdate";

const defaultSort = { type: "collected", dir: "desc" };

const ViewOrders = () => {
  const { setTitle } = useTitle();
  const [sort, setSort] = useState(defaultSort);
  const { sso } = useContext(MainStore);
  const [orders, setOrders] = useState({ data: [], page: 1 });
  const [editPatient, setEditPatient] = useState("");
  const fromDate = useRef();
  const [selected, setSelected] = useState("");
  const toDate = useRef();
  const selectType = useRef();
  const search = useRef();
  const [selectedOrder, setSelectedOrder] = useState();
  const [shipping, setShipping] = useState();
  const [toCancel, setToCancel] = useState("");
  const [rejected, setRejected] = useState("");
  const [canceled, setCanceled] = useState(null);
  const [lim, setLim] = useState(25);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const selectRow = (e) => {
    setSelected(e.target.getAttribute("data-id"));
  };

  const getOrderList = useCallback(
    async (pg, lmt) => {
      if (!pg) pg = 1;
      if (!lmt) lmt = lim;
      try {
        const request = {
          sort: sort.type,
          sort_dir: sort.dir,
          status: selectType.current.value,
          page: pg,
          limit: lmt,
        };
        if (fromDate.current?.value !== "") {
          request.from = fromDate.current.value;
        }
        if (toDate.current?.value !== "") {
          request.to = toDate.current.value;
        }
        if (search.current?.value !== "") {
          request.search = search.current.value;
        }
        const response = await Api.post(`/orders/search`, request);
        setOrders(response.data);
        setPage(response.data.page);
      } catch (e) {}
    },
    [sort, lim]
  );

  const resetFilters = useCallback(() => {
    fromDate.current.value = "";
    toDate.current.value = "";
    selectType.current.value = "";
    search.current.value = "";
    getOrderList();
  }, [getOrderList]);

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort = { ...defaultSort };
        }
      }
      setSort(newSort);
    },
    [sort]
  );

  const cancelOrder = useCallback(
    async (msg) => {
      try {
        await API.post(`/orders/${toCancel}/cancel`, { reason: msg });
      } catch (e) {}
      setToCancel("");
      getOrderList(page, lim);
    },
    [page, lim, toCancel]
  );

  const handleOrderClick = async (ord) => {
    if (ord.order_status === "rejected") {
      let reason = ord.reject_reason;
      if (reason === "Barcode PI doesn't match requisition on LIS") {
        reason =
          "Patient's name and DOB on the test order do not match the patient's name and DOB on the barcode sticker. " +
          "All patient identifiers must match in order to validate the patient with the sample. Per regulatory " +
          "requirements, we are not able to process the sample without the required information.";
      }
      if (ord.reject_note) {
        reason = reason + " NOTE: " + ord.reject_note;
      }
      setRejected(reason);
    } else if (ord.order_status === "resulted") {
      navigate(`/results/view/${ord.id}`);
    } else if (ord.order_status === "canceled") {
      try {
        const details = await API.get(`/orders/${ord.id}/cancel`);
        setCanceled(details.data);
      } catch (e) {}
    }
  };

  const setNextPage = useCallback(
    (p) => {
      setPage(p);
      getOrderList(p, lim);
    },
    [getOrderList, lim]
  );

  const changeLimit = useCallback(
    (l) => {
      setPage(1);
      setLim(l);
      getOrderList(1, l);
    },
    [getOrderList, lim]
  );

  const getPages = useCallback(() => {
    return (
      <Paging>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Label>Limit</Label>
          <StyledSelect
            style={{ flex: 1 }}
            onChange={(e) => changeLimit(Number(e.target.value))}
            value={lim}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </StyledSelect>
        </div>
        <div>Results: {orders.results}</div>
        <Pagination>
          {orders.page <= orders.pages && orders.page !== 1 && (
            <Page onClick={() => setNextPage(page - 1)}>&laquo;</Page>
          )}
          <span>
            Page {orders.page} of {orders.pages}
          </span>
          {orders.page < orders.pages && <Page onClick={() => setNextPage(page + 1)}>&raquo;</Page>}
        </Pagination>
      </Paging>
    );
  }, [orders, page, setNextPage]);

  // Fetch order list on page load
  useEffect(() => {
    getOrderList();
  }, [sort]);

  // Set page title
  useEffect(() => {
    setTitle({ name: "View Orders" });
  }, [setTitle]);

  // FIXME: Fix the fact that the date inputs reset on search

  return (
    <Wrapper>
      <ReactTooltip id="atHomeTip" effect="solid" className="tooltip">
        At Home
      </ReactTooltip>
      <Header>
        <TextInput
          id={"search"}
          title={"Search"}
          ref={search}
          onKeyUp={(e) => {
            if (e.code === "Enter") getOrderList(1, lim);
          }}
        />
        <TextInput
          id={"from_date"}
          title={"From"}
          type="date"
          data-date-format="MM/dd/yyyy"
          ref={fromDate}
        />
        <TextInput
          id={"to_date"}
          title={"To"}
          type="date"
          data-date-format="MM/dd/yyyy"
          ref={toDate}
        />
        <TextInputWrapper>
          <label>Status</label>
          <StyledSelect ref={selectType}>
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="checked in">Checked In</option>
            <option value="resulted">Resulted</option>
            <option value="rejected">Rejected</option>
            <option value="canceled">Canceled</option>
            <option value="shipped">Shipped</option>
            <option value="needs verification">Needs Verification</option>
            <option value="pending lab verification">Pending Lab Verification</option>
          </StyledSelect>
        </TextInputWrapper>
        <Button variant="primary" onClick={() => getOrderList(1, lim)} type="button">
          Search
        </Button>
        <Button variant="secondary" onClick={resetFilters} type="button">
          Reset Filters
        </Button>
      </Header>
      {orders.data.length > 0 && getPages()}
      <StyledTable>
        <thead>
          <tr>
            <th />
            <ColHead
              aria-sort={getSorted("collected")}
              onClick={() => toggleSort("collected")}
              sort
            >
              Collection Date
            </ColHead>
            <ColHead
              aria-sort={getSorted("patient.first")}
              onClick={() => toggleSort("patient.first")}
              sort
            >
              First Name
            </ColHead>
            <ColHead
              aria-sort={getSorted("patient.last")}
              onClick={() => toggleSort("patient.last")}
              sort
            >
              Last Name
            </ColHead>
            <ColHead>Test</ColHead>
            <ColHead
              sort
              aria-sort={getSorted("order_status")}
              onClick={() => toggleSort("order_status")}
            >
              Status
            </ColHead>
            <ColHead sort aria-sort={getSorted("barcode")} onClick={() => toggleSort("barcode")}>
              Barcode
            </ColHead>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {orders.data.map((order) => (
            <tr key={order.id}>
              <td>
                {order.test_location === "at_home" ? (
                  <div data-tip data-for="atHomeTip">
                    <HomeImg height={15} />
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td>{FormatBirthdate(order.collected)}</td>
              <td>{order.patient.first}</td>
              <td>{order.patient.last}</td>
              <td>{order.test.name}</td>
              <td>
                <StatusPill
                  status={order.order_status}
                  onClick={() => handleOrderClick(order)}
                  flagged={order.flag !== undefined && order.flag !== ""}
                >
                  {order.order_status.toUpperCase()}
                  {order.order_status.toUpperCase() === "NEEDS VERIFICATION" && (
                    <>
                      {order.flag !== undefined &&
                        order.flag !== "" &&
                        order.flag === "Patient Data Error" && (
                          <>
                            <WarningImg data-tip data-for={order.id} />
                            <ReactTooltip id={order.id} effect="solid" className="tooltip">
                              {order.flag === "Patient Data Error" && (
                                <>
                                  {order.sub_flag === "Collection Date Verification"
                                    ? "Sample info error - Verify collection date"
                                    : "Patient Info Error - " + order.sub_flag}
                                </>
                              )}
                            </ReactTooltip>
                          </>
                        )}
                    </>
                  )}
                </StatusPill>
              </td>
              <td>{order.barcode}</td>
              <td>
                {!["canceled"].includes(order.order_status) && (
                  <StyledAction
                    open={selected === order.id}
                    title="More"
                    id={order.id}
                    onClick={selectRow}
                    onClose={() => setSelected("")}
                  >
                    {order.test_location === "at_home" && (
                      <ActionItem
                        onClick={() => {
                          setSelectedOrder(order);
                          setShipping("true");
                        }}
                      >
                        <PackageImg />
                        Shipping Information
                      </ActionItem>
                    )}
                    {((!sso && order.order_status === "pending") || sso) && (
                      <ActionItem
                        onClick={() => {
                          navigate(`/orders/${order.id}`);
                        }}
                      >
                        <EditImg />
                        Edit Order
                      </ActionItem>
                    )}
                    <ActionItem
                      onClick={() => {
                        setEditPatient(order.patient.id);
                      }}
                    >
                      <EditImg />
                      Edit Patient Info
                    </ActionItem>
                    {order.order_status === "pending" && (
                      <>
                        <ActionItem
                          color="#ef3117"
                          onClick={() => {
                            setToCancel(order.id);
                          }}
                        >
                          <DeleteImg />
                          Cancel Order
                        </ActionItem>
                      </>
                    )}
                  </StyledAction>
                )}
              </td>
              <td />
            </tr>
          ))}
        </tbody>
      </StyledTable>
      {orders.data.length > 0 && getPages()}
      {toCancel && (
        <CancelOrder
          onLater={() => setToCancel("")}
          onCancel={cancelOrder}
          onEdit={() => navigate(`/orders/${toCancel}`)}
        />
      )}
      {rejected && (
        <Modal open onClose={() => setRejected("")}>
          <div style={{ maxWidth: "500px" }}>
            <h3>Reject Reason</h3>
            <p>{rejected}</p>
          </div>
        </Modal>
      )}
      {canceled && (
        <Modal open onClose={() => setCanceled(null)}>
          <div style={{ maxWidth: "500px" }}>
            <h3>Cancel Details</h3>
            <p>{canceled.reason}</p>
            <p>{new Date(canceled.time).toLocaleString()}</p>
            <p>{canceled.user}</p>
          </div>
        </Modal>
      )}
      {shipping && (
        <Shipping
          onClose={() => {
            getOrderList();
            setShipping("");
            setSelectedOrder();
          }}
          order={selectedOrder}
        />
      )}
      {editPatient && (
        <EditPatient
          onClose={() => {
            setEditPatient("");
            setSelected("");
            getOrderList();
          }}
          order
          patientId={editPatient}
        />
      )}
    </Wrapper>
  );
};

const ColHead = styled.th`
  ${(props) =>
    props.sort &&
    css`
      cursor: pointer;
    `};
`;
const StyledDateInput = styled(DateInput)`
  flex: 1;
  max-width: 320px;
`;
const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const Page = styled.div`
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 14px;
  ${({ active }) =>
    !active &&
    css`
      :hover {
        background-color: #ddd;
        cursor: pointer;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #4caf50;
      color: white;
    `}
`;

const Pagination = styled.div`
  display: flex;
  & * {
    padding: 8px 8px;
  }
  gap: 5px;
`;

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  min-height: 25px;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const StatusPill = styled.div`
  height: 24px;
  min-width: 130px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  > svg {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }

  ${(props) =>
    props.status === "pending" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "shipped" &&
    css`
      color: #ffffff;
      background: #802ca9;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "resulted" &&
    css`
      color: #219653;
      background: #cbffe3;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.status === "needs verification" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "pending lab verification" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "canceled" &&
    css`
      color: #000000;
      background: #eef2f6;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.status === "checked in" &&
    css`
      color: rgba(17, 67, 119, 0.4);
      background: rgba(48, 156, 255, 0.4);
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "rejected" &&
    css`
      color: #ef3117;
      background: #ffcfcf;
      border-radius: 5px;
      cursor: pointer;
    `};

  ${(props) =>
    props.flagged &&
    css`
      > svg {
        stroke: #f2994a;
      }
    `};
`;

const StyledAction = styled(ActionButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${StyledAction} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
  thead {
    display: none;
  }
  tr {
    display: flex;
    flex-direction: column;
    border: 1px solid ${rgba("#114377", 0.2)};
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 5px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.tabletS} {
    thead {
      display: revert;
    }
    tr {
      border: none;
      display: revert;
      td:nth-child(3) {
        font-weight: bold;
      }
    }
    td {
      border-collapse: collapse;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 20px;
      position: relative;
    }
    thead tr th {
      padding-bottom: 10px;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      border-collapse: separate;
      border-spacing: 5px 5px;
      -moz-user-select: none;
      -webkit-user-select: none;
      &:after {
        content: "";
        display: inline-flex;
        margin-left: 5px;
        width: 20px;
        font-size: 8px;
      }
      &[aria-sort="ascending"]:after {
        content: " ▲";
      }
      &[aria-sort="descending"]:after {
        content: " ▼";
      }
    }
    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      border: none;
      width: 10px;
      padding-right: 0;
    }
    tbody tr:hover {
      background-color: #eef2f6;
      td:first-child {
        border-radius: 20px 0 0 20px;
      }
      td:last-child {
        border-radius: 0 20px 20px 0;
      }
      ${StyledAction} {
        opacity: 1;
      }
    }
  }
`;

const ReturnedResults = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4f4f4f;
`;

const Wrapper = styled.div`
  border-radius: 20px;
  font-family: Poppins, serif;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  gap: 20px;
  @media only screen and ${(props) => props.theme.breakpoints.devices.mobileL} {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  }
`;

export default ViewOrders;
