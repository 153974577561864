import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as CardImg } from "../../../shared/assets/icons/Other/Credit Card.svg";
import styled from "styled-components";
import PropTypes from "prop-types";

const PatientProfileConfirmation = ({ onConfirm, onLater }) => {
  return (
    <Modal open onClose={onLater}>
      <Wrapper>
        <Header>
          <h3>Profile Update</h3>
        </Header>
        <Content>
          <p>
            You have changed the profile type. <br />
            This will update the patients record for this and future orders.
          </p>
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onLater}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 360px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default PatientProfileConfirmation;
