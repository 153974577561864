import axios from "axios";

export const apiPath = window.config.apiUrl;
export const stripeKey = window.config.stripeKey;

// Default Axios config
export default axios.create({
  baseURL: apiPath,
  responseType: "json",
  withCredentials: true,
  crossDomain: true,
});
