import styled from "styled-components";
import { hideVisually } from "polished";

const RadioSwitch = ({ options, group, value, onChange, className }) => {
  return (
    <CSSVariables onChange={onChange} className={className}>
      <RadioSwitchWrapper>
        {options.map((option, idx) => (
          <RadioSwitchItem key={option.id}>
            <RadioSwitchInput
              type="radio"
              id={option.id}
              name={group}
              value={option.id}
              checked={value === option.id}
              defaultChecked={value === option.id}
            />
            <RadioSwitchLabel htmlFor={option.id}>{option.name}</RadioSwitchLabel>
            <RadioSwitchMarker aria-hidden="true" />
          </RadioSwitchItem>
        ))}
      </RadioSwitchWrapper>
    </CSSVariables>
  );
};

const CSSVariables = styled.div`
  // style
  --radio-switch-width: 100%;
  --radio-switch-height: 46px;
  --radio-switch-padding: 3px;
  --radio-switch-radius: 8px;
  --text-sm: 14px;
  // animation
  --radio-switch-animation-duration: 0.3s;
  // color
  --color-bg-darker: #114377;
  --color-contrast-higher: #ffffff;
  --color-white: #ffffff;
  --color-primary: #ffffff;
  font-family: Poppins, serif;
`;

const RadioSwitchWrapper = styled.ul`
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  padding: var(--radio-switch-padding);
  border-radius: calc(var(--radio-switch-radius) * 1.4);
  background-color: var(--color-bg-darker);
  width: 100%;
  &:focus-within,
  &:active {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.5); // focus effect
  }
`;
const RadioSwitchItem = styled.li`
  position: relative;
  display: inline-block; // flexbox fallback
  height: calc(var(--radio-switch-height) - 2 * var(--radio-switch-padding));
  flex: 1;
`;
const RadioSwitchInput = styled.input`
  ${hideVisually()};
`;
const RadioSwitchLabel = styled.label`
  position: relative;
  z-index: 2;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: var(--radio-switch-radius);
  cursor: pointer;
  font-size: var(--text-sm);
  user-select: none;
  transition: all var(--radio-switch-animation-duration);
  color: var(--color-white);
  ${RadioSwitchInput}:checked ~ & {
    color: var(--color-bg-darker);
  }

  ${RadioSwitchInput}:focus ~ & {
    // focus effect in browsers not supporting :focus-within
    background-color: lightness(var(--color-primary), 0.6);
  }

  :not(*):focus-within, // trick to detect :focus-within support -> https://css-tricks.com/using-feature-detection-conditionals-and-groups-with-selectors/
  ${RadioSwitchInput}:focus ~ & {
    // reset focus style for browsers supporting :focus-within
    background-color: transparent;
  }
`;
const RadioSwitchMarker = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  border-radius: var(--radio-switch-radius);
  background-color: var(--color-primary);
  height: calc(var(--radio-switch-height) - 2 * var(--radio-switch-padding));
  width: 100%;
  opacity: 0;
  box-shadow: var(--shadow-md);
  transition: opacity var(--radio-switch-animation-duration);
  ${RadioSwitchInput}:checked ~ & {
    opacity: 1;
  }
`;
// Animated Version -- Needs to support more than 2
// const RadioSwitchMarker = styled.div`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: -200%;
//   border-radius: var(--radio-switch-radius);
//   background-color: var(--color-primary);
//   height: calc(var(--radio-switch-height) - 2 * var(--radio-switch-padding));
//   width: 100%;
//   box-shadow: var(--shadow-md);
//   transition: transform var(--radio-switch-animation-duration);
//   ${RadioSwitchInput}:checked ~ & {
//     transform: translateX(100%);
//   }
// `;

export default RadioSwitch;
