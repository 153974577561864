import React from "react";
import reducer from "../reducers/main";
import * as ACTIONS from "../actions/main";
import { apiPath } from "../utils/api";

export const MainStore = React.createContext(null);

const initialState = {
  isAuthenticated: false,
  clinic: "",
  sidebar: false,
  sso: false,
  role: "",
};

export function MainStoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const doLogout = () => {
    localStorage.clear();
    window.location.href = apiPath + "/logout";
  };

  const value = {
    role: state.role,
    isAuthenticated: state.isAuthenticated,
    sidebar: state.sidebar,
    clinic: state.clinic,
    sso: state.sso,
    handleLogin: (role, clinic, sso) => dispatch(ACTIONS.LOGIN_SUCCESS(role, clinic, sso)),
    toggleSidebar: (on) => dispatch(ACTIONS.TOGGLE_SIDEBAR(on)),
    handleLogout: () => doLogout(),
    handleRole: (role) => dispatch(ACTIONS.SET_ROLE(role)),
  };

  return <MainStore.Provider value={value}>{props.children}</MainStore.Provider>;
}
