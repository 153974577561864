import styled, { css } from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTitle } from "../../../contexts/TitleContext";
import { rgba } from "polished";
import { ReactComponent as SearchImg } from "../../../shared/assets/icons/Other/Search.svg";
import { ReactComponent as EditImg } from "../../../shared/assets/icons/Action/Edit.svg";
import { ReactComponent as FileText } from "../../../shared/assets/icons/File And Folder/File Text.svg";
import { ReactComponent as LoginImg } from "../../../shared/assets/icons/Action/Log In.svg";
import { ReactComponent as UnlockImg } from "../../../shared/assets/icons/Action/Unlocked.svg";
import { ReactComponent as PeopleImg } from "../../../shared/assets/icons/People/User Plus.svg";
import { ReactComponent as UserImg } from "../../../shared/assets/icons/People/userdrop.svg";
import { ReactComponent as DeleteImg } from "../../../shared/assets/icons/Other/Delete 2.svg";
import { ReactComponent as MergeIcon } from "../../../shared/assets/icons/Arrows/Minimize.svg";
import { ReactComponent as RightArrowIcon } from "../../../shared/assets/icons/Arrows/Right.svg";
import { ReactComponent as ToggleLeftImg } from "../../../shared/assets/icons/Action/Toggle-Left.svg";
import { ReactComponent as ToggleRightImg } from "../../../shared/assets/icons/Action/Toggle-Right.svg";
import { ReactComponent as MoreImg } from "../../../shared/assets/icons/Other/More Horizontal.svg";
import { ReactComponent as WarningImg } from "../../../shared/assets/icons/Triangle/warning.svg";
import { ReactComponent as MaleImg } from "../../../shared/assets/icons/gender/male.svg";
import { ReactComponent as FilterImg } from "../../../shared/assets/icons/Other/Filter.svg";
import { ReactComponent as FemaleImg } from "../../../shared/assets/icons/gender/female.svg";
import { ReactComponent as TestImg } from "../../../shared/assets/icons/File And Folder/File Plus.svg";
import TextInput from "../../../shared/components/TextInput";
import { ActionButton, ActionItem } from "../../../shared/components/ActionButton";
import Button from "../../../shared/components/Button";
import Checkbox from "../../../shared/components/Checkbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "../../../shared/components/Select";
import API, { apiPath } from "../../../utils/api";
import { useForm } from "react-hook-form";
import { MainStore } from "../../../stores/main";
import { FormatBirthdate } from "../../../shared/utilities/FormatBirthdate";
import DateInput from "../../../shared/components/DateInput";
import Radio from "../../../shared/components/Radio";
import EditPatient from "../../../components/Modals/EditPatient";
import AdvancedPatientSearch from "../../../components/Modals/AdvancedPatientSearch";
import ReactTooltip from "react-tooltip";

const userRoles = {
  admin: "Admin",
  clinic_admin: "Clinic Admin",
  clinician: "Clinic Standard",
  patient: "Patient",
  lab: "Lab User",
  sales: "Sales User",
  clinic_setup: "Clinic Setup",
  accounting: "Accounting",
  customer_support: "Customer Support",
  reporting: "Reporting",
};

const defaultSort = { type: "created", dir: "desc" };

const ViewPatients = () => {
  const { setTitle } = useTitle();
  const [selected, setSelected] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [patients, setPatients] = useState({ data: [], page: 1 });
  const [lastSearch, setLastSearch] = useState({});
  const [testingPs, setTestingPs] = useState([]);
  const { state } = useLocation();
  const { role } = useContext(MainStore);
  const [modal, setModal] = useState({});
  const [lim, setLim] = useState(25);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [sort, setSort] = useState(defaultSort);

  // Form handling
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort.dir = "asc";
        }
      }
      setSort(newSort);
    },
    [sort]
  );

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const selectRow = (e) => {
    setSelected(e.target.getAttribute("data-id"));
  };

  const doSearchData = useCallback(
    async (data) => {
      doSearch(data, sort, 1, lim);
    },
    [sort, clinics, lim]
  );

  // Fetch testing physicians
  const getTestingPhysicians = async () => {
    try {
      const response = await API.get("/clinicians");
      const newClinicians = [];
      for (const clinician of response.data) {
        newClinicians.push({ value: clinician.id, label: `${clinician.first} ${clinician.last}` });
      }
      setTestingPs(newClinicians);
    } catch (e) {}
  };

  const advancedSearch = (data) => {
    setValue("name", "");
    setValue("dob", "");
    doSearch(data, defaultSort, 1, lim);
  };

  const doSearch = useCallback(
    async (data, sort, p, limit) => {
      try {
        if (!limit) limit = lim;
        if (!p) p = 1;
        data.page = p;
        data.limit = limit;
        data.sort = sort.type;
        data.sort_dir = sort.dir;
        const response = await API.post("/patients/search", data);

        //loop through and parse clinic names
        for (let i = 0; i < response.data.data.length; i++) {
          const clId = response.data.data[i].clinic_id;
          response.data.data[i].clinic_name = clinics[clId];
        }

        setPatients(response.data);
        setPage(response.data.page);
        setLastSearch(data);
        setSelected("");
      } catch (e) {
        console.log(e);
      }
    },
    [sort, clinics]
  );

  const setNextPage = useCallback(
    (p) => {
      setPage(p);
      doSearch(lastSearch, sort, p, lim);
    },
    [lastSearch, doSearch, sort, lim]
  );

  const changeLimit = useCallback(
    (l) => {
      setPage(1);
      setLim(l);
      doSearch(lastSearch, sort, 1, l);
    },
    [lastSearch, doSearch, sort, lim]
  );

  const viewPatient = (p) => {
    navigate(`/patients/view/${p.id}`);
  };

  const startPatientOrder = (p) => {
    navigate(`/orders/new/`, { state: { patient: p } });
  };

  const getPages = useCallback(() => {
    return (
      <Paging>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Label>Limit</Label>
          <StyledSelect
            style={{ flex: 1 }}
            defaultValue={25}
            value={lim}
            onChange={(e) => changeLimit(Number(e.target.value))}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </StyledSelect>
        </div>
        <div>Results: {patients.results}</div>
        <Pagination>
          {patients.page <= patients.pages && patients.page !== 1 && (
            <Page onClick={() => setNextPage(page - 1)}>&laquo;</Page>
          )}
          <span>
            Page {patients.page} of {patients.pages}
          </span>
          {patients.page < patients.pages && (
            <Page onClick={() => setNextPage(page + 1)}>&raquo;</Page>
          )}
        </Pagination>
      </Paging>
    );
  }, [patients, page, setNextPage]);

  useEffect(() => {
    doSearch({}, sort, page, lim);
    setLoaded(true);
  }, [sort]);

  useEffect(() => {
    getTestingPhysicians();
  }, []);

  // Set page title
  useEffect(() => {
    const t = {
      name: "View Patients",
    };
    setTitle(t);
  }, [setTitle, state]);

  if (!loaded) return null;

  return (
    <Wrapper>
      <form
        onSubmit={handleSubmit(doSearchData)}
        style={{
          display: "flex",
          gap: "10px",
          "font-family": "Poppins, serif",
          alignItems: "center",
        }}
      >
        <TextInput id={"search"} placeholder={"Name"} variant="secondary" {...register("search")} />
        <Button variant="primary">Search</Button>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            reset();
            doSearch({}, defaultSort, 1, lim);
          }}
        >
          Reset Filters
        </Button>
        <div
          data-tip
          data-for="filterTip"
          style={{
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => setModal("advanced")}
        >
          <FilterImg height={20} />
        </div>
        <Button style={{ marginLeft: "auto" }} onClick={() => setModal("add")}>
          <PeopleImg />
          Add New Patient
        </Button>
      </form>
      <InsideWrapper>
        {patients.data.length > 0 && getPages()}
        <StyledTable>
          <thead>
            <tr>
              <th />
              <th aria-sort={getSorted("first")} onClick={() => toggleSort("first")}>
                First
              </th>
              <th aria-sort={getSorted("last")} onClick={() => toggleSort("last")}>
                Last
              </th>
              <th aria-sort={getSorted("dob")} onClick={() => toggleSort("dob")}>
                DOB
              </th>
              <th aria-sort={getSorted("gender")} onClick={() => toggleSort("gender")}>
                Birth Sex
              </th>
              <th aria-sort={getSorted("profile_type")} onClick={() => toggleSort("profile_type")}>
                Profile Type
              </th>
              <th
                aria-sort={getSorted("last_order.created")}
                onClick={() => toggleSort("last_order.created")}
              >
                Last Test Date
              </th>
              <th
                aria-sort={getSorted("last_order.order_status")}
                onClick={() => toggleSort("last_order.order_status")}
              >
                Last Test Status
              </th>
              <th
                aria-sort={getSorted("order_count.count")}
                onClick={() => toggleSort("order_count.count")}
              >
                Total Tests
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {patients.data.map((patient) => (
              <Patient key={patient.id} className={selected === patient.id ? "selected" : null}>
                <td />
                <td>{patient.first}</td>
                <td>{patient.last}</td>
                <td>{FormatBirthdate(patient.dob)}</td>
                <td>
                  <Gender gender={patient.gender}>
                    {patient.gender === "male" && <MaleImg />}
                    {patient.gender === "female" && <FemaleImg />}
                    {`${patient.gender}`}
                  </Gender>
                </td>
                <td>
                  {patient.profile_type === "patient" && <span>Patient</span>}
                  {patient.profile_type === "practice_team_member" && (
                    <span>Practice Team Member</span>
                  )}
                </td>
                <td>
                  {patient.last_order?.created && FormatBirthdate(patient.last_order?.created)}
                </td>
                <td style={{ textTransform: "uppercase" }}>
                  {patient.last_order?.order_status && patient.last_order?.order_status}
                </td>
                <td>{patient.order_count?.count && patient.order_count?.count}</td>
                <td>
                  <StyledAction
                    open={selected === patient.id}
                    title="More"
                    id={patient.id}
                    onClick={selectRow}
                    onClose={() => setSelected("")}
                  >
                    <ActionItem color="#114377" onClick={() => viewPatient(patient)}>
                      <RightArrowIcon />
                      View Patient
                    </ActionItem>
                    <ActionItem color="#114377" onClick={() => startPatientOrder(patient)}>
                      <TestImg />
                      Start Order
                    </ActionItem>
                  </StyledAction>
                </td>
              </Patient>
            ))}
          </tbody>
        </StyledTable>
        {patients.data.length > 0 && getPages()}
      </InsideWrapper>
      {modal === "add" && (
        <EditPatient
          onClose={() => {
            setModal("");
            doSearch(lastSearch, sort, page, lim);
          }}
        />
      )}
      {modal === "advanced" && (
        <AdvancedPatientSearch
          filter={lastSearch}
          testingClinicians={testingPs}
          onClose={() => {
            setModal("");
          }}
          onSearch={(data) => advancedSearch(data)}
        />
      )}
      <ReactTooltip id="filterTip" effect="solid" className="tooltip">
        Advanced Filter
      </ReactTooltip>
    </Wrapper>
  );
};

const Gender = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
  ${({ gender }) =>
    gender === "male" &&
    css`
      svg {
        fill: #0057a9;
        stroke: #0057a9;
      }
    `}
  ${({ gender }) =>
    gender === "female" &&
    css`
      svg {
        fill: #bf00ff;
        stroke: #bf00ff;
      }
    `}
`;

const CircleBg = styled.div`
  background-color: rgba(17, 67, 119, 0.4);
  height: 45px;
  width: 45px;
  border-radius: 99999px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserCircle = styled.div`
  background-color: #114377;
  height: 37px;
  width: 37px;
  border-radius: 99999px;
  overflow: hidden;
`;
const UserTypes = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: scroll;
`;
const UserType = styled.div`
  background-color: #f8f9fb;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > span:nth-child(2) {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #114377;
    margin-top: 10px;
  }
  > span:nth-child(3) {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #4f4f4f;
  }

  ${({ type }) =>
    type === "patient" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, rgba(239, 23, 217, 1) 0%, rgba(112, 13, 211, 1) 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
      ${UserCircle} {
        background: linear-gradient(180deg, rgba(239, 23, 217, 1) 0%, rgba(112, 13, 211, 1) 100%);
      }
      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(239, 23, 217, 0.4) 0%,
          rgba(112, 13, 211, 0.4) 100%
        );
      }
    `}

  ${({ type }) =>
    type === "clinic_admin" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(197.56deg, #309cff 4.46%, #0864b9 77.35%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(197.56deg, #309cff 4.46%, #0864b9 77.35%);
      }

      ${CircleBg} {
        background: linear-gradient(
          197.56deg,
          rgba(48, 156, 255, 0.4) 4.46%,
          rgba(8, 100, 185, 0.4) 77.35%
        );
      }
    `}
  
  ${({ type }) =>
    type === "clinician" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #12e24c 0%, #07ae4d 89.58%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #12e24c 0%, #07ae4d 89.58%);
      }

      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(18, 226, 76, 0.4) 0%,
          rgba(7, 174, 77, 0.4) 89.58%
        );
      }
    `}
  
  ${({ type }) =>
    type === "lab" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #f1e812 0%, #ffae10 90%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #f1e812 0%, #ffae10 90%);
      }

      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(241, 232, 18, 0.4) 0%,
          rgba(255, 174, 16, 0.4) 90%
        );
      }
    `}
  
  ${({ type }) =>
    type === "sales" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #fbc214 0%, #fc8215 90%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #fbc214 0%, #fc8215 90%);
      }

      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(251, 194, 20, 0.4) 0%,
          rgba(252, 130, 21, 0.4) 90%
        );
      }
    `}
  
  ${({ type }) =>
    type === "clinic_setup" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #ef3117 0%, #d30d0d 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #ef3117 0%, #d30d0d 100%);
      }

      ${CircleBg} {
        background: linear-gradient(180deg, rgba(239, 49, 23, 0.4) 0%, rgba(211, 13, 13, 0.4) 100%);
      }
    `}
  
  ${({ type }) =>
    type === "accounting" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #f46abd 0%, #b53553 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #f46abd 0%, #b53553 100%);
      }

      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(244, 106, 189, 0.4) 0%,
          rgba(181, 53, 83, 0.4) 100%
        );
      }
    `}
  
  ${({ type }) =>
    type === "customer_support" &&
    css`
      > span:nth-child(2) {
        background: linear-gradient(180deg, #787e85 0%, #393f3b 89.58%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }

      ${UserCircle} {
        background: linear-gradient(180deg, #787e85 0%, #393f3b 89.58%);
      }

      ${CircleBg} {
        background: linear-gradient(
          180deg,
          rgba(120, 126, 133, 0.4) 0%,
          rgba(57, 63, 59, 0.4) 89.58%
        );
      }
    `}
`;

const StyledSelect = styled(Select)`
  max-width: 300px;
`;

const StyledAction = styled(ActionButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${StyledAction} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  thead tr th {
    padding-bottom: 10px;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    border-collapse: separate;
    border-spacing: 5px 5px;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    &:after {
      content: "";
      display: inline-flex;
      margin-left: 5px;
      width: 20px;
      font-size: 8px;
    }
    &[aria-sort="ascending"]:after {
      content: " ▲";
    }
    &[aria-sort="descending"]:after {
      content: " ▼";
    }
  }
  th:first-child,
  th:last-child,
  td:first-child,
  td:last-child {
    border: none;
    width: 10px;
    padding-right: 0;
  }
  tbody tr:hover {
    cursor: pointer;
    background-color: #eef2f6;
    td:first-child {
      border-radius: 20px 0 0 20px;
    }
    td:last-child {
      border-radius: 0 20px 20px 0;
    }
    ${StyledAction} {
      opacity: 1;
    }
  }
  td {
    border-collapse: collapse;
    border-bottom: 1px solid ${rgba("#114377", 0.2)};
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
`;

const Patient = styled.tr`
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #eef2f6;
      td:first-child {
        border-radius: 20px 0 0 20px;
      }
      td:last-child {
        border-radius: 0 20px 20px 0;
      }
      // ${ActionButton} {
      //   opacity: 1;
      // }
    `}
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  gap: 20px;
`;

const Pagination = styled.div`
  display: flex;
  & * {
    padding: 8px 8px;
  }
  gap: 5px;
`;

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
`;

const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const Page = styled.div`
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 14px;
  ${({ active }) =>
    !active &&
    css`
      :hover {
        background-color: #ddd;
        cursor: pointer;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #4caf50;
      color: white;
    `}
`;

const MergeError = styled.td`
  ${({ error }) =>
    error &&
    css`
      color: red;
    `}
`;

const More = styled(MoreImg)`
  transform: scale(0.8) translateY(10px);
`;

const InsideWrapper = styled(Wrapper)`
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  padding: 20px;
  font-family: Poppins, serif;
  font-style: normal;
`;

const MergeZone = styled.div`
  border: 1px dashed ${(props) => props.theme.colors.grey.grey3};
  border-radius: 10px;
  font-family: Poppins, serif;
  font-size: 12px;
  padding: 20px;
  margin-top: 10px;
  position: relative;
  background-color: rgba(238, 242, 246, 0.3);
`;

export default ViewPatients;
