import styled, { css } from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTitle } from "../../../contexts/TitleContext";
import { ReactComponent as NewImg } from "../../../shared/assets/icons/newcircle.svg";
import { ReactComponent as PendingImg } from "../../../shared/assets/icons/pendingcircle.svg";
import { ReactComponent as MailImg } from "../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as PhoneImg } from "../../../shared/assets/icons/Phone/Defaut.svg";
import { ReactComponent as UpArrow } from "../../../shared/assets/icons/Arrows/Up filled.svg";
import { ReactComponent as DownArrow } from "../../../shared/assets/icons/Arrows/Down filled.svg";
import { ReactComponent as RightImg } from "../../../shared/assets/icons/Chevron/Right.svg";
import { ReactComponent as LeftImg } from "../../../shared/assets/icons/Chevron/Left.svg";
import LineChart from "../../../shared/components/Graphs/LineChart";
import Button from "../../../shared/components/Button";
import NotificationList from "../../../components/NotificationList";
import { Link, useNavigate } from "react-router-dom";
import UpdateBilling from "../../../components/Modals/UpdateBilling";
import API from "../../../utils/api";
import CardExpiring from "../../../components/Modals/CardExpiring";
import Truncate from "../../../shared/utilities/Truncate";
import Modal from "../../../shared/components/Modal";
import Logo from "../../../shared/components/Logo";
import GetInitials from "../../../shared/utilities/GetInitials";
import FormatPhone from "../../../shared/utilities/FormatPhone";
import { useCookies } from "react-cookie";
import PrebuyOffer from "../../../components/Modals/PrebuyOffer";
import DeactivateOffer from "../../../components/Modals/DeactivateOffer";
import Prebuy from "../../../components/Modals/Prebuy";
import Api from "../../../utils/api";

const Dashboard = () => {
  const { setTitle } = useTitle();
  const [methodState, setMethodState] = useState("");
  const [billingMethod, setBillingMethod] = useState("");
  const [bulletinOpen, setBulletinOpen] = useState(false);
  const [bulletin, setBulletin] = useState(null);
  const [cookies, setCookie] = useCookies(["offerPopups", "prebuyReminder"]);
  const [clinicProfile, setClinicProfile] = useState(null);
  const [clinicPricing, setClinicPricing] = useState(null);
  const [clinicGraph, setClinicGraph] = useState({
    weeks: [],
    currentOrders: 0,
    currentChange: 0,
    top3: [],
  });
  const [orderPendingCount, setOrderPendingcount] = useState(0);
  const [resultUnviewedCount, setResultUnviewedCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offerPopup, setOfferPopup] = useState({ code: "", offerId: null });
  const navigate = useNavigate();

  const fetchBulletin = async () => {
    try {
      const response = await API.get(`/bulletins`);
      if (response.data) {
        setBulletin(response.data);
      }
    } catch (e) {}
  };

  const getOffers = async () => {
    try {
      const response = await API.get("/offers");
      setOffers(response.data);

      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].offers.length > 0) {
          if (response.data[i].max_redemptions > response.data[i].offers[0].redemptions) {
            if (
              cookies.offerPopups === undefined ||
              cookies.offerPopups[response.data[i].code] === undefined ||
              cookies.offerPopups[response.data[i].code] < 2
            ) {
              setOfferPopup({ code: response.data[i].code, offerId: response.data[i]._id });
            }
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dismissOfferPopup = (code) => {
    let popup = {};
    if (cookies.offerPopups === undefined || cookies.offerPopups[code] === undefined) {
      popup = {};
    } else {
      popup = cookies.offerPopups;
    }

    setCookie(
      "offerPopups",
      {
        ...popup,
        [code]: popup[code] + 1 || 1,
      },
      { path: "/" }
    );
    setOfferPopup({ code: "", offerId: null });
  };

  const getClinicPricing = async () => {
    try {
      const response = await Api.get(`/profile/clinic/pricing`);

      if (response.data) {
        let isCustom = false;
        if (response.data.tests && response.data.tests.hr5 && response.data.tests.hr5.custom) {
          isCustom = true;
        }
        window.Intercom("update", {
          pricing_type: isCustom ? "custom" : "global",
        });
      }

      if (response.data && response.data.tests && response.data.tests.hr5) {
        return response.data.tests;
      }
    } catch (e) {}
  };

  const getCurrentClinicProfile = async () => {
    try {
      const response = await API.get("/profile/clinic");
      const pricing = await getClinicPricing();
      setClinicPricing(pricing);
      setClinicProfile(response.data);
      setBillingMethod(response.data.billing_schedule);
      // const today = new Date();

      // // If clinic is from before 4/1/24
      // if (
      //   response.data &&
      //   response.data.created &&
      //   response.data.disable_prebuy_plans !== true &&
      //   pricing.hr5.custom === false
      // ) {
      //   const created = new Date(response.data.created);
      //   const cutoff = new Date("2024-04-01");
      //   console.log(cookies.prebuyReminder, created);
      //   if (created < cutoff && cookies.prebuyReminder === undefined) {
      //     setOfferPopup({ code: "new-prebuy", offerId: null });
      //   } else if (
      //     cookies.prebuyReminder !== "shown" &&
      //     created < cutoff &&
      //     today - cookies.prebuyReminder > 10080 * 60 * 1000
      //   ) {
      //     setOfferPopup({ code: "new-prebuy", offerId: null });
      //   }
      // }

      await getCurrentMethod(response.data.billing_schedule);
    } catch (e) {}
  };

  const getClinicDashboardGraph = async () => {
    try {
      const response = await API.get("/dashboard/graph/clinic");
      setClinicGraph(response.data);
    } catch (e) {}
  };

  const getOrderPendingCount = async () => {
    try {
      const response = await API.get("/orders/not_resulted/count");
      setOrderPendingcount(response.data);
    } catch (e) {}
  };

  const getResultsUnviewedCount = async () => {
    try {
      const response = await API.get("/results/not_viewed/count");
      setResultUnviewedCount(response.data);
    } catch (e) {}
  };

  // Get their current billing details
  const getCurrentMethod = async (bm) => {
    try {
      const response = await API.get("/billing/details");
      if (typeof response.data == "object") {
        // Check if the expiry is this month
        const today = new Date();
        if (response.data.expiry === `${today.getMonth() + 1}/${today.getFullYear()}`) {
          setMethodState("upcoming-expiry");
        }
      }
    } catch (e) {}
  };

  const getBulletin = useMemo(() => {
    if (!bulletin) {
      return null;
    }
    const short = Truncate(bulletin.message, 500);
    const theDate = new Date(bulletin.created);
    const monthName = theDate.toLocaleString("en-us", { month: "short" });
    const theDay = theDate.getDate();
    return (
      <>
        <BulletinBoardWrapper>
          <div>
            <BulletinDate>
              <DateMonth>{monthName}</DateMonth>
              <DateDay>{theDay}</DateDay>
            </BulletinDate>
            <VHR />
            <h3>{bulletin.subject}</h3>
          </div>
          <CHR />
          <Bulletin>
            {short + " "}
            <span onClick={() => setBulletinOpen(true)}>Read More</span>
          </Bulletin>
        </BulletinBoardWrapper>
        {bulletinOpen && (
          <Modal open onClose={() => setBulletinOpen(false)}>
            <BulletinContent>
              <div>
                <BulletinDate>
                  <DateMonth>{monthName}</DateMonth>
                  <DateDay>{theDay}</DateDay>
                </BulletinDate>
                <VHR />
                <h3>{bulletin.subject}</h3>
              </div>
              <CHR />
              <Bulletin>
                <pre>{bulletin.message}</pre>
              </Bulletin>
              <Footer>
                <p>Thanks,</p>
                <Logo variant="dark" small />
                <p>
                  <MailImg /> info@directdiagnostics.com
                </p>
                <p>
                  <PhoneImg /> 512-861-1510
                </p>
              </Footer>
            </BulletinContent>
          </Modal>
        )}
      </>
    );
  }, [bulletin, bulletinOpen]);

  // Set page title
  useEffect(() => {
    setTitle("Dashboard");
  }, [setTitle]);

  // On page load
  useEffect(() => {
    Promise.all([
      getCurrentClinicProfile(),
      getOrderPendingCount(),
      getResultsUnviewedCount(),
      fetchBulletin(),
      getClinicDashboardGraph(),
      getOffers(),
    ]).then(() => {
      setLoaded(true);
      let orderRedirect = localStorage.getItem("orderRedirect");
      if (orderRedirect !== "" && orderRedirect !== null && orderRedirect !== "null") {
        localStorage.removeItem("orderRedirect");
        navigate(`/orders/${orderRedirect}`);
      }
    });
  }, []);

  if (!loaded) return null;

  return (
    <>
      {offers.map((offer) => (
        <>
          {offer.code === "prebuy13" && (
            <Notice>
              <span style={{ color: "#FF0000", fontWeight: "bold", marginRight: "5px" }}>
                Alert!
              </span>
              Special offer available:
              <span style={{ fontWeight: "bold", marginRight: "5px", marginLeft: "5px" }}>
                Buy <span style={{ color: "green", textDecoration: "underline" }}>10</span> test
                credits, get <span style={{ color: "green", textDecoration: "underline" }}>3</span>{" "}
                free!
              </span>
              Limited time only,{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() =>
                  setOfferPopup({
                    code: offer.code,
                    offerId: offer._id,
                    redeemed: offer.max_redemptions <= offer.offers[0].redemptions,
                    max_redemptions: offer.max_redemptions,
                  })
                }
              >
                click here
              </span>{" "}
              to view details
            </Notice>
          )}
          {offer.code === "august-endofsummer" && (
            <Notice>
              <span style={{ color: "#FF0000", fontWeight: "bold", marginRight: "5px" }}>
                Alert!
              </span>
              Special offer available:
              <span style={{ fontWeight: "bold", marginRight: "5px", marginLeft: "5px" }}>
                Buy <span style={{ color: "green", textDecoration: "underline" }}>5</span> test
                credits, get <span style={{ color: "green", textDecoration: "underline" }}>1</span>{" "}
                free!
              </span>
              Limited time only,{" "}
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() =>
                  setOfferPopup({
                    code: offer.code,
                    offerId: offer._id,
                    redeemed: offer.max_redemptions <= offer.offers[0].redemptions,
                    max_redemptions: offer.max_redemptions,
                  })
                }
              >
                click here
              </span>{" "}
              to view details
            </Notice>
          )}
        </>
      ))}
      <Wrapper>
        {methodState === "upcoming-expiry" && (
          <CardExpiring
            onLater={() => setMethodState("")}
            onUpdate={() => navigate("/settings/billing/edit")}
          />
        )}
        {offerPopup.code === "july-heatwave" && (
          <PrebuyOffer
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            offerId={offerPopup.offerId}
            onDismiss={() => dismissOfferPopup("july-heatwave")}
            onUpdate={() => navigate("/settings/billing/edit")}
            onComplete={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            maxPurchase={offerPopup.max_redemptions}
            initialScreen={offerPopup.redeemed ? "redeemed" : "offer"}
          />
        )}
        {offerPopup.code === "august-endofsummer" && (
          <PrebuyOffer
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            offerId={offerPopup.offerId}
            onDismiss={() => dismissOfferPopup("august-endofsummer")}
            onUpdate={() => navigate("/settings/billing/edit")}
            onComplete={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            maxPurchase={offerPopup.max_redemptions}
            initialScreen={offerPopup.redeemed ? "redeemed" : "offer"}
          />
        )}
        {offerPopup.code === "raise_the_dead" && (
          <DeactivateOffer
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            hasBilling={clinicProfile.verified_payment && clinicProfile.payment_type !== ""}
            offerId={offerPopup.offerId}
            onDismiss={() => dismissOfferPopup("raise_the_dead")}
            onUpdate={() => navigate("/settings/billing/edit")}
            onComplete={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            maxPurchase={offerPopup.max_redemptions}
            initialScreen={offerPopup.redeemed ? "redeemed" : "offer"}
          />
        )}
        {offerPopup.code === "new-prebuy" && clinicPricing !== null && (
          <Prebuy
            initialScreen={"new-plans"}
            clinicProfile={clinicProfile}
            clinicPricing={clinicPricing}
            onLater={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
            onUpdate={() => {
              setOfferPopup({ code: "", offerId: null });
            }}
          />
        )}
        <LeftCol>
          <HeaderCards>
            <IconCard style={{ cursor: "pointer" }} onClick={() => navigate("/results/view")}>
              <NewImg />
              <Info>
                <div>{resultUnviewedCount}</div>
                <div>New Results</div>
              </Info>
            </IconCard>
            <IconCard>
              <PendingImg />
              <Info>
                <div>{orderPendingCount}</div>
                <div>Pending Results</div>
              </Info>
            </IconCard>
          </HeaderCards>
          <InsideWrapperHidden>
            <GraphHeader>
              <h3>Tests Ordered</h3>
              {/*<GraphTitleDate>September - October 2021</GraphTitleDate>*/}
              {/*<GraphDateToggle>*/}
              {/*  <LeftImg />*/}
              {/*  <RightImg />*/}
              {/*</GraphDateToggle>*/}
            </GraphHeader>
            <LineChart data={clinicGraph.weeks} />
            <GraphFooter>
              <span>Total Weekly Orders:</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>{clinicGraph.current_orders} order(s)</span>
                {clinicGraph.current_change > 0 && (
                  <Stats increase>
                    <UpArrow />
                    <span>{clinicGraph.current_change}%</span>
                  </Stats>
                )}
                {clinicGraph.current_change < 0 && (
                  <Stats decrease>
                    <DownArrow />
                    <span>{clinicGraph.current_change}%</span>
                  </Stats>
                )}
              </div>
            </GraphFooter>
            <TestingClinicians>
              <div>
                <HR />
                <span>Top Testing Clinicians</span>
                <HR />
              </div>
              <div>
                {clinicGraph.top3.map((c) => (
                  <TopClinician>
                    <Avatar>{GetInitials(c.name)}</Avatar>
                    <ClinicianDetails>
                      <div>{c.name}</div>
                      <div>{c.value} ORDER(S)</div>
                    </ClinicianDetails>
                  </TopClinician>
                ))}
              </div>
            </TestingClinicians>
          </InsideWrapperHidden>
        </LeftCol>
        <RightCol>
          <NotificationWrapper>
            <div>
              <h3>Notifications</h3>
            </div>
            <HR />
            <Notifications>
              <NotificationList paging />
            </Notifications>
          </NotificationWrapper>
          {bulletin && getBulletin}
        </RightCol>
      </Wrapper>
    </>
  );
};

const Notifications = styled.div`
  max-height: 520px;
  overflow-y: scroll;
`;

const Footer = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  p {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin: 5px;
  }
`;

const Bulletin = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4f4f4f;
  span {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #114377;
    text-decoration: none;
    cursor: pointer;
  }
`;

const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;

const CHR = styled.hr`
  width: 100%;
  height: 4px;
  border: none;
  border-top: 4px dotted ${(props) => props.theme.colors.grey.grey5};
`;

const VHR = styled.hr`
  height: 100%;
  width: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;

const ClinicianDetails = styled.div`
  div:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #114377;
  }
  div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #0b0b0b;
  }
`;

const TestingClinicians = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  letter-spacing: -0.005em;
  text-transform: uppercase;
  flex-wrap: wrap;
  color: #0b0b0b;
  & span {
    white-space: nowrap;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 15px;
  }
  > div {
    display: flex;
    gap: 10px;
  }
`;

const GraphFooter = styled.div`
  display: flex;
  font-family: Poppins, serif;
  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #0b0b0b;
    flex: 1;
  }
  > div {
    display: flex;
    > span {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: -0.005em;
      color: #0b0b0b;
    }
    > div {
      margin-left: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
    }
  }
`;

const GraphHeader = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    flex: 1;
  }
`;

const GraphDateToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SalesRep = styled.div`
  background: #f8f9fb;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding-left: 20px;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 15px;
  @media only screen and ${(props) => props.theme.breakpoints.devices.mobileL} {
    flex-direction: row;
    align-items: center;
    gap: 5px;
    min-height: 70px;
  }
`;

const RepDetails = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #0b0b0b;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`;

const DefaultCol = styled.div`
  flex: 1;
`;
const LeftCol = styled(DefaultCol)`
  flex: 2;
  display: flex;
  flex-direction: column;
`;
const RightCol = styled(DefaultCol)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const HeaderCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  @media only screen and ${(props) => props.theme.breakpoints.devices.mobileL} {
    flex-direction: row;
  }
`;
const Info = styled.div`
  text-transform: uppercase;
  div:nth-child(1) {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
  }
  div:nth-child(2) {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
  }
`;
const IconCard = styled.div`
  background: #f8f9fb;
  border-radius: 15px;
  width: 100%;
  height: 120px;
  min-width: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  gap: 15px;
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 20px;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    flex-direction: row;
  }
`;

// Notice
const Notice = styled.div`
  border-radius: 10px;
  font-family: Poppins, serif;
  margin-bottom: 20px;
  background-color: rgba(241, 55, 55, 0.1);
  padding: 10px;
  font-size: 16px;
`;

const InsideWrapper = styled(Wrapper)`
  border-width: 1px;
`;

const InsideWrapperHidden = styled(InsideWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Avatar = styled.div`
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;

const GraphTitleDate = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #114377;
  text-align: center;
`;

const TopClinician = styled.div`
  background: #f8f9fb;
  border-radius: 15px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  gap: 15px;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    flex-direction: row;
  }
`;

const NotificationWrapper = styled(InsideWrapper)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 0;
  flex: 2;
`;

const BulletinContent = styled.div`
  flex: 1;
  flex-direction: column;
  gap: 5px;
  > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  max-width: 500px;
  overflow: scroll;
`;

const BulletinBoardWrapper = styled(InsideWrapper)`
  flex: 1;
  flex-direction: column;
  gap: 5px;
  > :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;

const DateDay = styled.div`
  font-size: 20px;
`;

const DateMonth = styled.div`
  font-size: 12px;
`;

const Stats = styled.div`
  display: flex;
  align-items: center;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: -0.01em;
  ${({ increase }) =>
    increase &&
    css`
      color: #219653;
      display: flex;
      align-items: center;
      > svg {
        fill: #219653;
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    `}
  ${({ decrease }) =>
    decrease &&
    css`
      color: #f13737;
      display: flex;
      align-items: center;
      > svg {
        fill: #f13737;
        height: 16px;
        width: 16px;
        margin-right: 5px;
      }
    `}
`;

const BulletinDate = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #114377;
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export default Dashboard;
