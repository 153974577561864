import Modal from "../../shared/components/Modal";
import styled from "styled-components";
import Button from "../../shared/components/Button";

const Confirm = ({
  onClose,
  onConfirm,
  title,
  text,
  titleImg,
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  return (
    <Modal open>
      <Wrapper>
        <Header>
          {titleImg} {title}
        </Header>
        {text}
        <Buttons>
          <Button type="button" onClick={onClose} variant="secondary">
            {cancelText}
          </Button>
          <Button type="button" onClick={onConfirm}>
            {confirmText}
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  font-family: Poppins, serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
  > svg {
    stroke: #f13737;
  }
  align-items: center;
`;
const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > * {
    flex: 1;
    justify-content: center;
  }
`;

export default Confirm;
