import Modal from "../../../../shared/components/Modal";
import styled, { css } from "styled-components";
import { ReactComponent as PhoneImg } from "../../../../shared/assets/icons/Tech/Smartphone.svg";
import { ReactComponent as MailImg } from "../../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as SendImg } from "../../../../shared/assets/icons/Other/Send.svg";
import { ReactComponent as NotificationImg } from "../../../../shared/assets/icons/Action/Notification On.svg";
import { ReactComponent as CircleImg } from "../../../../shared/assets/icons/Action/Tick Small Circle.svg";
import Button from "../../../../shared/components/Button";
import { useState } from "react";
import API from "../../../../utils/api";

const SendTestResults = ({ onClose, setting }) => {
  const [selected, setSelected] = useState(setting);
  const saveNew = async () => {
    if (selected === setting) {
      onClose();
    } else {
      try {
        await API.put("/profile/clinic/settings/result_method", {
          value: selected,
        });
      } catch (e) {}
      onClose();
    }
  };
  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <SubTitle>TEST RESULTS SETTINGS</SubTitle>
          <Title>Send Test Results To Patient</Title>
        </Header>
        <List>
          <Item active={selected === "manual"} onClick={() => setSelected("manual")}>
            <SendImg />
            <span>Manually</span>
            <CheckIcon />
          </Item>
          <Item active={selected === "sms"} onClick={() => setSelected("sms")}>
            <PhoneImg />
            <span>Text</span>
            <CheckIcon />
          </Item>
          <Item active={selected === "email"} onClick={() => setSelected("email")}>
            <MailImg />
            <span>Email</span>
            <CheckIcon />
          </Item>
          <Item active={selected === "sms, email"} onClick={() => setSelected("sms, email")}>
            <NotificationImg />
            <span>Text, Email</span>
            <CheckIcon />
          </Item>
        </List>
        <Buttons>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={saveNew}>Save Changes</Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default SendTestResults;

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  width: 360px;
`;

const List = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const CheckIcon = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
`;
const ItemHoverStyle = css`
  color: white;
  background-color: #114377;
  svg {
    & path {
      stroke: white;
    }
    & rect {
      fill: white;
    }
  }
  ${CheckIcon} {
    & path.svgcircle {
      fill: white;
    }
    & path.svgcheck {
      fill: #114377;
      stroke: #114377;
    }
  }
`;
const Item = styled.div`
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0b0b0b;
  gap: 10px;
  cursor: pointer;
  span {
    flex: 1;
  }
  &:hover {
    ${ItemHoverStyle}
  }
  ${(props) => props.active && ItemHoverStyle}
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
