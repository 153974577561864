import Modal from "../../../shared/components/Modal";
import TextInput from "../../../shared/components/TextInput";
import Button from "../../../shared/components/Button";
import { ReactComponent as InfoImg } from "../../../shared/assets/icons/Circle/Info filled.svg";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import API from "../../../utils/api";

const PasswordModal = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // Form handling
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const newPw = useRef({});
  newPw.current = watch("new_pw", "");
  // Process change password
  const changePassword = async (data) => {
    setLoading(true);
    try {
      const response = await API.put("/profile/password", {
        password: data.current_pw,
        new_password: data.new_pw,
      });
      onClose();
    } catch (e) {
      if (e.response.data.error) {
        setError(e.response.data.error);
      } else {
        setError("Unknown error. Please contact support.");
      }
    }
    setLoading(false);
  };
  return (
    <Modal open onClose={onClose}>
      <Header>
        <SubTitle>Settings</SubTitle>
        <Title>Change Password</Title>
      </Header>
      <Form onSubmit={handleSubmit(changePassword)}>
        <TextInput
          id={"current_pw"}
          title={"Enter current password"}
          type="password"
          disabled={loading}
          error={errors.current_pw}
          {...register("current_pw", { required: true })}
        />
        <TextInput
          id={"new_pw"}
          title={"Enter New Password"}
          type="password"
          disabled={loading}
          error={errors.new_pw}
          {...register("new_pw", { required: true })}
        />
        <TextInput
          id={"confirm_pw"}
          title={"Confirm New Password"}
          type="password"
          disabled={loading}
          error={errors.confirm_pw}
          {...register("confirm_pw", {
            required: true,
            validate: (value) =>
              value === newPw.current || "The passwords do not match",
          })}
        />
        <Notice>
          <InfoImg />
          Password must be at least 8 characters long and must contain 1 special
          character, 1 uppercase character, and 1 number
        </Notice>
        <FormButtons>
          <StyledButton variant="secondary" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton variant="primary" type="submit" disabled={loading} loading={loading}>
            Save Changes
          </StyledButton>
        </FormButtons>
        {error && <Error>{error}</Error>}
      </Form>
    </Modal>
  );
};

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Form = styled.form`
  display: flex;
  gap: 20px;
  flex-direction: column;
  max-width: 360px;
`;

const FormButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const Notice = styled.p`
  display: flex;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  color: ${(props) => props.theme.colors.grey.grey2};
  gap: 10px;
  svg {
    fill: ${(props) => props.theme.colors.grey.grey3};
    height: 20px;
    width: 20px;
    flex: none;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default PasswordModal;
