import styled, { css } from "styled-components";
import { ReactComponent as ClinicImg } from "../../../shared/assets/icons/Other/Clinic.svg";
import { ReactComponent as CircleImg } from "../../../shared/assets/icons/Action/Tick Small Circle.svg";
import Button from "../../../shared/components/Button";
import { useForm } from "react-hook-form";
import Modal from "../../../shared/components/Modal";
import { useEffect, useState } from "react";
import API from "../../../utils/api";

const SwitchClinic = ({ onClose, currentClinic }) => {
  const [clinics, setClinics] = useState([]);
  const [clinic, setClinic] = useState(currentClinic);
  const [loading, setLoading] = useState(false);

  const getClinics = async () => {
    try {
      const response = await API.get("/profile/clinics");
      setClinics(response.data.clinics);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getClinics();
  }, []);

  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    setLoading(true);
    try {
      const response = await API.put(`/change_clinic/${clinic}`);
      window.location = window.location.origin + "/dashboard";
    } catch (e) {}
  };

  return (
    <Modal open onClose={onClose}>
      <form onSubmit={handleSubmit(submit)}>
        <Title>Switch Clinic</Title>
        <ClinicList>
          {clinics.map((cln) => (
            <Clinic
              onClick={() => setClinic(cln.id)}
              active={clinic === cln.id}
              key={cln.id}
            >
              <ClinicIcon />
              <span>{cln.name}</span>
              <CheckIcon />
            </Clinic>
          ))}
        </ClinicList>
        <FormButtons>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button type="submit" disabled={clinic === "" || loading}>
            Select Clinic
          </Button>
        </FormButtons>
      </form>
    </Modal>
  );
};

const FormButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const Title = styled.h2``;

const ClinicList = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
const ClinicIcon = styled(ClinicImg)`
  & path {
    stroke: ${(props) => props.theme.colors.blue.light.primary};
  }
  & rect {
    fill: ${(props) => props.theme.colors.blue.light.primary};
  }
`;
const CheckIcon = styled(CircleImg)`
  & path.svgcircle {
    fill: #bac4cf;
  }
  & path.svgcheck {
    fill: white;
  }
`;
const ClinicHoverStyle = css`
  color: white;
  background-color: #114377;
  ${ClinicIcon} {
    & path {
      stroke: white;
    }
    & rect {
      fill: white;
    }
  }
  ${CheckIcon} {
    & path.svgcircle {
      fill: white;
    }
    & path.svgcheck {
      fill: #114377;
      stroke: #114377;
    }
  }
`;
const Clinic = styled.div`
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.grey.grey3};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: capitalize;
  color: #0b0b0b;
  gap: 10px;
  cursor: pointer;
  span {
    flex: 1;
  }
  &:hover {
    ${ClinicHoverStyle}
  }
  ${(props) => props.active && ClinicHoverStyle}
`;

const FullButton = styled(Button)`
  margin-top: 10px;
`;

export default SwitchClinic;
