import Modal from "../../../../shared/components/Modal";
import styled from "styled-components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import API from "../../../../utils/api";
import TextInput from "../../../../shared/components/TextInput";
import Button from "../../../../shared/components/Button";
import TextMaskInput from "../../../../shared/components/TextMaskInput";
import States from "../../../../utils/states";
import Select from "../../../../shared/components/Select";

const EditInfo = ({ onClose, onUpdate, profile }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    setError([]);
    try {
      const response = await API.put("/profile/clinic", data);
      setLoading(false);
      onUpdate(response.data);
    } catch (e) {
      if (e.response.data.errors) {
        console.log(e.response.data);
        setError(e.response.data.errors);
      }
      console.log(e);
      setLoading(false);
    }
  };
  return (
    <Modal open onClose={onClose}>
      <Header>
        <SubTitle>SETTINGS</SubTitle>
        <Title>Edit Clinic Info</Title>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id={"address_1"}
          title="Clinic Address"
          required
          maxLength={30}
          error={errors["address_1"] || error["address_1"]}
          disabled={loading}
          defaultValue={profile.address_1}
          {...register("address_1", { required: true })}
        />
        <TextInput
          id={"address_2"}
          maxLength={30}
          defaultValue={profile.address_2}
          disabled={loading}
          {...register("address_2", { required: false })}
        />
        <TextInput
          id={"city"}
          title="City"
          required
          error={errors["city"] || error["city"]}
          defaultValue={profile.city}
          disabled={loading}
          {...register("city", { required: true })}
        />
        <StyledSelect
          style={{ flex: 1 }}
          required
          defaultValue={profile.state}
          disable={loading}
          error={errors.state !== undefined || error}
          disabled={loading}
          {...register("state", { required: true })}
        >
          <option value="">- State -</option>
          {States.map((state) => (
            <option value={state.abbreviation}>{state.name}</option>
          ))}
        </StyledSelect>
        <TextInput
          id={"zip"}
          title="Zip"
          required
          error={errors["zip"] || error["zip"]}
          defaultValue={profile.zip}
          disabled={loading}
          {...register("zip", { required: true })}
        />
        <TextInput
          id={"email"}
          title="Email"
          error={errors["email"] || error["email"]}
          defaultValue={profile.email}
          disabled={loading}
          {...register("email")}
        />
        <TextMaskInput
          alwaysShowMask={false}
          maskPlaceholder={null}
          mask="(999)999-9999"
          id={"phone"}
          error={errors["phone"] || error["phone"]}
          title="Phone"
          required
          defaultValue={profile.phone}
          disabled={loading}
          {...register("phone", { required: true })}
        />
        <TextInput
          id={"url"}
          title="URL"
          defaultValue={profile.url}
          disabled={loading}
          {...register("url", { required: false })}
        />
        <Buttons>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button type="submit">Save Changes</Button>
        </Buttons>
      </Form>
    </Modal>
  );
};

const Buttons = styled.div`
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const StyledSelect = styled(Select)`
  background-color: #ffffff;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 44px;
`;

export default EditInfo;
