import styled from "styled-components";
import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { useEffect, useState } from "react";
import API from "../../../utils/api";
import TextInput from "../../../shared/components/TextInput";
import { ReactComponent as SentImg } from "../../../shared/assets/icons/message-sent.svg";
import { useForm } from "react-hook-form";
import TextAreaInput from "../../../shared/components/TextAreaInput";
import Select from "../../../shared/components/Select";

const ContactSupport = ({ onClose, onOrder }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clinic, setClinic] = useState({});
  const [sent, setSent] = useState(false);

  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <Title>Contact Support</Title>
        </Header>
        <To>Hours of Operation: M - F 8am - 5pm CST. Closed all federal holidays.</To>
        <To>
          <strong>Email us: </strong>
          <a href="mailto:support@directdiagnostics.com">support@directdiagnostics.com</a>
        </To>
        <To>
          <strong>Call us: </strong>
          <a href="tel:15128611510">512-861-1510</a>
        </To>
        <Content>
          <Buttons>
            <Button variant="secondary" onClick={onClose} disabled={loading}>
              Back
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={loading}
              loading={loading}
              onClick={onOrder}
            >
              Order Supplies
            </Button>
          </Buttons>
          {error && <Error>{error}</Error>}
        </Content>
      </Wrapper>
    </Modal>
  );
};

export default ContactSupport;

const IconWrapper = styled.div`
  position: relative;
`;
const Message = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #0b0b0b;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const SalesRep = styled.div``;
const To = styled.div`
  display: flex;
  gap: 20px;
  font-family: Poppins, serif;
  font-style: normal;
  font-size: 12px;
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;
const StyledSelect = styled(Select)`
  min-height: 40px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 290px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
