import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import { rgba } from "polished";
import Button from "../../../../shared/components/Button";
import { ReactComponent as DownloadImg } from "../../../../shared/assets/icons/Action/Download Cloud.svg";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ReactComponent as CreditCardImg } from "../../../../shared/assets/icons/Other/Credit Card.svg";
import { ReactComponent as ACHImg } from "../../../../shared/assets/icons/wallet.svg";
import Radio from "../../../../shared/components/Radio";
import TextInput from "../../../../shared/components/TextInput";
import {
  InputWrapper,
  Wrapper as TextInputWrapper,
} from "../../../../shared/components/TextInput/TextInput";
import Select from "../../../../shared/components/Select";
import { useForm } from "react-hook-form";
import API from "../../../../utils/api";
import VerifyACH from "../../../../components/Modals/VerifyACH";
import States from "../../../../utils/states";
import { useNavigate } from "react-router";
import Modal from "../../../../shared/components/Modal";

const stripeOptions = {
  style: {
    base: {
      color: "#32325d",

      fontFamily: '"Poppins", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontWeight: 600,
      fontSize: "12px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const BillingInformation = () => {
  const { setTitle } = useTitle();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currentMethod, setCurrentMethod] = useState(null);
  const [openVerify, setOpenVerify] = useState(false);
  const [error, setError] = useState("");
  const [modal, setModal] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // Form handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const getCurrentMethod = async () => {
    try {
      const response = await API.get("/billing/details");
      if (typeof response.data == "object") {
        setCurrentMethod(response.data);
      }
    } catch (e) {}
  };

  // Bank account submission
  const bankSubmit = async ({ accountName, routingNumber, accountNumber, accountType }) => {
    setLoading(true);
    setError("");

    try {
      // Process the stripe request
      const { token, error } = await stripe.createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: routingNumber,
        account_number: accountNumber,
        account_holder_name: accountName,
        account_holder_type: "company",
      });

      if (error) {
        setError(error.message);
      }

      if (token) {
        const response = await API.put("/billing/details", {
          token: token.id,
          type: token.type,
        });
        await getCurrentMethod();
        setPaymentMethod("");
        setModal("success");
      }
    } catch (e) {}

    setLoading(false);
  };

  // Credit card submission
  const cardSubmit = async (data) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    setError("");

    try {
      // Process the stripe request
      const cardElement = elements.getElement("card");
      const { token, error } = await stripe.createToken(cardElement, {
        name: `${data.firstName} ${data.lastName}`,
        address_line1: data.address,
        address_city: data.city,
        address_state: data.state,
        address_zip: data.zip,
        address_country: "US",
      });

      if (error) {
        setError(error.message);
      }

      if (token) {
        const response = await API.put("/billing/details", {
          token: token.id,
          type: token.type,
        });
        await getCurrentMethod();
        setPaymentMethod("");
        setModal("success");
      }
    } catch (e) {}

    setLoading(false);
  };

  const confirmACH = () => {
    getCurrentMethod().then(() => setOpenVerify(false));
    setModal("success");
  };

  // Set page title
  useEffect(() => {
    setTitle({ name: "Billing Information", back: "settings" });
  }, [setTitle]);

  // Reset forms on change
  useEffect(() => {
    reset({});
    reset2({});
  }, [paymentMethod]);

  // Fetch current method on page load
  useEffect(() => {
    getCurrentMethod().then(() => {
      setLoaded(true);
    });
  }, []);

  if (loaded) {
    return (
      <Wrapper>
        {modal === "success" && (
          <Modal onClose={() => setModal("")} open>
            <Header>
              <h3>Payment Method</h3>
            </Header>
            <ModalContent style={{ maxWidth: "360px" }}>
              <p>
                <strong>Your payment information was updated.</strong>
                <br />
                Would you like to view standard pricing and see about saving money through a
                pre-buy?
              </p>
            </ModalContent>
            <Buttons>
              <Button variant="secondary" type="button" onClick={() => navigate("/dashboard")}>
                Dashboard
              </Button>
              <Button variant="secondary" type="button" onClick={() => navigate("/orders/new")}>
                Place Order
              </Button>
              <Button type="button" onClick={() => navigate("/settings/billing/pricing")}>
                Pricing
              </Button>
            </Buttons>
          </Modal>
        )}
        {openVerify && <VerifyACH onLater={() => setOpenVerify(false)} onUpdate={confirmACH} />}
        <Header>
          {currentMethod && (
            <CurrentMethod>
              <div>
                <MethodDetails>
                  <h3>Current Payment Method</h3>
                  <span>{currentMethod.description}</span>
                </MethodDetails>
                {currentMethod.verified ? (
                  <MethodStatus verified>Verified</MethodStatus>
                ) : (
                  <MethodStatus onClick={() => setOpenVerify(true)}>Click to Verify</MethodStatus>
                )}
              </div>
            </CurrentMethod>
          )}
          <h4>Update Billing/Payment Method:</h4>
          <div>
            <StyledRadio
              label
              checked={paymentMethod === "credit"}
              onChange={() => setPaymentMethod("credit")}
            >
              <span>
                <CreditCardImg />
                Credit Card
              </span>
            </StyledRadio>
            <StyledRadio
              label
              checked={paymentMethod === "ach"}
              onChange={() => setPaymentMethod("ach")}
            >
              <span>
                <ACHImg />
                ACH Payment
              </span>
            </StyledRadio>
          </div>
        </Header>
        {paymentMethod === "credit" && (
          <form onSubmit={handleSubmit2(cardSubmit)}>
            <PaymentDetailWrapper>
              <div>
                <h4>Personal Info</h4>
                <Content>
                  <Row>
                    <TextInput
                      id={"firstName"}
                      title={"First Name"}
                      placeholder={"enter first name"}
                      {...register2("firstName", { required: true })}
                    />
                    <TextInput
                      id={"lastName"}
                      title={"Last Name"}
                      placeholder="enter last name"
                      {...register2("lastName", { required: true })}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      id={"clinicName"}
                      title={"Clinic Name (Optional)"}
                      placeholder={"enter clinic name"}
                      {...register2("clinicName")}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      id={"address"}
                      title={"address"}
                      placeholder={"address"}
                      {...register2("address", { required: true })}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      id={"city"}
                      placeholder={"city"}
                      {...register2("city", { required: true })}
                    />
                  </Row>
                  <Row>
                    <StyledSelect
                      style={{ flex: 1 }}
                      required
                      disable={loading}
                      error={errors.state !== undefined || error}
                      disabled={loading}
                      {...register2("state", { required: true })}
                    >
                      <option value="">- State -</option>
                      {States.map((state) => (
                        <option value={state.abbreviation}>{state.name}</option>
                      ))}
                    </StyledSelect>
                  </Row>
                  <Row>
                    <TextInput
                      id={"zip"}
                      placeholder={"zip"}
                      {...register2("zip", { required: true })}
                    />
                  </Row>
                </Content>
              </div>
              <div>
                <h4>Credit Card Info</h4>
                <Content>
                  <Row>
                    <TextInputWrapper>
                      <label>Credit Card Details</label>
                      <InputWrapper>
                        <StripeWrapper>
                          <CardElement options={stripeOptions} />
                        </StripeWrapper>
                      </InputWrapper>
                    </TextInputWrapper>
                  </Row>
                </Content>
              </div>
            </PaymentDetailWrapper>
            <Footer>
              <Button disabled={loading} variant="secondary" onClick={() => setPaymentMethod("")}>
                Cancel
              </Button>
              <Button disabled={loading} loading={loading}>
                Save Changes
              </Button>
            </Footer>
            {error && <Error>{error}</Error>}
          </form>
        )}
        {paymentMethod === "ach" && (
          <form onSubmit={handleSubmit(bankSubmit)}>
            <PaymentDetailWrapper>
              <div>
                <h4>Account Info</h4>
                <Content>
                  <Row>
                    <TextInputWrapper>
                      <label>ACCOUNT TYPE</label>
                      <StyledSelect
                        disabled={loading}
                        {...register("accountType", { required: true })}
                      >
                        <option value="checking">Business Checking</option>
                        <option value="savings">Business Savings</option>
                      </StyledSelect>
                    </TextInputWrapper>
                  </Row>
                  <Row>
                    <TextInput
                      disabled={loading}
                      id={"accountName"}
                      title={"Account Holder Name"}
                      placeholder={"enter account holder’s name"}
                      {...register("accountName", { required: true })}
                    />
                  </Row>
                </Content>
              </div>
              <div>
                <h4>Bank Details</h4>
                <Content>
                  <Row>
                    <TextInput
                      disabled={loading}
                      id={"accountNumber"}
                      title={"Account Number"}
                      placeholder={"enter account number"}
                      {...register("accountNumber", { required: true })}
                    />
                  </Row>
                  <Row>
                    <TextInput
                      disabled={loading}
                      id={"routingNumber"}
                      title={"Routing Number"}
                      placeholder={"enter routing number"}
                      {...register("routingNumber", { required: true })}
                    />
                  </Row>
                </Content>
              </div>
            </PaymentDetailWrapper>
            <Footer>
              <Button disabled={loading} variant="secondary" onClick={() => setPaymentMethod("")}>
                Cancel
              </Button>
              <Button disabled={loading} type="submit" loading={loading}>
                Save Changes
              </Button>
            </Footer>
            {error && <Error>{error}</Error>}
          </form>
        )}
      </Wrapper>
    );
  }

  return null;
};

const MethodDetails = styled.div`
  display: flex;
  gap: 20px;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const MethodStatus = styled.div`
  cursor: pointer;
  // Method is verified
  ${({ verified }) =>
    verified &&
    css`
      background-color: #00fcb6;
      color: #0b0b0b;
      cursor: default;
    `}
  // Method is not verified
  ${({ verified }) =>
    !verified &&
    css`
      background-color: #000000;
      color: #ffffff;
    `}
  box-sizing: border-box;
  border-radius: 8px;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
`;

const CurrentMethod = styled.div`
  font-family: Poppins, serif;
  background-color: rgba(186, 196, 207, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: space-between;
    flex: 1;
  }
`;

const StyledSelect = styled(Select)`
  background-color: #ffffff;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
`;

const StripeWrapper = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Error = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
`;

const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  > button {
    width: 100%;
    max-width: 200px;
    justify-content: center;
  }
`;

const Header = styled.div`
  > div {
    display: flex;
    gap: 20px;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PaymentDetailWrapper = styled.div`
  display: flex;
  gap: 20px;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  h4 {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 30px;
  > div {
    flex: 1;
  }
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const ModalContent = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledRadio = styled(Radio)`
  flex: 1;
  max-width: 320px;
  & > label > span {
    display: flex;
    align-items: center;
    gap: 15px;
    & > svg {
      height: 30px;
      width: 30px;
    }
  }
`;

export default BillingInformation;
