import styled, { css } from "styled-components";
import { ReactComponent as NotificationImg } from "../../shared/assets/icons/Action/Notification On.svg";
import { ReactComponent as CriticalImg } from "../../shared/assets/icons/Triangle/triangle.svg";
import { ReactComponent as DeleteImg } from "../../shared/assets/icons/Other/Delete 2.svg";
import API from "../../utils/api";
import { useEffect, useState, useCallback, useMemo } from "react";
import { ActionButton, ActionItem } from "../../shared/components/ActionButton";
import Api from "../../utils/api";

const NotificationList = ({
  mode = "recent",
  filter,
  limit,
  refreshNotifications,
  criticalOnly,
  enableActions,
  paging,
}) => {
  const [notifications, setNotifications] = useState({ data: [], page: 1 });
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState("");

  const fetchNotifications = useCallback(
    async (p) => {
      try {
        let path = `/notifications?mode=${mode}&page=${p || page}`;
        if (filter && filter.from && filter.to) {
          path = path + `&from=${filter.from}&to=${filter.to}`;
        }
        if (limit) {
          path = path + `&limit=${limit}`;
        }
        if (criticalOnly) {
          path = path + `&type=critical`;
        }
        const response = await API.get(path);
        setNotifications(response.data);
        setPage(response.data.page);
        setLoaded(true);
      } catch (e) {}
    },
    [notifications, page]
  );

  const setNextPage = useCallback(
    (p) => {
      setPage(p);
      fetchNotifications(p);
    },
    [fetchNotifications]
  );

  const getPages = useCallback(() => {
    return (
      <Paging>
        <div>Results: {notifications.results}</div>
        <Pagination>
          {notifications.page <= notifications.pages && notifications.page !== 1 && (
            <Page onClick={() => setNextPage(page - 1)}>&laquo;</Page>
          )}
          <span>
            Page {notifications.page} of {notifications.pages}
          </span>
          {notifications.page < notifications.pages && (
            <Page onClick={() => setNextPage(page + 1)}>&raquo;</Page>
          )}
        </Pagination>
      </Paging>
    );
  }, [notifications, page, setNextPage]);

  useEffect(() => {
    fetchNotifications();
    if (refreshNotifications) refreshNotifications.current = fetchNotifications;
  }, [filter]);

  return (
    <Wrapper>
      {notifications.data.map((notification) => (
        <Notification critical={notification.critical}>
          <div>{notification.critical ? <CriticalImg /> : <NotificationImg />}</div>
          <div>
            <span
              style={{ display: "block" }}
              dangerouslySetInnerHTML={{ __html: notification.message }}
            />
            <span>{new Date(notification.created).toLocaleString()}</span>
          </div>
        </Notification>
      ))}
      {loaded && notifications.data.length === 0 && <p>No notifications found.</p>}
      {paging && notifications.data.length > 0 && getPages()}
    </Wrapper>
  );
};

const Page = styled.div`
  color: black;
  text-decoration: none;
  transition: background-color 0.3s;
  font-size: 14px;
  ${({ active }) =>
    !active &&
    css`
      :hover {
        background-color: #ddd;
        cursor: pointer;
      }
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #4caf50;
      color: white;
    `}
`;

const Pagination = styled.div`
  display: flex;
  & * {
    padding: 8px 8px;
  }
  gap: 5px;
`;

const Paging = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  > :nth-child(1) {
    padding: 8px 8px;
  }
`;

const Wrapper = styled.div`
  font-family: Poppins, serif;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Action = styled.div`
  position: absolute;
  right: 10px;
`;

const Notification = styled.div`
  display: flex;
  align-items: center;
  min-height: 70px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #0b0b0b;
  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #114377;
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 20px;
    margin-right: 20px;
    svg {
      stroke: white;
      height: 20px;
      width: 20px;
    }
  }
  ${Action} {
    display: none;
  }
  :hover {
    ${Action} {
      display: block;
    }
  }
  > div span:nth-child(2) {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #4f4f4f;
  }
  span:first-child {
    display: flex;
    align-items: center;
  }
  ${({ critical }) =>
    critical &&
    css`
      background: rgba(241, 55, 55, 0.1);
      > div:first-child {
        background-color: #f13737;
      }
    `}
`;

export default NotificationList;
