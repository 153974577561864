import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Loader, { LoaderWrap } from "../Loader/Loader";

const Button = (props) => {
  const { variant, children, onClick, circle, className, loading } = props;
  const loaderColor = variant === "outlined" ? "#114377" : "#FFFFFF";
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      className={className}
      circle={circle}
      loading={loading}
      {...props}
    >
      {loading && <Loader color={loaderColor} />}
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  position: relative;

  ${(props) =>
    props.circle &&
    css`
      font-family: Poppins, serif;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      height: 44px;
      width: 44px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      border-radius: 22px;
      svg:first-child {
        height: 20px;
        width: 20px;
        margin: 0;
      }
    `}

  ${(props) =>
    !props.circle &&
    css`
      font-family: Poppins, serif;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      height: 44px;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      display: inline-flex;
      border-radius: 8px;
      svg:first-child {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
    `}

  :hover {
    box-shadow: none;
  }

  ${(props) =>
    (props.variant === "primary" || props.variant === undefined) &&
    css`
      background: ${props.theme.colors.blue.light.primary};
      box-shadow: 2px 5px 8px ${props.theme.colors.blue.dark.primary};
      color: #ffffff;
      svg:first-child {
        stroke: #ffffff;
        & .svgoverride {
          fill: white;
        }
      }
      &:hover {
        background-color: ${props.theme.colors.blue.light.secondary};
      }
      &:disabled {
        background-color: #bac4cf;
        box-shadow: none;
        cursor: default;
      }
    `}

  ${(props) =>
    props.variant === "green" &&
    css`
      background: ${props.theme.colors.green.primary};
      box-shadow: 2px 5px 8px ${props.theme.colors.blue.dark.primary};
      color: #ffffff;
      svg:first-child {
        stroke: #ffffff;
        & .svgoverride {
          fill: white;
        }
      }
      &:hover {
        background-color: "darken(${props.theme.colors.green.primary}, 50%)";
      }
      &:disabled {
        background-color: #bac4cf;
        box-shadow: none;
        cursor: default;
      }
    `}

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background: #ffffff;
      box-shadow: 2px 3px 10px ${props.theme.colors.blue.dark.primary};
      color: ${props.theme.colors.blue.light.primary};
      &:hover {
        background: #f8f9fb;
      }
      &:active {
        background-color: ${props.theme.colors.blue.light.secondary};
        color: #ffffff;
        svg:first-child {
          stroke: #ffffff;
          & .svgoverride {
            fill: white;
          }
        }
      }
      svg:first-child {
        stroke: ${props.theme.colors.blue.light.primary};
        & .svgoverride {
          fill: ${props.theme.colors.blue.light.primary};
        }
      }
    `}

  ${(props) =>
    props.variant === "outlined" &&
    css`
      background: #ffffff;
      color: ${props.theme.colors.blue.light.primary};
      border: 1px solid #114377;
      &:hover {
        background: #f8f9fb;
      }
      &:active {
        background-color: ${props.theme.colors.blue.light.secondary};
        color: #ffffff;
        svg:first-child {
          stroke: #ffffff;
          & .svgoverride {
            fill: white;
          }
        }
      }
      svg:first-child {
        stroke: ${props.theme.colors.blue.light.primary};
        & .svgoverride {
          fill: ${props.theme.colors.blue.light.primary};
        }
      }
    `}

  ${(props) =>
    props.loading &&
    css`
      > :not(${LoaderWrap}) {
        opacity: 0;
      }
      color: transparent;
    `}
`;

Button.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "secondary", "outlined"]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  circle: PropTypes.bool,
  "data-id": PropTypes.string,
};

export default Button;
