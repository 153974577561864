import styled, { css } from "styled-components";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import ReactSelect, { components } from "react-select";
import { ReactComponent as DownArrowImg } from "../../../../shared/assets/icons/Chevron/Down.svg";
import { ReactComponent as SearchImg } from "../../../../shared/assets/icons/Other/Search.svg";
import { ReactComponent as HR5Img } from "../../../../shared/assets/icons/hr5circle.svg";
import { ReactComponent as FamImg } from "../../../../shared/assets/icons/famcircle.svg";
import { ReactComponent as CovidImg } from "../../../../shared/assets/icons/covidcircle.svg";
import Radio from "../../../../shared/components/Radio";
import Switch from "../../../../shared/components/Switch/Switch";
import GetInitials from "../../../../shared/utilities/GetInitials";
import TextInput from "../../../../shared/components/TextInput";
import States from "../../../../utils/states";
import { useForm, Controller, get } from "react-hook-form";
import Button from "../../../../shared/components/Button";
import { Row } from "../../../../shared/styled/Flexbox";
import Select from "../../../../shared/components/Select";
import PatientSearch from "../../../../components/Modals/PatientSearch";
import Api from "../../../../utils/api";
import OrderComplete from "../../../../components/Modals/OrderComplete/OrderComplete";
import TextMaskInput from "../../../../shared/components/TextMaskInput";
import Modal from "../../../../shared/components/Modal";
import { useLocation, useNavigate } from "react-router";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Prebuy from "../../../../components/Modals/Prebuy";
import { useCookies } from "react-cookie";
import MissingBilling from "../../../../components/Modals/MissingBilling/MissingBilling";
import PatientContactAlert from "../../../../components/Modals/PatientContactAlert/PatientContactAlert";
import Checkbox from "../../../../shared/components/Checkbox";
import AtHomeConfirmation from "../../../../components/Modals/AtHomeConfirmation/AtHomeConfirmation";
import API from "../../../../utils/api";
import { MainStore } from "../../../../stores/main";
import PrebuyFailure from "../../../../components/Modals/PrebuyFailure";
import PrebuyOffer from "../../../../components/Modals/PrebuyOffer";
import PatientProfileConfirmation from "../../../../components/Modals/PatientProfileConfirmation";

const DownArrow = styled(DownArrowImg)`
  stroke: ${({ focused }) => (focused ? "#114377" : "#BAC4CF")};
  :hover {
    stroke: #114377;
  }
`;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DownArrow focused={props.isFocused} />
    </components.DropdownIndicator>
  );
};

const UserIconSingleValue = (props) => (
  <components.SingleValue {...props}>
    <UserWrap focused={props.isFocused}>
      <Avatar>{GetInitials(props.data.label)}</Avatar>
      {props.data.label}
    </UserWrap>
  </components.SingleValue>
);

const UserIconOption = (props) => (
  <components.Option {...props}>
    <UserWrap focused={props.isFocused}>
      <Avatar>{GetInitials(props.data.label)}</Avatar>
      {props.data.label}
    </UserWrap>
  </components.Option>
);

const TestIconSingleValue = (props) => (
  <components.SingleValue {...props}>
    <UserWrap focused={props.isFocused}>
      {props.data.value === "hr5" && <HR5Img height={30} width={30} />}
      {props.data.value === "fam" && <FamImg height={30} width={30} />}
      {props.data.value === "covid-19" && <CovidImg height={30} width={30} />}
      {props.data.label}
    </UserWrap>
  </components.SingleValue>
);

const TestIconOption = (props) => (
  <components.Option {...props}>
    <UserWrap focused={props.isFocused}>
      {props.data.value === "hr5" && <HR5Img height={30} width={30} />}
      {props.data.value === "fam" && <FamImg height={30} width={30} />}
      {props.data.value === "covid-19" && <CovidImg height={30} width={30} />}
      {props.data.label}
    </UserWrap>
  </components.Option>
);

// Set minimum date
const now = new Date();

// Extract the year, month, and day
const year = now.getFullYear();
const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
const day = String(now.getDate()).padStart(2, "0");

// Combine them into the desired format
const maxDate = `${year}-${month}-${day}`;

// TODO: Prevent duplicate patients

const NewOrder = () => {
  const { setTitle } = useTitle();
  const [testLocation, setTestLocation] = useState("clinic");
  const [lockStatus, setLockStatus] = useState("full");
  const [openModal, setOpenModal] = useState({ modal: "", data: {} });
  const [patientId, setPatientId] = useState("");
  const [tempOrder, setTempOrder] = useState({});
  const [error, setError] = useState("");
  const [customError, setCustomError] = useState("");
  const [loading, setLoading] = useState(false);
  const [atHomeChecked, setAtHomeChecked] = useState(false);
  const [currentClinic, setCurrentClinic] = useState(null);
  const [testingPs, setTestingPs] = useState([]);
  const [credits, setCredits] = useState();
  const [currentOrder, setCurrentOrder] = useState({});
  const [offer, setOffer] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies, setCookie] = useCookies(["creditReminder"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { sso } = useContext(MainStore);
  const { state } = useLocation();

  // if sso is true, set min date to 1900-01-01, otherwise set it to january 1st of the prior year
  let minDate;
  if (sso) {
    minDate = new Date("01/01/1900").toISOString().split("T")[0];
  } else {
    minDate = new Date(new Date().getFullYear() - 1, 0, 1).toISOString().split("T")[0];
  }

  // Form handling
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  // Get Gender
  const watchGender = watch("gender");

  const testType = watch("test_type");

  const sendOrder = useCallback(
    async (data, override) => {
      const formValues = getValues();

      if (testLocation === "at_home" && !atHomeChecked) {
        return;
      }

      if (!override) {
        switch (currentClinic.settings.result_method) {
          case "sms, email":
            if (formValues.email === "" && formValues.phone === "") {
              setOpenModal({ modal: "contact_info", mode: currentClinic.settings.result_method });
              return;
            }
            break;
          case "email":
            if (formValues.email === "") {
              setOpenModal({ modal: "contact_info", mode: currentClinic.settings.result_method });
              return;
            }
            break;
          case "sms":
            if (formValues.phone === "") {
              setOpenModal({ modal: "contact_info", mode: currentClinic.settings.result_method });
              return;
            }
            break;
        }
      }

      // append additional data to
      data.test_location = testLocation;
      setLoading(true);

      // if not override, convert dob_m/d/y to dob
      if (!data.override) {
        const newDate = new Date(`${data.dob_y}-${data.dob_m}-${data.dob_d}`);
        data.dob = newDate.toISOString().split("T")[0];
        delete data.dob_m;
        delete data.dob_d;
        delete data.dob_y;
      }

      // Did they send a patient?
      if (!data.override && (data.patient_id === "" || data.patient_id === undefined)) {
        const searchData = data;
        const response = await Api.post("/patients/search", searchData);
        if (response.data && response.data.data.length > 0) {
          setLoading(false);
          patientSearch(true, response.data.data, data);
          return;
        }
      }

      if (data["existing_profile_type"] && data["existing_profile_type"] !== data["profile_type"]) {
        setOpenModal({ modal: "profile_confirm", data: { profile_type: data["profile_type"] } });
        return;
      }

      try {
        if (id) {
          await Api.put(`/orders/${id}`, data);
          setOpenModal({ modal: "updated", data });
        } else {
          await Api.post("/orders", data);
          setOpenModal({ modal: "completed", data });
          const newCredits = await getCurrentClinic();
          if (newCredits.settings.prebuy_autofill && newCredits.credits.hr5 <= 0) {
            await doPrebuy();
          }
        }
        clearPatient();
        setError("");
        setCustomError("");
        setAtHomeChecked(false);
      } catch (e) {
        if (e.response.data.error) {
          setError(e.response.data.error);
          switch (e.response.data.error) {
            case "Missing payment method":
            case "Past due balance":
              setCustomError(e.response.data.error);
              break;
            default:
              setCustomError("");
              break;
          }
        }
      }
      setLoading(false);
    },
    [currentClinic, testLocation, atHomeChecked, credits]
  );

  const doPrebuy = async () => {
    try {
      await Api.post("/billing/prebuy/renew", {});
    } catch (e) {
      console.log(e);
    }
  };

  // Do patient search
  const patientSearch = (override = false, data, orderData) => {
    const formValues = getValues();
    setOpenModal({
      modal: "patient_search",
      data: {
        data: data,
        override: override,
        orderData: orderData,
        search: {
          first: formValues["first"],
          last: formValues["last"],
          dob: formValues["dob"],
          gender: formValues["gender"],
          ext_patient_id: formValues["ext_patient_id"],
          profile_type: formValues["profile_type"],
        },
      },
    });
  };

  const _selectPatient = useCallback(
    async (patient, over, orderData) => {
      try {
        setOpenModal({ modal: "", data: {} });
        if (patient) {
          const {
            first,
            last,
            dob,
            gender,
            profile_type,
            ext_patient_id,
            phone,
            email,
            address,
            city,
            state,
            zip,
            id,
          } = patient;

          let newDate = new Date(dob);
          setValue("first", first);
          setValue("last", last);
          setValue("dob_m", newDate.toISOString().split("T")[0].split("-")[1]);
          setValue("dob_d", newDate.toISOString().split("T")[0].split("-")[2]);
          setValue("dob_y", newDate.toISOString().split("T")[0].split("-")[0]);
          setValue("gender", gender);
          setValue("profile_type", profile_type);
          setValue("existing_profile_type", profile_type);
          setValue("ext_patient_id", ext_patient_id);
          setValue("phone", phone);
          setValue("email", email);
          setValue("address", address);
          setValue("city", city);
          setValue("state", state);
          setValue("zip", zip);
          setValue("patient_id", id);
          setPatientId(id);

          const lockResponse = await API.get(`/patients/${id}/editStatus`);
          setLockStatus(lockResponse.data.status);

          if (over && orderData) {
            orderData.first = first;
            orderData.last = last;
            orderData.dob = newDate.toISOString().split("T")[0];
            orderData.gender = gender;
            orderData.profile_type = profile_type;
            orderData.ext_patient_id = ext_patient_id;
            orderData.phone = phone;
            orderData.email = email;
            orderData.address = address;
            orderData.city = city;
            orderData.state = state;
            orderData.zip = zip;
            orderData.patient_id = id;
            orderData.override = true;
          }
        }

        if (over && orderData) {
          orderData.override = true;
          sendOrder(orderData);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [sendOrder]
  );

  const getOffers = async () => {
    try {
      const response = await API.get("/offers");

      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].offers.length > 0) {
          if (response.data[i].max_redemptions > response.data[i].offers[0].redemptions) {
            setOffer({
              code: response.data[i].code,
              offerId: response.data[i]._id,
              max_redemptions: response.data[i].max_redemptions,
            });
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clearPatient = () => {
    setPatientId("");
    setLockStatus("full");
    setValue("first", "");
    setValue("last", "");
    setValue("dob_m", "");
    setValue("dob_d", "");
    setValue("dob_y", "");
    setValue("gender", "");
    setValue("profile_type", "");
    setValue("existing_profile_type", "patient");
    setValue("ext_patient_id", "");
    setValue("phone", "");
    setValue("email", "");
    setValue("address", "");
    setValue("city", "");
    setValue("state", "");
    setValue("zip", "");
    setValue("patient_id", "");
  };

  // Fetch clinic settings
  const getCurrentClinic = async () => {
    try {
      const response = await Api.get("/profile/clinic");
      setCurrentClinic(response.data);
      setValue("test_type", response.data.settings.default_test);
      if (!id) {
        setValue("testing_clinician", response.data.last_testing_clinician);
      }
      setCredits(response.data.credits);
      if (response.data.credits.hr5 < 2 && !id) {
        const today = new Date().getTime();
        let creditReminder = cookies.creditReminder;
        let doReminder = false;
        let autofill = response.data.settings.prebuy_autofill;
        if (!creditReminder) {
          const newReminder = {
            hr5: response.data.credits.hr5,
            reminded: today,
          };
          setCookie("creditReminder", newReminder, { path: "/" });
          creditReminder = newReminder;
          doReminder = true;
        }
        // If they now have 0 credits or it's been 30 minutes
        if (
          doReminder ||
          (creditReminder.hr5 > 0 && response.data.credits.hr5 === 0) ||
          today - creditReminder.reminded > 10080 * 60 * 1000
        ) {
          if (!autofill) {
            //setOpenModal({ modal: "prebuy" });
          }
          const newReminder = {
            hr5: response.data.credits.hr5,
            reminded: new Date().getTime(),
          };
          setCookie("creditReminder", newReminder, { path: "/" });
        }
      }
      if (
        response.data.billing_schedule !== "direct_bill" &&
        response.data.credits.hr5 === 0 &&
        response.data.payment_type !== "bank_account" &&
        !response.data.verified_payment
      ) {
        setOpenModal({ modal: "billing" });
      }
      return response.data;
    } catch (e) {}
  };

  // Fetch testing physicians
  const getTestingPhysicians = async () => {
    try {
      const response = await Api.get("/clinicians");
      const newClinicians = [];
      for (const clinician of response.data) {
        newClinicians.push({ value: clinician.id, label: `${clinician.first} ${clinician.last}` });
      }
      setTestingPs(newClinicians);
    } catch (e) {}
  };

  // Fetch current order
  const getOrder = async (oid) => {
    setLoading(true);
    try {
      const response = await Api.post("/orders/search", { id });
      if (response.data && response.data.data[0]) {
        const ord = response.data.data[0];
        const lockResponse = await API.get(`/patients/${ord.patient.id}/editStatus`);
        setLockStatus(lockResponse.data.status);
        // select patient
        _selectPatient({ ...ord.patient });
        // set data
        let newDate = new Date(ord.collected);
        setValue("barcode", ord.barcode);
        setValue("testing_clinician", ord.primary.id);
        setValue("test_type", ord.test.key, { shouldValidate: true });
        setValue("collected", newDate.toISOString().split("T")[0]);
        setTestLocation(ord.test_location);
        setCurrentOrder(ord);
      } else {
        navigate("/dashboard");
      }
    } catch (e) {}
    setLoading(false);
  };

  const clinicCode = currentClinic ? `${currentClinic?.clinic_code}9999` : "";

  // Change the test location only if they aren't editing
  const changeTestLocation = useCallback(
    (newLoc) => {
      if (!id) {
        setTestLocation(newLoc);
      }
    },
    [id]
  );

  useEffect(() => {
    getOffers();
    getCurrentClinic().then(() => {
      getTestingPhysicians().then(() => {
        if (id) {
          getOrder(id);
        } else {
          // set the collection date to todays date
          let newDate = new Date();
          setValue("collected", newDate.toISOString().split("T")[0]);

          // if there is a patient sent, use it
          if (state?.patient) {
            _selectPatient(state.patient);
          }
        }
      });
    });
  }, [id]);

  // Set page title
  useEffect(() => {
    setTitle({ name: "Order a Test" });
  }, [setTitle]);

  const testTypes = [];
  if (currentClinic?.tests) {
    if (currentClinic.tests.hr5) {
      testTypes.push({ value: "hr5", label: "HR-5" });
    }
    if (currentClinic.tests.fam) {
      testTypes.push({ value: "fam", label: "FAM" });
    }
  }

  useEffect(() => {
    const newCode = searchParams.get("barcode");
    if (newCode) {
      setValue("barcode", newCode);
      searchParams.delete("barcode");
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (testLocation === "at_home") {
      setOpenModal({ modal: "athome_confirm" });
    }
  }, [testLocation]);

  const submitForm = useCallback(
    (e) => {
      e.preventDefault();
      if (testLocation === "at_home" && !atHomeChecked) {
        return;
      }
      handleSubmit(sendOrder)();
    },
    [sendOrder, atHomeChecked, testLocation]
  );

  return (
    <Wrapper as={"form"} onSubmit={submitForm}>
      <CollapseRow style={{ gap: "30px" }}>
        <StyledCol>
          <div>
            <Label required>Select Location</Label>
            <Row>
              <StyledRadio
                label
                checked={testLocation === "clinic"}
                onClick={() => changeTestLocation("clinic")}
              >
                In Clinic
              </StyledRadio>
              <StyledRadio
                label
                checked={testLocation === "at_home"}
                onClick={() => changeTestLocation("at_home")}
              >
                At home
              </StyledRadio>
            </Row>
          </div>
          <CollapseRow>
            <div style={{ flex: 1 }}>
              <Label required>Type of Test</Label>
              <Controller
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <ReactSelect
                      isSearchable={false}
                      options={testTypes}
                      onBlur={onBlur}
                      isDisabled={loading || id}
                      error={errors["test_type"]}
                      value={testTypes.filter((option) => value === option.value)}
                      onChange={(v) => onChange(v.value)}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator,
                        Option: TestIconOption,
                        SingleValue: TestIconSingleValue,
                      }}
                      styles={{
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                          return {
                            ...styles,
                            backgroundColor: isFocused ? "#114377" : null,
                            color: "#FFFFFF",
                          };
                        },
                        control: (provided, state) => ({
                          ...provided,
                          maxWidth: "100%",
                          cursor: "pointer",
                          minHeight: "46px",
                          fontFamily: "Poppins",
                          border: errors["test_type"] ? "1px solid #FF0000" : "1px solid #BAC4CF",
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          boxShadow: "none",
                          "&:hover": { borderColor: "#114377" }, // border style on hover
                        }),
                      }}
                    />
                  );
                }}
                name="test_type"
                control={control}
                defaultValue={null}
                rules={{
                  required: true,
                }}
              />
              {credits !== undefined &&
                credits !== null &&
                testType === "hr5" &&
                testLocation !== "at_home" && (
                  <PrebuyNotice>
                    {credits.hr5} credits remaining{": "}
                    <u onClick={() => navigate("/settings/billing/pricing")}>Pre-buy now</u>
                  </PrebuyNotice>
                )}
            </div>
            {testLocation === "clinic" && (
              <>
                <div style={{ flex: 1 }}>
                  <Label required>Barcode</Label>
                  <TextMaskInput
                    id={"barcode"}
                    mask={clinicCode}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Invalid barcode. Barcodes must contain at least 4 digits."
                      )
                    }
                    disabled={loading || (currentOrder.order_status !== "pending" && id)}
                    alwaysShowMask={true}
                    minLength={currentClinic?.clinic_code.length + 4}
                    pattern={`.{${currentClinic?.clinic_code.length + 4},${
                      currentClinic?.clinic_code.length + 4
                    }}`}
                    error={errors["barcode"]}
                    {...register("barcode", {
                      required: true,
                      minLength: currentClinic?.clinic_code.length + 4,
                    })}
                    maskPlaceholder={null}
                    required
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FullWidthText
                    id={"collected"}
                    title={"Collected"}
                    type="date"
                    required
                    min={minDate}
                    max={maxDate}
                    disabled={loading}
                    data-date-format="MM/dd/yyyy"
                    {...register("collected", { required: true })}
                  />
                </div>
              </>
            )}
          </CollapseRow>
        </StyledCol>
        <StyledCol>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "flex-start",
            }}
          >
            <Label required>Testing Clinician</Label>
            <Controller
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <ReactSelect
                    isSearchable={false}
                    options={testingPs}
                    onBlur={onBlur}
                    disabled={loading}
                    value={testingPs.filter((option) => value === option.value)}
                    defaultValue={testingPs.filter((option) => value === option.value)}
                    onChange={(v) => onChange(v.value)}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator,
                      Option: UserIconOption,
                      SingleValue: UserIconSingleValue,
                    }}
                    styles={{
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                        return {
                          ...styles,
                          backgroundColor: isFocused ? "#114377" : null,
                          color: "#FFFFFF",
                        };
                      },
                      control: (provided, state) => ({
                        ...provided,
                        maxWidth: "100%",
                        cursor: "pointer",
                        minHeight: "46px",
                        fontFamily: "Poppins",
                        border: errors["testing_clinician"]
                          ? "1px solid #FF0000"
                          : "1px solid #BAC4CF",
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        boxShadow: "none",
                        "&:hover": { borderColor: "#114377" }, // border style on hover
                      }),
                    }}
                  />
                );
              }}
              name="testing_clinician"
              control={control}
              defaultValue={null}
              rules={{
                required: true,
              }}
            />
          </div>
        </StyledCol>
      </CollapseRow>
      <HR />
      <CollapseRow style={{ gap: "30px" }}>
        <StyledCol>
          <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Label>Patient Info</Label>
            {patientId && (!id || lockStatus !== "contact_only") && (
              <InternalId>
                Existing Patient <span onClick={clearPatient}> reset </span>
              </InternalId>
            )}
            <input
              type="hidden"
              id={"patient_id"}
              {...register("patient_id")}
              value={patientId}
              defaultValue={patientId}
            />
          </Row>
          <Row>
            <FullWidthText
              {...register("first", { required: true })}
              id={"first"}
              title={"First Name"}
              disabled={loading || lockStatus === "contact_only"}
              placeholder={"enter first name"}
              required
            />
            <FullWidthText
              {...register("last", { required: true })}
              id={"last"}
              title={"Last Name"}
              disabled={loading || lockStatus === "contact_only"}
              placeholder={"enter last name"}
              required
            />
          </Row>
          <Row style={{ justifyContent: "flex-end", alignItems: "center" }}>
            <div style={{ fontSize: "10px", opacity: 0.7, color: "#f13737" }}>
              <strong>REMINDER:</strong> The patient's name on the test order <strong>MUST</strong>{" "}
              match the name written on the barcode sticker. Do <strong>NOT</strong> switch the
              first and last names.
            </div>
            <Button
              variant="primary"
              type="button"
              onClick={() => patientSearch(false)}
              disabled={loading || lockStatus === "contact_only"}
            >
              Search
            </Button>
          </Row>
          <HR />
          <Row>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Label required>Date of Birth</Label>
              <div style={{ flex: 1, display: "flex", flexDirection: "row", gap: "5px" }}>
                <FullWidthSelect
                  variant="primary"
                  {...register("dob_m", { required: true })}
                  disabled={loading || lockStatus === "contact_only"}
                >
                  <option value="">Month</option>
                  <option value="01">01 - Jan</option>
                  <option value="02">02 - Feb</option>
                  <option value="03">03 - Mar</option>
                  <option value="04">04 - Apr</option>
                  <option value="05">05 - May</option>
                  <option value="06">06 - Jun</option>
                  <option value="07">07 - Jul</option>
                  <option value="08">08 - Aug</option>
                  <option value="09">09 - Sep</option>
                  <option value="10">10 - Oct</option>
                  <option value="11">11 - Nov</option>
                  <option value="12">12 - Dec</option>
                </FullWidthSelect>
                <FullWidthSelect
                  variant="primary"
                  {...register("dob_d", { required: true })}
                  disabled={loading || lockStatus === "contact_only"}
                >
                  <option value="">Day</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </FullWidthSelect>
                {/*full width select for year since 1920*/}
                <FullWidthSelect
                  variant="primary"
                  {...register("dob_y", { required: true })}
                  disabled={loading || lockStatus === "contact_only"}
                >
                  <option value="">Year</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
                  <option value="2008">2008</option>
                  <option value="2007">2007</option>
                  <option value="2006">2006</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  <option value="1999">1999</option>
                  <option value="1998">1998</option>
                  <option value="1997">1997</option>
                  <option value="1996">1996</option>
                  <option value="1995">1995</option>
                  <option value="1994">1994</option>
                  <option value="1993">1993</option>
                  <option value="1992">1992</option>
                  <option value="1991">1991</option>
                  <option value="1990">1990</option>
                  <option value="1989">1989</option>
                  <option value="1988">1988</option>
                  <option value="1987">1987</option>
                  <option value="1986">1986</option>
                  <option value="1985">1985</option>
                  <option value="1984">1984</option>
                  <option value="1983">1983</option>
                  <option value="1982">1982</option>
                  <option value="1981">1981</option>
                  <option value="1980">1980</option>
                  <option value="1979">1979</option>
                  <option value="1978">1978</option>
                  <option value="1977">1977</option>
                  <option value="1976">1976</option>
                  <option value="1975">1975</option>
                  <option value="1974">1974</option>
                  <option value="1973">1973</option>
                  <option value="1972">1972</option>
                  <option value="1971">1971</option>
                  <option value="1970">1970</option>
                  <option value="1969">1969</option>
                  <option value="1968">1968</option>
                  <option value="1967">1967</option>
                  <option value="1966">1966</option>
                  <option value="1965">1965</option>
                  <option value="1964">1964</option>
                  <option value="1963">1963</option>
                  <option value="1962">1962</option>
                  <option value="1961">1961</option>
                  <option value="1960">1960</option>
                  <option value="1959">1959</option>
                  <option value="1958">1958</option>
                  <option value="1957">1957</option>
                  <option value="1956">1956</option>
                  <option value="1955">1955</option>
                  <option value="1954">1954</option>
                  <option value="1953">1953</option>
                  <option value="1952">1952</option>
                  <option value="1951">1951</option>
                  <option value="1950">1950</option>
                  <option value="1949">1949</option>
                  <option value="1948">1948</option>
                  <option value="1947">1947</option>
                  <option value="1946">1946</option>
                  <option value="1945">1945</option>
                  <option value="1944">1944</option>
                  <option value="1943">1943</option>
                  <option value="1942">1942</option>
                  <option value="1941">1941</option>
                  <option value="1940">1940</option>
                  <option value="1939">1939</option>
                  <option value="1938">1938</option>
                  <option value="1937">1937</option>
                  <option value="1936">1936</option>
                  <option value="1935">1935</option>
                  <option value="1934">1934</option>
                  <option value="1933">1933</option>
                  <option value="1932">1932</option>
                  <option value="1931">1931</option>
                  <option value="1930">1930</option>
                  <option value="1929">1929</option>
                  <option value="1928">1928</option>
                  <option value="1927">1927</option>
                  <option value="1926">1926</option>
                  <option value="1925">1925</option>
                  <option value="1924">1924</option>
                  <option value="1923">1923</option>
                  <option value="1922">1922</option>
                  <option value="1921">1921</option>
                  <option value="1920">1920</option>
                </FullWidthSelect>
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Label required>Birth Sex</Label>
              <FullWidthSelect
                variant="primary"
                {...register("gender", { required: true })}
                disabled={loading || lockStatus === "contact_only"}
              >
                <option value="" />
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="decline">Decline to specify</option>
              </FullWidthSelect>
            </div>
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Label required>Profile Type</Label>
              <FullWidthSelect
                variant="primary"
                {...register("profile_type", { required: true })}
                disabled={loading || lockStatus === "contact_only"}
              >
                <option value="patient">Patient</option>
                <option value="practice_team_member">Practice Team Member</option>
              </FullWidthSelect>
            </div>
          </Row>
          <Row>
            <FullWidthText
              {...register("ext_patient_id")}
              id={"ext_patient_id"}
              title={"Patient ID"}
              disabled={loading}
              placeholder="enter patient id"
              maxLength={20}
            />
            <TextMaskInput
              id={"phone"}
              {...register("phone", { required: false })}
              placeholder="enter 10-digit number"
              title={"Phone Number"}
              min={9}
              disabled={loading}
              mask={"(999) 999-9999"}
              maskPlaceholder={null}
            />
          </Row>
          <FullWidthText
            {...register("email", { required: false })}
            id={"email"}
            title={"Email address"}
            disabled={loading}
            placeholder="Enter email address"
          />
          {testLocation === "at_home" && (
            <>
              <FullWidthText
                {...register("address")}
                id={"address"}
                title={"Address"}
                disabled={loading}
                required
                placeholder="Street Address"
              />
              <CollapseRow>
                <FullWidthText
                  {...register("city")}
                  id={"city"}
                  placeholder={"City"}
                  required
                  disabled={loading}
                />
                <StyledSelect
                  style={{ flex: 1 }}
                  required
                  error={errors.state !== undefined || error}
                  disabled={loading}
                  {...register("state", { required: true })}
                >
                  <option value="">- State -</option>
                  {States.map((state) => (
                    <option value={state.abbreviation}>{state.name}</option>
                  ))}
                </StyledSelect>
                <FullWidthText
                  {...register("zip")}
                  id={"zip"}
                  required
                  placeholder="zip"
                  disablde={loading}
                />
              </CollapseRow>
            </>
          )}
        </StyledCol>
        <VHR />
        <StyledCol>
          {/*<Row*/}
          {/*  style={{ justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}*/}
          {/*>*/}
          {/*  <Label>Patient Medical History</Label>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>C.V. Disease</span>*/}
          {/*    <Switch {...register("history.cv_disease")} id={"test"} />*/}
          {/*  </SwitchBox>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>Heart Attack</span>*/}
          {/*    <Switch {...register("history.heart_attack")} id={"test2"} />*/}
          {/*  </SwitchBox>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>Ischemic Stroke</span>*/}
          {/*    <Switch {...register("history.ischemic_stroke")} />*/}
          {/*  </SwitchBox>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>Diabetes (I/II)</span>*/}
          {/*    <Switch {...register("history.diabetes")} />*/}
          {/*  </SwitchBox>*/}
          {/*</Row>*/}
          {/*<Row>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>Antibiotic Allergy</span>*/}
          {/*    <Switch {...register("history.antibiotic_allergy")} />*/}
          {/*  </SwitchBox>*/}
          {/*  <SwitchBox>*/}
          {/*    <span>Current Smoker</span>*/}
          {/*    <Switch {...register("history.smoker")} />*/}
          {/*  </SwitchBox>*/}
          {/*</Row>*/}
          {/*{watchGender === "female" && (*/}
          {/*  <Row>*/}
          {/*    <SwitchBox>*/}
          {/*      <span>Pregnant</span>*/}
          {/*      <Switch {...register("history.pregnant")} />*/}
          {/*    </SwitchBox>*/}
          {/*  </Row>*/}
          {/*)}*/}
        </StyledCol>
      </CollapseRow>
      <Row
        style={{
          fontFamily: "Poppins",
          justifyContent: "center",
          marginTop: "20px",
          fontSize: "12px",
        }}
      >
        {testLocation === "at_home" && (
          <Checkbox
            label
            checked={atHomeChecked}
            onChange={() => setAtHomeChecked(!atHomeChecked)}
            required
          >
            I acknowledge I have selected an At Home test
          </Checkbox>
        )}
      </Row>
      <Row
        style={{ gap: "20px", justifyContent: "center", marginTop: "20px", marginBottom: "20px" }}
      >
        <Button variant="secondary" type="button" onClick={() => navigate(-1)} disabled={loading}>
          Cancel
        </Button>
        <Button loading={loading} disabled={loading}>
          {id ? "Save" : "Submit"} Order
        </Button>
      </Row>
      {!customError && error && <Error>{error}</Error>}
      {customError === "Missing payment method" && (
        <Error>
          Please update your payment information &nbsp;
          <Link to={"/settings/billing/edit"}>here</Link>&nbsp; before ordering a new test. Thank
          you.
        </Error>
      )}
      {customError === "Past due balance" && (
        <Error>
          You have a past due invoices. Please take care of them &nbsp;
          <Link to={"/settings/billing/history"}>here</Link>&nbsp; before ordering a new test. Thank
          you.
        </Error>
      )}
      {openModal.modal === "patient_search" && (
        <PatientSearch
          onClose={() => setOpenModal({ modal: "", data: {} })}
          search={openModal.data.search}
          data={openModal.data.data}
          orderData={openModal.data.orderData}
          override={openModal.data.override}
          selectPatient={_selectPatient}
        />
      )}
      {(openModal.modal === "completed" || openModal.modal === "updated") && (
        <OrderComplete
          onClose={() => {
            navigate("/orders/new");
            setValue("barcode", "");
            setOpenModal({ modal: "", data: {} });
            clearPatient();
            setError("");
            setCustomError("");
            getCurrentClinic();
            setAtHomeChecked(false);
          }}
          updated={openModal.modal === "updated"}
          order={openModal.data}
        />
      )}
      {openModal.modal === "prebuy" && offer.code !== "prebuy13" && (
        <Prebuy
          onLater={() => {
            setOpenModal({ modal: "", data: {} });
            getCurrentClinic();
          }}
          onUpdate={() => {
            setOpenModal({ modal: "", data: {} });
            getCurrentClinic();
          }}
        />
      )}
      {openModal.modal === "prebuy" && offer.code === "prebuy13" && (
        <PrebuyOffer
          onLater={() => {
            setOffer({ code: "", offerId: null });
            setOpenModal({ modal: "prebuy", data: {} });
          }}
          onComplete={() => {
            getCurrentClinic();
          }}
          initialScreen={"full_offer"}
          offerId={offer.offerId}
          onDismiss={() => {
            setOpenModal({ modal: "", data: {} });
          }}
          onUpdate={() => navigate("/settings/billing/edit")}
        />
      )}
      {openModal.modal === "billing" && (
        <MissingBilling
          onLater={() => navigate("/dashboard")}
          onUpdate={() => navigate("/settings/billing/edit")}
        />
      )}
      {openModal.modal === "prebuy_failure" && (
        <PrebuyFailure
          onLater={() => {
            setOpenModal({ modal: "", data: {} });
            getCurrentClinic();
          }}
        />
      )}
      {openModal.modal === "contact_info" && (
        <PatientContactAlert
          onSubmit={() => {
            handleSubmit((d) => sendOrder(d, true))();
            setOpenModal({ modal: "", data: {} });
          }}
          onChange={() => {
            setOpenModal({ modal: "", data: {} });
          }}
          mode={openModal.mode}
        />
      )}
      {openModal.modal === "athome_confirm" && (
        <AtHomeConfirmation
          onConfirm={() => {
            setOpenModal({ modal: "", data: {} });
          }}
          onLater={() => {
            setOpenModal({ modal: "", data: {} });
            setTestLocation("clinic");
          }}
        />
      )}
      {openModal.modal === "profile_confirm" && (
        <PatientProfileConfirmation
          onConfirm={() => {
            setValue("existing_profile_type", openModal.data.profile_type);
            handleSubmit((d) => sendOrder(d, true))();
            setOpenModal({ modal: "", data: {} });
          }}
          onLater={() => {
            setLoading(false);
            setOpenModal({ modal: "", data: {} });
          }}
        />
      )}
    </Wrapper>
  );
};

const InternalId = styled.span`
  opacity: 0.7;
  font-size: 10px;
  > span {
    color: #f13737;
    cursor: pointer;
    margin-left: 5px;
  }
`;

const CollapseRow = styled(Row)`
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.tablet} {
    flex-direction: row;
  }
`;

const SwitchBox = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
  height: 50px;
  flex: 1;
  max-width: 240px;
  font-family: Poppins, serif;
  font-size: 12px;
  padding: 10px;
  align-items: center;
`;

const VHR = styled.hr`
  height: 100%;
  width: 2px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
  display: none;
  @media only screen and ${(props) => props.theme.breakpoints.devices.tablet} {
    display: block;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    height: 30px;
    width: 30px;
  }
`;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

const FullWidthSelect = styled(Select)`
  max-height: 44px;
  height: 44px;
  flex: 1;
`;

const FullWidthText = styled(TextInput)`
  flex: 1;
`;

const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;

const StyledCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Poppins, serif;
  ${Row} {
    gap: 20px;
  }
  gap: 20px;
`;

const StyledRadio = styled(Radio)`
  flex: 1;
`;

const StyledRow = styled(Row)`
  > {
    flex: 1;
  }
`;

const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const Avatar = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;

const PrebuyNotice = styled.div`
  margin-top: 10px;
  text-align: right;
  font-size: 10px;
  opacity: 0.5;
  > u {
    cursor: pointer;
  }
`;

const UserWrap = styled.div`
  display: flex;
  gap: 15px;
  font-family: Poppins, serif;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${({ focused }) => (!focused ? "#114377" : "#FFFFFF")};
  cursor: pointer;
  text-transform: capitalize;
`;

const Wrapper = styled.form`
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  gap: 20px;
`;

const Error = styled.div`
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const StyledSelect = styled(Select)`
  min-height: 40px;
`;

export default NewOrder;
