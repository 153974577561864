import styled from "styled-components";
import PropTypes from "prop-types";
import { hideVisually } from "polished";

const Checkbox = ({ className, checked, children, label, error, ...props }) => {
  return (
    <CheckboxContainer className={className}>
      <label>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
        {label && children}
      </label>
      {!label && children}
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  label: PropTypes.bool,
  check: PropTypes.bool,
  error: PropTypes.bool,
};

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  ${hideVisually()};
  left: 10px;
  bottom: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? props.theme.colors.blue.light.primary : "none")};
  border-width: 2px;
  border-style: solid;
  border-color: ${(props) =>
    props.error ? props.theme.colors.red.primary : props.theme.colors.blue.light.primary};
  border-radius: 5px;
  transition: all 150ms;
  margin-right: 10px;
  cursor: pointer;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue.light.primary};
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
`;

export default Checkbox;
