import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LineChart = ({ data, chartStyle }) => {
  const categories = data.map((one) => one.title);
  const seriesData = data.map((one) => one.value);

  const yAxisMax = 500;
  const yAxisMin = 0;

  const chartOptions = {
    chart: {
      fontFamily: "Poppins",
      ...chartStyle,
    },
    title: {
      text: "",
    },

    yAxis: {
      min: yAxisMin,
      tickInterval: 1,
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: 14,
        },
      },
      gridLineColor: "rgba(186,196,207,0.2)",
      plotLines: [{ value: 0, color: "#BAC4CF" }],
    },

    xAxis: {
      categories: categories,
      opposite: true,
      labels: {
        useHTML: true,
        formatter: function () {
          return (
            "<div style='text-align:center;'>" +
            "<div style='font-size:14px;'>" +
            this.value.slice(0, this.value.indexOf(" ")).toUpperCase() +
            "</div>" +
            "<div style='font-size:16px;font-weight:bold;'>" +
            this.value.slice(this.value.indexOf(" ") + 1) +
            "</div>" +
            "</div>"
          );
        },
      },
      lineWidth: 0,
    },

    legend: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, "rgba(130,160,192,0.8)"],
            [1, "rgba(255,255,255,0.2)"],
          ],
        },
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
        lineWidth: 0,
        threshold: null,
      },
      line: {
        lineColor: "#BAC4CF",
        marker: {
          enabled: false,
        },
        enableMouseTracking: false,
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: "area",
        name: "Orders",
        data: seriesData,
        zIndex: -1,
      },
      {
        type: "line",
        name: "Orders",
        lineColor: "#114377",
        lineWidth: 4,
        marker: {
          enabled: true,
          symbol: "circle",
          lineColor: "#fff",
          fillColor: "#1f4273",
          lineWidth: 4,
          radius: 8,
        },
        enableMouseTracking: true,
        data: seriesData,
      },
    ],
    credits: {
      enabled: false,
    },
  };
  return (
    <div
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: "100%", width: "100%" } }}
      />
    </div>
  );
};

export default LineChart;
