import PropTypes from "prop-types";
import { ReactComponent as ErrorImg } from "../../assets/icons/Circle/exp.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & span {
    font-family: Poppins, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: 11px;
    color: ${(props) => props.theme.colors.red.primary};
  }
  > svg {
    stroke: #f13737;
  }
`;

const ErrorText = ({ error }) => {
  return (
    <Wrapper>
      <ErrorImg />
      <span>{error}</span>
    </Wrapper>
  );
};

ErrorText.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorText;
