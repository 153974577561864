import { useContext, useEffect, useState } from "react";
import { useTitle } from "../../../contexts/TitleContext";
import { ReactComponent as UserImg } from "../../../shared/assets/icons/People/User.svg";
import { ReactComponent as MapPinImg } from "../../../shared/assets/icons/Other/Map Pin.svg";
import { ReactComponent as GlobeImg } from "../../../shared/assets/icons/Other/Globe.svg";
import { ReactComponent as MailImg } from "../../../shared/assets/icons/Mail/Mail.svg";
import { ReactComponent as PhoneImg } from "../../../shared/assets/icons/Phone/Defaut.svg";
import { ReactComponent as LockImg } from "../../../shared/assets/icons/Action/Locked.svg";
import { ReactComponent as RightArrowImg } from "../../../shared/assets/icons/Chevron/Right.svg";
import { ReactComponent as ClinicImg } from "../../../shared/assets/icons/Other/Clinic.svg";
import { ReactComponent as NotificationImg } from "../../../shared/assets/icons/Action/Notification On.svg";
import { ReactComponent as ClipboardImg } from "../../../shared/assets/icons/Other/Clipboard.svg";
import { ReactComponent as FileFolderImg } from "../../../shared/assets/icons/File And Folder/File Text.svg";
import { ReactComponent as SendImg } from "../../../shared/assets/icons/Other/Send.svg";
import { ReactComponent as QRImg } from "../../../shared/assets/icons/qr.svg";
import { ReactComponent as PayBillsImg } from "../../../shared/assets/icons/pay bills.svg";
import { ReactComponent as WalletImg } from "../../../shared/assets/icons/wallet.svg";
import { ReactComponent as PersonImg } from "../../../shared/assets/icons/People/User Plus.svg";
import { ReactComponent as PrebuyImg } from "../../../shared/assets/icons/Shop/Prebuy.svg";
import styled from "styled-components";
import PasswordModal from "./PasswordModal";
import { Link } from "react-router-dom";
import Button from "../../../shared/components/Button";
import API from "../../../utils/api";
import DefaultTestType from "../../../components/Modals/Settings/DefaultTestType/DefaultTestType";
import SendTestResults from "../../../components/Modals/Settings/SendTestResults/SendTestResults";
import SystemAlert from "../../../components/Modals/Settings/SystemAlert/SystemAlert";
import BulletinBoard from "../../../components/Modals/Settings/BulletinBoard/BulletinBoard";
import NotificationType from "../../../components/Modals/Settings/NotificationType/NotificationType";
import EditInfo from "../../../components/Modals/Settings/EditInfo/EditInfo";
import { MainStore } from "../../../stores/main";
import FormatPhoneNumber from "../../../shared/utilities/FormatPhone";
import Modal from "../../../shared/components/Modal";
import QRCode from "react-qr-code";
import Logo from "../../../shared/components/Logo";

const Settings = () => {
  const { setTitle } = useTitle();
  const [modal, setModal] = useState("");
  const [profile, setProfile] = useState({});
  const [settings, setSettings] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const { role } = useContext(MainStore);

  // Toggle the modal to open/closed
  const toggleModalOff = () => setModal("");
  const togglePwModal = () => setModal("password");
  const toggleDefaultTestModal = () => setModal("default_test");
  const toggleSendResultMethodModal = () => setModal("result_method");
  const toggleNotificationMethodModal = () => setModal("notification_method");
  const toggleBulletinMethodModal = () => setModal("bulletin_method");
  const toggleNotificationType = () => setModal("notification_type");
  const toggleEditInfo = () => setModal("edit_info");

  const getCurrentProfile = async () => {
    try {
      const response = await API.get("/profile/clinic");
      setProfile(response.data);
    } catch (e) {}
  };

  const updateProfile = (newProfile) => {
    setProfile(newProfile);
    toggleModalOff();
  };

  const getCurrentSettings = async () => {
    try {
      const response = await API.get("/profile/settings");
      setSettings(response.data.settings);
    } catch (e) {}
  };

  // Set page title
  useEffect(() => {
    setTitle("Settings");
  }, [setTitle]);

  // Load page
  // TODO: Handle single failure
  useEffect(async () => {
    await getCurrentProfile();
    await getCurrentSettings();
    setLoaded(true);
  }, []);

  if (loaded) {
    return (
      <ClinicInfo>
        <Title>Clinic Info</Title>
        <Container>
          <Column>
            <FlexSection>
              <UserWrap>
                <Avatar>
                  <ClinicImg />
                </Avatar>
                <Name>{profile.name}</Name>
              </UserWrap>
              {role === "clinic_admin" && (
                <Button variant="secondary" onClick={toggleEditInfo}>
                  Edit Info
                </Button>
              )}
            </FlexSection>
            <Section>
              <Demographics>
                <tbody>
                  <DemoRow>
                    <DemoCol>
                      <UserImg />
                    </DemoCol>
                    <DemoCol>Clinician Name:</DemoCol>
                    <DemoCol>{profile.clinician_name}</DemoCol>
                  </DemoRow>
                  <DemoRow>
                    <DemoCol>
                      <UserImg />
                    </DemoCol>
                    <DemoCol>Account Manager:</DemoCol>
                    <DemoCol>{profile.account_manager || "Unassigned"}</DemoCol>
                  </DemoRow>
                  <DemoRow>
                    <DemoCol>
                      <MapPinImg />
                    </DemoCol>
                    <DemoCol>Address:</DemoCol>
                    <DemoCol>
                      <div>
                        {profile.address_1}
                        {profile.address_2 !== "" ? ", " + profile.address_2 : ""}
                      </div>
                      <div>{`${profile.city}, ${profile.state} ${profile.zip}`}</div>
                    </DemoCol>
                  </DemoRow>
                  <DemoRow>
                    <DemoCol>
                      <MailImg />
                    </DemoCol>
                    <DemoCol>Email Address:</DemoCol>
                    <DemoCol>{profile.email}</DemoCol>
                  </DemoRow>
                  <DemoRow>
                    <DemoCol>
                      <PhoneImg />
                    </DemoCol>
                    <DemoCol>Phone:</DemoCol>
                    <DemoCol>{FormatPhoneNumber(profile.phone)}</DemoCol>
                  </DemoRow>
                  <DemoRow>
                    <DemoCol>
                      <GlobeImg />
                    </DemoCol>
                    <DemoCol>Clinic URL:</DemoCol>
                    <DemoCol>{profile.url}</DemoCol>
                  </DemoRow>
                </tbody>
              </Demographics>
            </Section>
            <SectionHeader>Notification Settings</SectionHeader>
            <ArrowButton onClick={toggleNotificationMethodModal}>
              <NotificationImg />
              <span>Notification Method:</span>
              <span>{settings.notification_method?.toUpperCase() || "NONE"}</span>
              <RightArrowImg />
            </ArrowButton>
            <ArrowButton onClick={toggleBulletinMethodModal}>
              <ClipboardImg />
              <span>Bulletin Board:</span>
              <span>{settings.bulletin_method?.toUpperCase() || "NONE"}</span>
              <RightArrowImg />
            </ArrowButton>
            <ArrowButton onClick={toggleNotificationType}>
              <NotificationImg />
              <span>Notification Type:</span>
              <span>{settings.notification_type?.toUpperCase() || "ALL"}</span>
              <RightArrowImg />
            </ArrowButton>
            {role === "clinic_admin" && (
              <>
                <SectionHeader>Test Orders and Results Settings</SectionHeader>
                <ArrowButton onClick={toggleDefaultTestModal}>
                  <FileFolderImg />
                  <span>Default Test:</span>
                  <span>{profile.settings?.default_test?.toUpperCase() || "HR5"}</span>
                  <RightArrowImg />
                </ArrowButton>
                <ArrowButton onClick={toggleSendResultMethodModal}>
                  <SendImg />
                  <span>Send Results:</span>
                  <span>{profile.settings?.result_method?.toUpperCase() || "MANUAL"}</span>
                  <RightArrowImg />
                </ArrowButton>
              </>
            )}

            <SectionHeader>Current User Settings</SectionHeader>
            <PasswordArrowButton onClick={togglePwModal}>
              <LockImg />
              <span>Password:</span>
              <span>Change Password</span>
              <RightArrowImg />
            </PasswordArrowButton>
          </Column>
          {role === "clinic_admin" && (
            <Column>
              <SectionHeader>Billing Settings</SectionHeader>
              <Link to="billing/history">
                <ArrowButton>
                  <PayBillsImg />
                  <span>Billing History</span>
                  <RightArrowImg />
                </ArrowButton>
              </Link>
              <Link to="billing/edit">
                <ArrowButton>
                  <WalletImg />
                  <span>Manage Billing Information</span>
                  <RightArrowImg />
                </ArrowButton>
              </Link>
              <Link to="billing/pricing">
                <ArrowButton>
                  <PrebuyImg />
                  <span>Pricing & Pre-buy</span>
                  <RightArrowImg />
                </ArrowButton>
              </Link>
              <SectionHeader>Others</SectionHeader>
              <Link to="users">
                <ArrowButton>
                  <PersonImg />
                  <span>Manage Users</span>
                  <RightArrowImg />
                </ArrowButton>
              </Link>
              <Link to="clinicians">
                <ArrowButton>
                  <PersonImg />
                  <span>Manage Clinicians</span>
                  <RightArrowImg />
                </ArrowButton>
              </Link>
              {profile.settings?.public_link && (
                <ArrowButton onClick={() => setShowQR(true)}>
                  <QRImg />
                  <span>View Public QR Code</span>
                  <div />
                </ArrowButton>
              )}
            </Column>
          )}
          {showQR && (
            <FullBack>
              <Modal open onClose={() => setShowQR(false)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Logo variant="dark" />
                  <QRCode value={`https://patient.directdx.app/order/${profile.clinic_code}`} />
                  <h3>Scan me to view</h3>
                </div>
              </Modal>
            </FullBack>
          )}
        </Container>

        {/** MODALS **/}
        {modal === "password" && <PasswordModal onClose={toggleModalOff} />}
        {modal === "default_test" && (
          <DefaultTestType
            onClose={() => {
              getCurrentProfile();
              toggleModalOff();
            }}
            setting={profile.settings.default_test}
          />
        )}
        {modal === "result_method" && (
          <SendTestResults
            onClose={() => {
              getCurrentProfile();
              toggleModalOff();
            }}
            setting={profile.settings.result_method}
          />
        )}
        {modal === "notification_method" && (
          <SystemAlert
            onClose={() => {
              getCurrentSettings();
              toggleModalOff();
            }}
            setting={settings.notification_method}
          />
        )}
        {modal === "bulletin_method" && (
          <BulletinBoard
            onClose={() => {
              getCurrentSettings();
              toggleModalOff();
            }}
            setting={settings.bulletin_method}
          />
        )}
        {modal === "notification_type" && (
          <NotificationType
            onClose={() => {
              getCurrentSettings();
              toggleModalOff();
            }}
            setting={settings.notification_type}
          />
        )}
        {modal === "edit_info" && (
          <EditInfo
            onClose={() => {
              toggleModalOff();
            }}
            onUpdate={updateProfile}
            profile={profile}
          />
        )}
      </ClinicInfo>
    );
  }

  return null;
};

const Column = styled.div`
  flex: 1;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    :first-of-type {
      border-right: 1px solid #bac4cf;
      padding-right: 35px;
    }
    :last-of-type {
      padding-left: 35px;
    }
  }
`;

const ClinicInfo = styled.div`
  background: #114377;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
`;
const Title = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
`;
const Container = styled.div`
  background-color: white;
  border-radius: 20px;
  min-height: 100px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media only screen and ${(props) => props.theme.breakpoints.devices.laptop} {
    flex-direction: row;
  }
`;
const UserWrap = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const Avatar = styled.div`
  min-width: 60px;
  min-height: 60px;
  border-radius: 30px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;
const Name = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const Demographics = styled.table``;
const DemoRow = styled.tr`
  vertical-align: center;
  font-family: Poppins, serif;
  font-size: 12px;
  > :nth-child(1) {
    width: 30px;
  }
  > :nth-child(2) {
    padding-right: 20px;
  }
  > :nth-child(3) {
    font-weight: bold;
  }
`;
const DemoCol = styled.td`
  svg {
    height: 16px;
    width: 16px;
    stroke: ${(props) => props.theme.colors.grey.grey2};
    opacity: 0.6;
  }
`;
const SectionHeader = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey2};
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const Section = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
const ArrowButton = styled.div`
  font-size: 12px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  color: #114377;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  max-width: 510px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  > :nth-child(1) {
    margin-right: 20px;
    height: 16px;
    width: 16px;
    stroke: ${(props) => props.theme.colors.grey.grey2};
    opacity: 0.6;
  }
  > :nth-child(2) {
    min-width: 120px;
    margin-right: 20px;
  }
  > :last-child {
    margin-left: auto;
  }
`;

const PasswordArrowButton = styled(ArrowButton)`
  > :nth-child(3) {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #f13737;
  }
`;

const FlexSection = styled(Section)`
  display: flex;
  align-items: center;
  > :nth-child(1) {
    flex: 1;
  }
`;

const FullBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  top: 0;
  left: 0;
`;

export default Settings;
