import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as CardImg } from "../../../shared/assets/icons/Other/Credit Card.svg";
import styled from "styled-components";
import PropTypes from "prop-types";

const UpdateBilling = ({ onUpdate, onLater }) => {
  return (
    <Modal open>
      <Wrapper>
        <Header>
          <CardImg />
          <h3>Billing Information</h3>
        </Header>
        <Content>Please add a payment method.</Content>
        <Buttons>
          <Button variant="secondary" onClick={onLater}>
            Add Later
          </Button>
          <Button variant="primary" onClick={onUpdate}>
            Proceed
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

UpdateBilling.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onLater: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 360px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default UpdateBilling;
