import styled from "styled-components";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const Switch = forwardRef(
  ({ id, className, name, children, label, error, onClick, ...props }, ref) => {
    return (
      <CheckBoxWrapper className={className} onClick={onClick}>
        <CheckBox id={name} name={name} type="checkbox" ref={ref} {...props} />
        <CheckBoxLabel htmlFor={name} />
      </CheckBoxWrapper>
    );
  }
);

Switch.propTypes = {
  label: PropTypes.bool,
  check: PropTypes.bool,
  error: PropTypes.bool,
};

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 51px;
  height: 31px;
  border-radius: 15px;
  background: #bac4cf;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    margin: 1px;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    transition: 0.2s;
  }
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  height: 31px;
  :focus-within {
    ${CheckBoxLabel} {
      box-shadow: 0 0 0 1px ${(props) => props.theme.colors.blue.light.primary};
    }
  }
  width: 51px;
`;

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 51px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #114377;
    &::after {
      content: "";
      display: block;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

export default Switch;
