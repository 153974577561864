import styled, { css } from "styled-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTitle } from "../../../../contexts/TitleContext";
import { rgba } from "polished";
import Button from "../../../../shared/components/Button";
import { ReactComponent as DownloadImg } from "../../../../shared/assets/icons/Action/Download Cloud.svg";
import { ReactComponent as WalletImg } from "../../../../shared/assets/icons/wallet.svg";
import { Link } from "react-router-dom";
import API, { apiPath } from "../../../../utils/api";
import Select from "../../../../shared/components/Select";
import TextInput, {
  Wrapper as TextInputWrapper,
} from "../../../../shared/components/TextInput/TextInput";
import DateInput from "../../../../shared/components/DateInput";
import { ActionButton, ActionItem } from "../../../../shared/components/ActionButton";
import { ReactComponent as DeleteImg } from "../../../../shared/assets/icons/Other/Delete 2.svg";
import { ReactComponent as PackageImg } from "../../../../shared/assets/icons/Shop/Package.svg";
import { ReactComponent as EditImg } from "../../../../shared/assets/icons/Action/Edit.svg";
import Modal from "../../../../shared/components/Modal";
import Api from "../../../../utils/api";

const defaultSort = { type: "updated", dir: "desc" };

const BillingHistory = () => {
  const { setTitle } = useTitle();
  const [sort, setSort] = useState(defaultSort);
  const [modal, setModal] = useState({ modal: "", data: {} });
  const [transactions, setTransactions] = useState([]);
  const [selected, setSelected] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const fromDate = useRef();
  const toDate = useRef();
  const selectType = useRef();

  const resetFilters = () => {
    fromDate.current.value = "";
    toDate.current.value = "";
    selectType.current.value = "";
  };

  const reattempt = async (id) => {
    setLoading(true);
    setError("");
    try {
      await Api.post("/billing/" + id + "/reattempt", {});
      await getBillingHistory();
      setModal({ modal: "", data: {} });
    } catch (e) {
      if (e.response.data.error) {
        setError(e.response.data.error);
      }
    }
    setLoading(false);
  };

  const getBillingHistory = useCallback(async () => {
    let path = `/billing/history?mode=${sort.type}&dir=${sort.dir}&format=json`;
    if (fromDate.current.value !== "" && toDate.current.value !== "") {
      path = path + `&from=${fromDate.current.value}&to=${toDate.current.value}`;
    }
    if (selectType.current.value !== "") {
      path = path + `&type=${selectType.current.value}`;
    }
    try {
      const response = await API(path);
      setTransactions(response.data);
    } catch (e) {}
  }, [sort]);

  const getSorted = useCallback(
    (name) => {
      if (sort.type !== name) return null;
      if (sort.dir === "asc") return "ascending";
      return "descending";
    },
    [sort]
  );

  const toggleSort = useCallback(
    (name) => {
      let newSort = { type: name, dir: "asc" };
      if (sort.type === name) {
        if (sort.dir === "asc") {
          newSort.dir = "desc";
        } else if (sort.dir === "desc") {
          newSort = { ...defaultSort };
        }
      }
      setSort(newSort);
    },
    [sort]
  );

  const selectRow = (e) => {
    setSelected(e.target.getAttribute("data-id"));
  };

  // Fetch clinicians on page load
  useEffect(() => {
    getBillingHistory();
  }, [sort]);

  // Set page title
  useEffect(() => {
    setTitle({ name: "Billing History", back: "settings" });
  }, [setTitle]);

  const CSVPath = useMemo(() => {
    let csvPath = `${apiPath}/billing/history?mode=${sort.type}&dir=${sort.dir}&format=csv`;
    if (fromDate.current?.value && toDate.current?.value) {
      csvPath = csvPath + `&from=${fromDate.current.value}&to=${toDate.current.value}`;
    }
    if (selectType.current?.value) {
      csvPath = csvPath + `&type=${selectType.current.value}`;
    }
    return csvPath;
  }, [sort]);

  // FIXME: Fix the fact that the date inputs reset on search

  const downloadFile = (transId) => {};

  return (
    <Wrapper>
      {modal.modal === "reattempt" && (
        <Modal onClose={() => setModal({ modal: "", data: {} })} open>
          <Header>
            <h3>Reattempt Transaction</h3>
          </Header>
          <ModalContent style={{ maxWidth: "280px" }}>
            <p>
              <strong>Are you sure you'd like to reattempt?</strong>
              <br />
              This will attempt a payment in the amount of $
              {Number(Number(modal.data.total_cost) / 100).toFixed(2)}.
            </p>
          </ModalContent>
          <Buttons>
            <Button
              variant="secondary"
              type="button"
              loading={loading}
              disabled={loading}
              onClick={() => setModal({ modal: "", data: {} })}
            >
              Later
            </Button>
            <Button
              type="button"
              loading={loading}
              disabled={loading}
              onClick={() => reattempt(modal.data.id)}
            >
              Process
            </Button>
          </Buttons>
        </Modal>
      )}
      <Header>
        <TextInput
          id={"from_date"}
          max="9999-12-31"
          title={"From"}
          type="date"
          data-date-format="MM/dd/yyyy"
          ref={fromDate}
        />
        <TextInput
          id={"to_date"}
          max="9999-12-31"
          title={"To"}
          type="date"
          data-date-format="MM/dd/yyyy"
          ref={toDate}
        />
        <TextInputWrapper>
          <label>Status</label>
          <StyledSelect ref={selectType}>
            <option value="">All</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
          </StyledSelect>
        </TextInputWrapper>
        <Button variant="primary" onClick={getBillingHistory} type="button">
          Search
        </Button>
        <Button variant="secondary" onClick={resetFilters} type="button">
          Reset Filters
        </Button>
        <a href={CSVPath} download>
          <Button variant="secondary" type="button">
            <DownloadImg />
            Download CSV
          </Button>
        </a>
      </Header>
      <ReturnedResults>Displaying {transactions.length} result(s)</ReturnedResults>
      <StyledTable>
        <thead>
          <tr>
            <th />
            <ColHead aria-sort={getSorted("updated")} onClick={() => toggleSort("updated")}>
              Billing Date
            </ColHead>
            <ColHead aria-sort={getSorted("external_id")} onClick={() => toggleSort("external_id")}>
              Payment ID
            </ColHead>
            <ColHead>Description</ColHead>
            <ColHead aria-sort={getSorted("total_cost")} onClick={() => toggleSort("total_cost")}>
              Amount
            </ColHead>
            <ColHead
              aria-sort={getSorted("transaction_status")}
              onClick={() => toggleSort("transaction_status")}
            >
              Status
            </ColHead>
            <th />
          </tr>
        </thead>
        <tbody>
          {transactions.map((trans) => (
            <tr>
              <td />
              <td>{new Date(trans.updated).toLocaleDateString()}</td>
              <td>{trans.external_id}</td>
              <td>{trans.description}</td>
              <td>${Number(Number(trans.total_cost) / 100).toFixed(2)}</td>
              <td>
                <StatusPill status={trans.transaction_status}>
                  {trans.transaction_status.toUpperCase()}
                </StatusPill>
              </td>
              <td>
                {trans.transaction_status === "paid" && (
                  <StyledAction
                    open={selected === trans.id}
                    title="More"
                    id={trans.id}
                    onClick={selectRow}
                    onClose={() => setSelected("")}
                  >
                    <a
                      href={`${apiPath}/billing/history?transaction=${trans.id}&format=csv`}
                      download
                    >
                      <ActionItem>
                        <DownloadImg />
                        Download Details
                      </ActionItem>
                    </a>
                  </StyledAction>
                )}
                {trans.transaction_status === "declined" && (
                  <StyledAction
                    open={selected === trans.id}
                    title="More"
                    id={trans.id}
                    onClick={selectRow}
                    onClose={() => setSelected("")}
                  >
                    <ActionItem onClick={() => setModal({ modal: "reattempt", data: trans })}>
                      <WalletImg />
                      Reattempt
                    </ActionItem>
                  </StyledAction>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </Wrapper>
  );
};

const ColHead = styled.th``;
const StyledDateInput = styled(DateInput)`
  flex: 1;
  max-width: 320px;
`;
const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`;

const StyledSelect = styled(Select)`
  background-color: #ffffff;
  border: 1px solid #bac4cf;
  box-sizing: border-box;
  border-radius: 8px;
`;

const StyledAction = styled(ActionButton)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

const StatusPill = styled.div`
  height: 24px;
  font-family: Poppins, serif;
  font-style: normal;
  width: 100px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  ${(props) =>
    props.status === "paid" &&
    css`
      color: #219653;
      background: #cbffe3;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "pending" &&
    css`
      color: #f2994a;
      background: #fff1c8;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "processing" &&
    css`
      color: #000000;
      background: #dcdcdc;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "failed" &&
    css`
      color: #f13737;
      background: #ffcfcf;
      border-radius: 5px;
    `};

  ${(props) =>
    props.status === "declined" &&
    css`
      color: #f13737;
      background: #ffcfcf;
      border-radius: 5px;
    `};
`;

const StyledTable = styled.table`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  ${StyledAction} {
    opacity: 0;
  }
  thead {
    color: #828282;
    text-transform: uppercase;
    text-align: left;
  }
  tr:last-of-type {
    td {
      border: none;
    }
  }
  thead {
    display: none;
  }
  tr {
    display: flex;
    flex-direction: column;
    border: 1px solid ${rgba("#114377", 0.2)};
    border-radius: 8px;
    margin-bottom: 5px;
    padding: 5px;
  }
  @media only screen and ${(props) => props.theme.breakpoints.devices.tabletS} {
    thead {
      display: revert;
    }
    tr {
      border: none;
      display: revert;
      td:nth-child(3) {
        font-weight: bold;
      }
    }
    td {
      border-collapse: collapse;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      position: relative;
    }
    thead tr th {
      padding-bottom: 10px;
      border-bottom: 1px solid ${rgba("#114377", 0.2)};
      border-collapse: separate;
      border-spacing: 5px 5px;
      cursor: pointer;
      -moz-user-select: none;
      -webkit-user-select: none;
      &:after {
        content: "";
        display: inline-flex;
        margin-left: 5px;
        width: 20px;
        font-size: 8px;
      }
      &[aria-sort="ascending"]:after {
        content: " ▲";
      }
      &[aria-sort="descending"]:after {
        content: " ▼";
      }
    }
    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      border: none;
      width: 10px;
      padding-right: 0;
    }
    tbody tr:hover {
      background-color: #eef2f6;
      td:first-child {
        border-radius: 20px 0 0 20px;
      }
      td:last-child {
        border-radius: 0 20px 20px 0;
      }
      ${StyledAction} {
        opacity: 1;
      }
    }
  }
`;

const ReturnedResults = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #4f4f4f;
`;

const ModalContent = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Wrapper = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid ${(props) => props.theme.colors.grey.grey3};
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 25px;
  gap: 20px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

export default BillingHistory;
