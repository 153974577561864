import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { memo } from "react";

const ActiveCSS = css`
  font-weight: 600;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white.primary};
  background: ${(props) => props.theme.colors.blue.light.primary};
  box-shadow: 2px 5px 8px ${(props) => props.theme.colors.blue.dark.primary};
  svg {
    stroke: #ffffff;
    & .svgoverride {
      fill: white;
    }
  }
`;

const Wrapper = styled.div`
  height: 50px;
  width: 100%;
  border-radius: 10px;
  font-family: Poppins, serif;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 17px;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.theme.colors.black.offBlack};
  ${(props) => props.active && ActiveCSS}
  svg {
    margin-right: 15px;
  }
  span {
    flex: 1;
  }
  &:hover {
    ${ActiveCSS}
  }
`;

const Counter = styled.div`
  height: 30px;
  min-width: 30px;
  border-radius: 15px;
  background-color: #f13737;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
  padding-left: 2px;
`;

const NavigationItem = ({ icon, active, title, counter, onClick, className }) => {
  return (
    <Wrapper active={active} onClick={onClick} className={className}>
      {icon && icon}
      <span>{title}</span>
      {counter && <Counter>{counter}</Counter>}
    </Wrapper>
  );
};

NavigationItem.propTypes = {
  icon: PropTypes.any,
  active: PropTypes.bool,
  title: PropTypes.string,
  counter: PropTypes.number,
};

NavigationItem.defaultProps = {
  title: "Title",
};

export default memo(NavigationItem);
