import styled from "styled-components";
import { forwardRef } from "react";

const Select = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <StyledSelect className={className} {...rest} ref={ref}>
      {children}
    </StyledSelect>
  );
});

const StyledSelect = styled.select`
  background: #f8f9fb;
  border-radius: 8px;
  height: 44px;
  border: none;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 30px;
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #0b0b0b;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 0.8em;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
`;

export default Select;
