import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import NavigationItem from "../../shared/components/NavigationItem";

const StyledNavLink = styled(NavLink)`
  width: 100%;
`;

const StyledLink = styled.a`
  width: 100%;
`;

const NavigationLink = ({ to, title, icon, counter, external, as, className }) => {
  if (external) {
    return (
      <StyledLink href={to} target="_blank">
        <NavigationItem icon={icon} title={title} counter={counter} className={className} />
      </StyledLink>
    );
  }
  return (
    <StyledNavLink to={to} as={as}>
      {({ isActive }) => (
        <NavigationItem
          active={isActive}
          icon={icon}
          title={title}
          counter={counter}
          className={className}
        />
      )}
    </StyledNavLink>
  );
};

NavigationLink.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  counter: PropTypes.number,
  external: PropTypes.bool,
};

export default NavigationLink;
