import styled from "styled-components";
import PropTypes from "prop-types";

const StyledCopyright = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  opacity: 0.7;
  flex-direction: column;
`;

const Copyright = ({ color, className }) => {
  return (
    <StyledCopyright color={color} className={className}>
      <div>Copyright © 2024 Direct Diagnostics.</div>
      <div>All rights reserved.</div>
    </StyledCopyright>
  );
};

export default Copyright;
