import React from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import Portal from "../Portal";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => rgba(props.theme.colors.blue.light.primary, 0.7)};
  backdrop-filter: blur(1px);
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transition-delay: 200ms;
  z-index: 100;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    max-width: fit-content;
    margin: 50px auto;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }

  ${(props) =>
    props.error &&
    css`
      background-color: ${(props) => rgba(props.theme.colors.red.dark, 0.85)};
    `};
`;

const Content = styled.div`
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  min-height: fit-content;
  min-width: fit-content;
  background-color: white;
  font-family: Poppins, serif;
  filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.15));
  border-radius: 15px;
`;

const Modal = ({ open, onClose, locked, children, error }) => {
  // set up active state
  const [active, setActive] = React.useState(false);
  // Make a reference to the backdrop
  const backdrop = React.useRef(null);

  // on mount
  React.useEffect(() => {
    // get dom element from backdrop
    const { current } = backdrop;
    // when transition ends set active state to match open prop
    const transitionEnd = () => setActive(open);
    // when esc key press close modal unless locked
    const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onClose();
    // when clicking the backdrop close modal unless locked
    const clickHandler = (e) => !locked && e.target === current && onClose();

    // if the backdrop exists set up listeners
    if (current) {
      current.addEventListener("transitionend", transitionEnd);
      current.addEventListener("click", clickHandler);
      window.addEventListener("keyup", keyHandler);
    }

    // if open props is true add inert to #root
    // and set active state to true
    if (open) {
      document.activeElement.blur();
      setActive(open);
      document.querySelector("#root").setAttribute("inert", "true");
    }

    // on unmount remove listeners
    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd);
        current.removeEventListener("click", clickHandler);
      }
      document.querySelector("#root").removeAttribute("inert");
      window.removeEventListener("keyup", keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open && "active"} error={error}>
            <Content className="modal-content">{children}</Content>
          </Backdrop>
        </Portal>
      )}
    </>
  );
};

export default Modal;
