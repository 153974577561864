import Modal from "../../../../shared/components/Modal";
import styled, { css } from "styled-components";
import Button from "../../../../shared/components/Button";
import { useState } from "react";
import API from "../../../../utils/api";
import { useForm } from "react-hook-form";
import TextInput from "../../../../shared/components/TextInput";
import Select from "../../../../shared/components/Select";
import TextMaskInput from "../../../../shared/components/TextMaskInput";

const Clinician = ({ onClose, clinician, edit }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const save = async (data) => {
    setLoading(true);
    if (data.active === "true" || data.active === "") data.active = true;
    if (data.active === "false") data.active = false;
    try {
      if (edit) {
        await API.put(`/clinicians/${clinician.id}`, data);
      } else {
        await API.post("/clinicians", data);
      }
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Modal open onClose={onClose}>
      <Wrapper onSubmit={handleSubmit(save)}>
        <Header>
          <SubTitle>MANAGE clinicians</SubTitle>
          <Title>{edit ? "Edit Clinician" : "Add Clinician"}</Title>
        </Header>
        <Content>
          <Row>
            <TextInput
              id={"first"}
              title={"First Name"}
              type="text"
              {...register("first", { required: true })}
              disabled={loading}
              defaultValue={clinician.first}
              error={errors["first"]}
            />
            <TextInput
              id={"last"}
              title={"Last Name"}
              type="text"
              defaultValue={clinician.last}
              {...register("last", { required: true })}
              disabled={loading}
              error={errors["last"]}
            />
          </Row>
          <Row>
            <TextInput
              id={"email"}
              title={"Email"}
              type="text"
              defaultValue={clinician.email}
              {...register("email")}
              disabled={loading}
            />
          </Row>
          {edit && (
              <Row>
                <Select
                    id={"type"}
                    title={"type"}
                    type="text"
                    {...register("type")}
                    disabled={loading}
                    defaultValue={clinician.type}
                >
                  <option value={""}>Select Clinician Type</option>
                  <option value={"primary"}>Primary Clinician</option>
                  <option value={"testing"}>Testing Clinician</option>
                </Select>
              </Row>
          )}
          <input
              type="hidden"
              id="active"
              defaultValue={clinician.active}
              {...register("active")}
          />
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
          <Button type="submit">{edit ? "Save Changes" : "Add Clinician"}</Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default Clinician;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Row = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;
  > div {
    flex: 1;
  }
`;

const Buttons = styled.div`
  margin-top: 20px;
  gap: 10px;
  display: flex;
  button {
    flex: 1;
    justify-content: center;
  }
`;

const Wrapper = styled.form`
  width: 360px;
`;

const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
  margin-bottom: 20px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
