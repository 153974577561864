import TextInput from "../TextInput";
import { ReactComponent as CalendarImg } from "../../assets/icons/icon/calendar 2.svg";
import { forwardRef, useState } from "react";
import Modal from "../Modal";
import styled from "styled-components";
import Button from "../Button";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateInput = forwardRef((props, ref) => {
  const { title, subtitle, defaultValue, inputTitle, ...rest } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(defaultValue);
  const toggleModal = () => {
    setOpen((c) => !c);
  };
  const _onChange = (d) => {
    console.log(d);
    setDate(d);
  };
  return (
    <>
      <StyledTextInput
        title={inputTitle}
        icon={<CalendarImg />}
        iconClick={toggleModal}
        type="date"
        max="9999-12-31"
        data-date-format="MM/dd/yyyy"
        ref={ref}
        readOnly
        {...rest}
      />
      {open && (
        <Modal onClose={toggleModal} open>
          <Wrapper>
            <Header>
              <SubTitle>{subtitle}</SubTitle>
              <Title>{title}</Title>
            </Header>
            <StyledCalendar
              date={date}
              onChange={_onChange}
              showMonthArrow={false}
              width={300}
              color={"#114377"}
            />
            <ButtonsGroups>
              <StyledButton variant="secondary" onClick={toggleModal}>
                Cancel
              </StyledButton>
              <StyledButton>Confirm</StyledButton>
            </ButtonsGroups>
          </Wrapper>
        </Modal>
      )}
    </>
  );
});

const StyledTextInput = styled(TextInput)`
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  input {
    display: flex;
    align-items: center;
  }
`;

const StyledButton = styled(Button)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCalendar = styled(Calendar)({
  fontFamily: "Poppins, serif",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  "&>.rdrMonthAndYearWrapper": {
    paddingTop: 0,
  },
  "&>.rdrMonthsVertical": {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  "&>div:nth-child(2)": {
    "&>span": {
      justifyContent: "space-between",
      padding: 20,
      gap: 20,
      "&>span:nth-child(2)": {
        display: "none",
      },
      "&>span:nth-child(1)": {
        borderRadius: 8,
        border: "1px solid #BAC4CF",
        flexGrow: 1,
        "&>select": {
          width: "100%",
        },
      },
      "&>span:nth-child(3)": {
        borderRadius: 8,
        border: "1px solid #BAC4CF",
        flexGrow: 1,
        "&>select": {
          width: "100%",
        },
      },
    },
  },
  "&>div:nth-child(3)": {
    "&>div": {
      "&>div:nth-child(1)": {
        "&>span": {
          color: "#114377",
          fontSize: "12px !important",
          fontWeight: 600,
          textTransform: "uppercase",
        },
      },
      "&>div:nth-child(2)": {
        "&>.rdrDay": {
          "&>.rdrDayStartPreview": {
            border: "none",
          },
          "&>.rdrDayStartPreview:hover": {
            border: "none",
          },
          "&>.rdrDayEndPreview": {
            border: "none",
          },
          "&>.rdrDayEndPreview:hover": {
            border: "none",
          },

          "&>.rdrSelected": {
            borderRadius: "50%",
            height: 30,
            width: 30,
            left: 9,
            top: 2,
            boxShadow: "2px 3px 10px rgb(17 67 119 / 30%)",
          },
        },
        "&>.rdrDayHovered": {
          "&>.rdrDayNumber:after": {
            display: "none",
          },
        },
        "&>.rdrDayToday": {
          "&>.rdrDayNumber": {
            "&>span::after": {
              display: "none",
            },
          },
        },
      },
    },
  },
});

const Wrapper = styled.div``;
const ButtonsGroups = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  ${Button} {
    width: 100%;
  }
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;

export default DateInput;
