import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import styled from "styled-components";
import PropTypes from "prop-types";

const PatientContactAlert = ({ onSubmit, onChange, mode }) => {
  return (
    <Modal open onClose={onChange}>
      <Wrapper>
        <Header>
          <h3>Patient information missing</h3>
        </Header>
        <>
          <Content>
            {mode === "sms" &&
              "Patient missing phone number. If you do not add one we cannot automatically send the results when complete."}
            {mode === "sms, email" &&
              "Patient missing phone number or email. If you do not add one we cannot automatically send the results when complete."}
            {mode === "email" &&
              "Patient missing email. If you do not add one we cannot automatically send the results when complete."}
          </Content>
          <Buttons>
            <Button variant="secondary" onClick={onSubmit} type="button">
              Maybe Later
            </Button>
            <Button variant="primary" onClick={onChange} type="button">
              Add Now
            </Button>
          </Buttons>
        </>
      </Wrapper>
    </Modal>
  );
};

PatientContactAlert.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 425px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default PatientContactAlert;
