import { ReactComponent as LogoImg } from "../../assets/logo.svg";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Logo.module.css";

const Logo = ({ variant, small }) => {
  return (
    <LogoImg
      className={cx({
        [styles.light]: variant === "light",
        [styles.dark]: variant === "dark",
        [styles.small]: small,
      })}
      title={"Logo"}
    />
  );
};

Logo.propTypes = {
  variant: PropTypes.oneOf(["light", "dark"]),
  small: PropTypes.bool,
};

export default Logo;
