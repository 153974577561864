import Modal from "../../../shared/components/Modal";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import TextInput from "../../../shared/components/TextInput";
import { Row } from "../../../shared/styled/Flexbox";
import Select from "../../../shared/components/Select";
import { ReactComponent as FilterImg } from "../../../shared/assets/icons/Other/Filter.svg";
import Button from "../../../shared/components/Button";
import Switch from "../../../shared/components/Switch/Switch";
import { useCallback, useState } from "react";

const AdvancedPatientSearch = ({ onClose, onSearch, testingClinicians = [], filter = {} }) => {
  // Form handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Search
  const search = useCallback((data) => {
    onSearch(data);
    onClose();
  }, []);

  return (
    <Modal open>
      <Row style={{ alignItems: "center", gap: "10px", marginBottom: "20px" }}>
        <FilterImg />
        <Header>
          <SubTitle>Patients</SubTitle>
          <Title>Advanced Filter</Title>
        </Header>
      </Row>
      <HR />
      <Form onSubmit={handleSubmit(search)}>
        <StyledRow>
          <TextInput
            variant="secondary"
            id={"first"}
            title={"Patient First"}
            defaultValue={filter.first}
            {...register("first")}
          />
          <TextInput
            variant="secondary"
            id={"last"}
            title={"Patient Last"}
            defaultValue={filter.last}
            {...register("last")}
          />
          <TextInput
            variant="secondary"
            id={"dob"}
            max="9999-12-31"
            title={"Date of Birth"}
            type="date"
            data-date-format="MM/dd/yyyy"
            defaultValue={filter.dob}
            {...register("dob")}
          />
        </StyledRow>
        <StyledRow>
          <TextInput
            variant="secondary"
            id={"last_order_date"}
            max="9999-12-31"
            title={"Last Order Date"}
            type="date"
            data-date-format="MM/dd/yyyy"
            defaultValue={filter.last_order_date}
            {...register("last_order_date")}
          />
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Label>Testing Clinician</Label>
            <FullWidthSelect
              variant="primary"
              {...register("testing_clinician")}
              defaultValue={filter.testing_clinician}
            >
              <option></option>
              {testingClinicians.map((clinician) => (
                <option value={clinician.value}>{clinician.label}</option>
              ))}
            </FullWidthSelect>
          </div>
        </StyledRow>
        <HR />
        <ButtonRow>
          <Button variant="secondary" type="button" onClick={onClose}>
            Close
          </Button>
          <Button>Search</Button>
        </ButtonRow>
      </Form>
    </Modal>
  );
};

const ButtonRow = styled.div`
  display: flex;
  gap: 15px;
  > * {
    flex: 1;
    justify-content: center;
  }
`;
const HR = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.grey.grey5};
  border: none;
`;
const FullWidthSelect = styled(Select)`
  max-height: 44px;
  height: 44px;
  flex: 1;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 6px;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;
const StyledRow = styled(Row)`
  gap: 10px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
const Header = styled.div``;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;

export default AdvancedPatientSearch;
