import * as ACTION_TYPES from "../actions/main_types";

export default function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        clinic: action.clinic,
        role: action.role,
        sso: action.sso,
      };
    case ACTION_TYPES.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };
    case ACTION_TYPES.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case ACTION_TYPES.LOGOUT:
      window.location = "/";
      return;
    default:
      return state;
  }
}
