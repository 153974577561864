import styled, { css } from "styled-components";
import Modal from "../../shared/components/Modal";
import Button from "../../shared/components/Button";
import { ReactComponent as PackageImg } from "../../shared/assets/icons/Shop/Package.svg";
import TextInput from "../../shared/components/TextInput";
import { ReactComponent as DownArrowImg } from "../../shared/assets/icons/Chevron/Down.svg";
import Select from "../../shared/components/Select";

const Shipping = ({ onClose, order }) => {

  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <PackageImg />
          <div>
            <SubTitle>Orders</SubTitle>
            <Title>At Home Shipping</Title>
          </div>
        </Header>
        <OrderDetails cellSpacing={5}>
          <tr>
            <td>Patient Name:</td>
            <td>
              {order.patient.first} {order.patient.last}
            </td>
          </tr>
          <tr>
            <td>Patient DOB:</td>
            <td>
              {new Date(order.patient.dob).toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td>Patient Address:</td>
            <td>
              {order.patient.address}<br/>
              {order.patient.city} {order.patient.state} {order.patient.zip}
            </td>
          </tr>
          <tr>
            <td>Test Type:</td>
            <td>{order.test.name}</td>
          </tr>
          <tr>
            <td>Date Placed:</td>
            <td>{new Date(order.created).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Barcode ID:</td>
            <td>{order.barcode}</td>
          </tr>
        </OrderDetails>
        <Label>TRACKING NUMBERS</Label>
        <TextInput
            id={"tracking_out"}
            title={"Outbound Tracking"}
            defaultValue={order.tracking_out}
            disabled
        />
        <TextInput
            id={"tracking_in"}
            title={"Inbound Tracking"}
            disabled
            defaultValue={order.tracking_in}
        />
        <Content>
          <Buttons>
            <Button variant="primary" type="button" onClick={onClose}>
              Close
            </Button>
          </Buttons>
        </Content>
      </Wrapper>
    </Modal>
  );
};

export default Shipping;

const IconWrapper = styled.div`
  position: relative;
`;
const Message = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: #0b0b0b;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const SalesRep = styled.div``;
const To = styled.div`
  display: flex;
  gap: 20px;
  font-family: Poppins, serif;
  font-style: normal;
  font-size: 12px;
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 360px;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Label = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 22px;
  display: block;
  min-height: 22px;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;
const DownArrow = styled(DownArrowImg)`
  stroke: ${({ focused }) => (focused ? "#114377" : "#BAC4CF")};
  :hover {
    stroke: #114377;
  }
`;
const Header = styled.div`
  display: flex;
  align-items:center;
  gap: 10px;
  svg {
    height: 30px;
    width: 30px;
  }
`;
const Title = styled.h1`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.005em;
  color: ${(props) => props.theme.colors.black.offBlack};
`;
const SubTitle = styled.h2`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.colors.grey.grey4};
  text-transform: uppercase;
`;
const Avatar = styled.div`
  min-width: 30px;
  min-height: 30px;
  border-radius: 15px;
  background-color: #eef2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blue.light.primary};
`;
const StyledSelect = styled(Select)`
  min-height: 45px;
`;
const UserWrap = styled.div`
  display: flex;
  gap: 15px;
  font-family: Poppins, serif;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: ${({ focused }) => (!focused ? "#114377" : "#FFFFFF")};
  cursor: pointer;
  text-transform: capitalize;
`;

const OrderDetails = styled.table`
  background: #f8f9fb;
  border-radius: 8px;
  padding: 10px;
  > tr > td:nth-child(2) {
    font-weight: bold;
  }
`;
