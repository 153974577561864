import Modal from "../../../shared/components/Modal";
import Button from "../../../shared/components/Button";
import { ReactComponent as PrebuyImg } from "../../../shared/assets/icons/Shop/Prebuy.svg";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useState } from "react";
import Api from "../../../utils/api";

const NoticeMessage = ({ onClose }) => {
  return (
    <Modal open onClose={onClose}>
      <Wrapper>
        <Header>
          <h3>HOLIDAY HOUR AND SHIPPING NOTICE</h3>
        </Header>
        <Content>
          We are CLOSED Monday, 12/26 through Friday, 12/30.
          <br />
          LAST DAY to ship is Wednesday, 12/21.
          <br />
          We will reopen on Monday, 1/2/2023.
        </Content>
        <Buttons>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  > button {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;

const Error = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #f13737;
  font-family: Poppins, serif;
  font-size: 12px;
`;

const Content = styled.div`
  font-family: Poppins, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #0b0b0b;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    stroke: #114377;
    height: 28px;
    width: 28px;
  }
`;

export default NoticeMessage;
